import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FieldDef } from 'app/core/models';
import { RelatedToType } from 'app/shared/enums';

@Component({
  selector: 'linkable-related-field-cell',
  template: ` <ng-container *ngFor="let link of value; let last = last">
    <a *ngIf="link?.label" class="underline" [routerLink]="getRouterLink(link)">{{ link?.label }}</a>
    <span *ngIf="!last">, </span>
  </ng-container>`,
  styles: [],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class LinkableRelatedToFieldCell {
  private _value: any;

  @Input() type: RelatedToType;

  @Input()
  set value(value: any) {
    if (Array.isArray(value)) {
      this._value = value;
    } else {
      this._value = [value];
    }
  }

  get value(): any {
    return this._value;
  }

  getRouterLink(link) {
    const customObjectRouter = ['/objects', link?.custom_object_id, link?.id, 'details'];
    const routerMap = {
      [RelatedToType.Project]: ['/projects', link?.pipeline_id, link?.id, 'details'],
      [RelatedToType.Contact]: ['/contacts', link.id],
      [RelatedToType.Company]: ['/companies', link.id],
      [RelatedToType.Property]: ['/properties', link.id],
    };

    return routerMap[this.type] || customObjectRouter;
  }
}
