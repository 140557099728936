import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Observable, catchError, of, tap } from 'rxjs';
import { StateBase } from 'app/state/state-base';
import { TaskRole, TaskRoleCreate } from '../task-roles.types';
import { TaskRolesService } from '../services/task-roles.service';
import { TaskRolesActions } from './actions';

export interface TaskRolesStateModel {
  taskRoles: TaskRole[];
  newTaskRole: TaskRole;
  deleteError: string;
}

@State<TaskRolesStateModel>({
  name: new StateToken<TaskRolesStateModel>('taskRolesState'),
  defaults: {
    taskRoles: [],
    newTaskRole: null,
    deleteError: null,
  },
})
@Injectable()
export class TaskRolesState extends StateBase {
  constructor(protected readonly store: Store, private readonly taskRolesService: TaskRolesService) {
    super(store);
  }

  @Selector()
  static get(state: TaskRolesStateModel): TaskRole[] {
    return state.taskRoles;
  }

  @Selector()
  static getNew(state: TaskRolesStateModel): TaskRole {
    return state.newTaskRole;
  }

  @Selector()
  static getDeleteError(state: TaskRolesStateModel): string {
    return state.deleteError;
  }

  @Action(TaskRolesActions.Get)
  getTaskRolesByAccountId(
    context: StateContext<TaskRolesStateModel>,
    { accountId }: { accountId: number },
  ): Observable<TaskRole[]> {
    return this.taskRolesService.getByAccountId(accountId).pipe(
      tap((objects) => {
        const state = context.getState();
        context.setState({ ...state, taskRoles: [...objects] });
      }),
    );
  }

  @Action(TaskRolesActions.Create)
  createTaskRole(
    context: StateContext<TaskRolesStateModel>,
    { taskRole }: { taskRole: TaskRoleCreate },
  ): Observable<TaskRole> {
    return this.taskRolesService.create(taskRole).pipe(
      tap((response) => {
        const state = context.getState();
        if (response.errors?.length > 0) {
          context.setState({ ...state, newTaskRole: response });
        } else {
          context.setState({ ...state, taskRoles: [...state.taskRoles, response], newTaskRole: response });
        }
      }),
    );
  }

  @Action(TaskRolesActions.Delete)
  deleteTaskRole(
    context: StateContext<TaskRolesStateModel>,
    { taskRoleId, accountId }: { taskRoleId: number; accountId: number },
  ): Observable<void | string> {
    const state = context.getState();
    return this.taskRolesService.delete(taskRoleId, accountId).pipe(
      tap(() => {
        let taskRoles = state.taskRoles;
        taskRoles = taskRoles.filter((v) => v.id !== taskRoleId);
        context.setState({ ...state, taskRoles, deleteError: null });
      }),
      catchError((errorResponse: { error: { error: string } }) => {
        context.setState({ ...state, deleteError: errorResponse.error.error });
        return of(errorResponse.error.error);
      }),
    );
  }
}
