import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsPaginatorComponent } from './ts-paginator.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TSIconModule } from '../ts-icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [TsPaginatorComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    TSIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  exports: [TsPaginatorComponent],
})
export class TsPaginatorModule {}
