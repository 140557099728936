import { DashboardState } from './modules/dashboard/state/state';
import { ReportingState } from './modules/reporting/state/state';
import { ObjectsManagerState } from './modules/settings/objects-manager/state/state';
import { CustomObjectState } from './shared/modules/custom-objects/state/state';
import { AccountState } from './state/account/state';
import { UserState } from './state/user/state';
import { FieldDefsState } from './state/fields/state';
import { FolderResourceState } from './shared/modules/folders/state/state';
import { NotesState } from './shared/modules/notes/state/state';
import { TaskRolesState } from './modules/settings/task-roles/state/state';
import { SettingsRolesPermissionState } from './modules/settings/roles-permissions/state/state';
import { AutomationState } from './modules/settings/automations/state/state';
import { ResearchState } from './modules/research/state/state';
import { CompanyState } from './modules/companies/state/state';
import { ContactState } from './modules/contacts/state/state';
import { PropertiesState } from './modules/properties/state/state';

export const AppStates = [
  AccountState,
  ReportingState,
  ObjectsManagerState,
  CustomObjectState,
  DashboardState,
  FieldDefsState,
  FolderResourceState,
  NotesState,
  TaskRolesState,
  SettingsRolesPermissionState,
  UserState,
  AutomationState,
  ResearchState,
  CompanyState,
  ContactState,
  PropertiesState,
];
