import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TsBaseDialog, TsDialogComponent } from 'tsui/lib/ts-dialog/ts-dialog.component';
import { TsButtonModule } from 'tsui';
import { DialogRef } from '@angular/cdk/dialog';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TsSelectModule } from 'tsui/lib/ts-select/ts.select.module';

const fileTypeOptions = {
  pdf: { label: 'PDF', value: 'pdf' },
  csv: { label: 'CSV', value: 'csv' },
} as const;

export type TaskExportDialogResult = keyof typeof fileTypeOptions;

@Component({
  selector: 'task-export-dialog',
  standalone: true,
  imports: [TsDialogComponent, TsButtonModule, TsSelectModule, FormsModule, ReactiveFormsModule],
  templateUrl: './task-export-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskExportDialogComponent implements TsBaseDialog<TaskExportDialogResult> {
  protected dialogRef: DialogRef<TaskExportDialogResult, TaskExportDialogComponent> = inject(DialogRef);

  protected fileTypeForm: FormControl<TaskExportDialogResult> = new FormControl('pdf');
  protected readonly fileTypeOptions = Object.values(fileTypeOptions);

  closeDialog(result?: TaskExportDialogResult): void {
    if (result) {
      this.dialogRef.close(result);
    } else {
      this.dialogRef.close(null);
    }
  }
}
