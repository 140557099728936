import { inject, Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { ObjectGroupService } from '@services/object-group.service';
import { SavedFilter, SavedView } from 'app/core/models';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { tap } from 'rxjs';

import { StateBase } from '../state-base';
import { SavedViews } from './action';

export interface SavedViewsStateModel {
  savedViews: SavedView[];
}

@State<SavedViewsStateModel>({
  name: new StateToken('savedViewsState'),
  defaults: {
    savedViews: null,
  },
})
@Injectable()
export class SavedViewsState extends StateBase {
  protected projectsService = inject(ProjectsService);
  protected objectGroupService = inject(ObjectGroupService);

  constructor(protected readonly store: Store) {
    super(store);
    this.accountService.setAccount(this.account);
  }

  @Selector()
  static getViews(state: SavedViewsStateModel) {
    return state.savedViews;
  }

  @Action(SavedViews.Get)
  getSavedViews(context: StateContext<SavedViewsStateModel>) {
    return this.accountService.getSavedViews().pipe(
      tap((savedViews) => {
        context.setState({ ...context.getState(), savedViews });
      }),
    );
  }

  @Action(SavedViews.AddView)
  addSavedView(context: StateContext<SavedViewsStateModel>, { view }) {
    return this.accountService.createSavedView(view).pipe(
      tap((savedView) => {
        context.patchState({
          savedViews: [...context.getState().savedViews, savedView],
        });
      }),
    );
  }

  @Action(SavedViews.Set)
  setPipelines(context: StateContext<SavedViewsStateModel>, { savedViews }: { savedViews: SavedFilter[] }) {
    context.patchState({ savedViews });
  }
}
