<div
  class="treo-message-container"
  *ngIf="!dismissed"
  [@fadeIn]="dismissed === null ? false : !dismissed"
  [@fadeOut]="dismissed === null ? false : !dismissed"
>
  <!-- Icon -->
  <div class="treo-message-icon" *ngIf="showIcon">
    <!-- Custom icon -->
    <div class="treo-message-custom-icon">
      <ng-content select="[treoMessageIcon]"></ng-content>
    </div>

    <!-- Default icons -->
    <div class="treo-message-default-icon">
      <mat-icon *ngIf="type === 'primary'" [svgIcon]="'check_circle'"></mat-icon>

      <mat-icon *ngIf="type === 'accent'" [svgIcon]="'check_circle'"></mat-icon>

      <mat-icon *ngIf="type === 'warn'" [svgIcon]="'warning'"></mat-icon>

      <mat-icon *ngIf="type === 'basic'" [svgIcon]="'check_circle'"></mat-icon>

      <mat-icon *ngIf="type === 'info'" [svgIcon]="'info'"></mat-icon>

      <mat-icon *ngIf="type === 'success'" [svgIcon]="'check_circle'"></mat-icon>

      <mat-icon *ngIf="type === 'warning'" [svgIcon]="'warning'"></mat-icon>

      <mat-icon *ngIf="type === 'error'" [svgIcon]="'error'"></mat-icon>
    </div>
  </div>

  <!-- Content -->
  <div class="treo-message-content">
    <div class="treo-message-title">
      <p>
        <ng-content select="[treoMessageTitle]"></ng-content>
      </p>
    </div>

    <div class="treo-message-message">
      <p>
        <ng-content></ng-content>
      </p>
    </div>
  </div>

  <!-- Dismiss button -->
  <button class="treo-message-dismiss-button" mat-icon-button (click)="dismiss()">
    <mat-icon [svgIcon]="'close'"></mat-icon>
  </button>
</div>
