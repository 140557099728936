<div
  class="form-field-container"
  [ngClass]="{
    'showing-history': canShowHistory,
    'showing-tooltip': !!showTip,
    locked: isLocked
  }"
>
  <ng-template form-field-host><ng-template> </ng-template> </ng-template>
  <i
    class="fa fa-info-circle pointer-events-auto form-field-container__description"
    [matTooltip]="tooltipContent"
    *ngIf="!!showTip"
  >
  </i>
  <div *ngIf="canShowHistory" class="history-panel-trigger" #historyPanelOrigin>
    <mat-icon svgIcon="history" (click)="openHistoryPanel()"></mat-icon>
  </div>
  <ng-template #historyPanel>
    <div class="history-overlay">
      <div class="history">
        <app-field-history [model]="model" [fieldDef]="fieldDef" [data]="data"></app-field-history>
      </div>
    </div>
  </ng-template>
</div>
