import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Observable, of, switchMap } from 'rxjs';
import { Dictionary, RedirectItem } from 'tsui';

export type NestedValueCellRenderedParams = ICellRendererParams & {
  nestedKeyName: string;
  recordType: string;
  relatedToRedirectConfig: Dictionary<RedirectItem>;
};

type SelectLink = { id: string; url: Observable<string>; label: string; deferred?: boolean };

@Component({
  standalone: true,
  imports: [RouterModule, AsyncPipe, RouterModule],
  template: `
    @for (item of items; track item.id; let last = $last) {@if(item.deferred) {
    <a (click)="deferredRedirect(item)">{{ item.label }}</a>
    }@else{
    <a [routerLink]="item.url | async">{{ item.label }}</a>
    }{{ last ? null : ', ' }}
    }
  `,
  styles: [
    `
      a {
        text-decoration: underline;
      }
    `,
  ],
})
export class TableRecordRendererComponent implements ICellRendererAngularComp {
  items: any[] = [];
  params: NestedValueCellRenderedParams;
  urlPattern: RedirectItem;

  constructor(private readonly router: Router) {}

  agInit(params: NestedValueCellRenderedParams): void {
    this.params = params;

    if (Array.isArray(params.value)) {
      this.items = params.value.map((item) => this.nestedValueToLink(item));
    } else if (params.value) {
      this.items = [this.nestedValueToLink(params.value)];
    }
  }

  refresh(params: NestedValueCellRenderedParams) {
    this.params = params;
    return true;
  }

  deferredRedirect(item: SelectLink): void {
    item.url.subscribe((url) => this.router.navigateByUrl(url));
  }

  private nestedValueToLink(nestedValue: { id: number }): any {
    let [id, label] = [String(nestedValue.id), nestedValue[this.params.nestedKeyName]];

    label = this.params.valueFormatted ?? label;

    const redirectConfig = this.params.relatedToRedirectConfig[`${this.params.recordType}`];
    let url: Observable<string> = null;

    if (redirectConfig) {
      url = redirectConfig.route(Number(id)).pipe(switchMap((pattern) => of(pattern.replace(':id', id))));
    }

    return { id, url, label, deferred: redirectConfig?.deferred };
  }
}
