import { NgModule } from '@angular/core';
import { TsAutocompleteComponent } from './ts-autocomplete.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { CommonModule } from '@angular/common';

export const tsAutocompleteModules = [MatAutocompleteModule, CommonModule];

@NgModule({
  imports: [...tsAutocompleteModules],
  declarations: [TsAutocompleteComponent],
  exports: [TsAutocompleteComponent],
})
export class TsAutocompleteModule {}
