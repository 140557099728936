import { Parcel, BoundingBox, FilterForm, LatLng } from '../research.types';

export namespace Research {
  export class LoadGeoData {
    static readonly type = '[Research] Load Geo Data';

    constructor(public latLng: LatLng, public geography: string = 'zcta', public radiusMiles: number = 25) {}
  }

  export class GetAndSelectParcel {
    static readonly type = '[Research] Get And Select';

    constructor(public geoId: string, public mode: 'list' | 'selected' | 'all' = 'selected') {}
  }

  export class SetCurrentLocation {
    static readonly type = '[Research] Set Current Location';

    constructor(public latLng: LatLng) {}
  }

  export class ClearFilterAndParcels {
    static readonly type = '[Research] Clear Filter And Parcels';
  }

  export class SetPropertyUseCodes {
    static readonly type = '[Research] Set Property Use Codes';
  }

  export class LoadParcels {
    static readonly type = '[Research] Load Parcels';

    constructor(public filter: FilterForm) {}
  }

  export class SetParcels {
    static readonly type = '[Research] Set Parcels';

    constructor(public bboxParcels: Parcel[]) {}
  }

  export class LoadParcelsByLatLng {
    static readonly type = '[Research] Load Parcels By LatLng';

    constructor(public latLng: LatLng) {}
  }

  export class UpdateBoundingBox {
    static readonly type = '[Research] Update Current Bounding Box';

    constructor(public boundingBox: BoundingBox) {}
  }

  export class SetSelectedLayersIds {
    static readonly type = '[Research] Set Selected Layers Ids';

    constructor(public selectedLayersIds: string[]) {}
  }

  export class LoadAvailableLayers {
    static readonly type = '[Research] Load Available Layers';
  }
}
