import { Note, NotesPaginationRequest } from '../notes.types';

export namespace NotesActions {
  export class GetNotes {
    static readonly type = 'Get Notes List';
    constructor(public filter: NotesPaginationRequest, public keepSelected: boolean = false) {}
  }

  export class GetNoteById {
    static readonly type = 'Get Note By Id';
    constructor(public noteId: number) {}
  }

  export class SelectNote {
    static readonly type = 'Select Note By Id';
    constructor(public note: Note) {}
  }

  export class CreateNote {
    static readonly type = 'Create Note';
    constructor() {}
  }

  export class UpdateNote {
    static readonly type = 'Update Note';
    constructor(public note: Note) {}
  }

  export class DeleteNote {
    static readonly type = 'Delete Note';
    constructor(public noteId: number) {}
  }
}
