<div class="header">
  <div class="title">Associate Contact</div>
  <button class="close-button" mat-icon-button (click)="discard()" [tabIndex]="-1">
    <mat-icon [svgIcon]="'close'"></mat-icon>
  </button>
</div>

<form [formGroup]="contactForm">
  <!-- Contact Search/Name & ID Control -->
  <div class="flex flex-col gt-xs:flex-row">
    <!-- Contact ID Control (hidden) -->
    <input [formControlName]="'id'" type="hidden" />
    <!-- Company Search/Name Control -->
    <mat-form-field class="flex-auto">
      <mat-label>Contact Name</mat-label>
      <input
        matInput
        [formControlName]="'name'"
        [placeholder]="'Search by Contact Name'"
        [matAutocomplete]="matAutocompleteContact"
        [spellcheck]="false"
        [readonly]="hasSelectedContact"
      />
      <ng-container *ngIf="isSearching">
        <div [ngTemplateOutlet]="LoadingSpinner"></div>
      </ng-container>
    </mat-form-field>
    <mat-autocomplete
      #matAutocompleteContact="matAutocomplete"
      [disableRipple]="true"
      (optionSelected)="autocompleteContactSelected($event)"
    >
      <mat-option *ngIf="contactResults && !contactResults.length" disabled class="no-results">
        No contacts found!
      </mat-option>

      <mat-option *ngFor="let contactResult of contactResults" [value]="contactResult">
        <span
          [innerHTML]="
            resultLabel(contactResult) | highlighter: contactForm.get('name').value:'highlight-result':'partial'
          "
        ></span>
      </mat-option>

      <mat-option
        *ngIf="contactResults && contactNameControl.value && !idControl.value && !createNew && !isSearching"
        disabled
        (click)="isNewContact()"
      >
        <span class="create-new">Create a New Contact</span>
      </mat-option>
    </mat-autocomplete>
  </div>

  <ng-container *ngIf="contactNameControl.value && (hasSelectedContact || createNew)">
    <div class="flex flex-col gt-xs:flex-row">
      <!-- Contact Type/Category Control -->
      <ng-container *ngIf="hasSelectedContact">
        <mat-form-field class="flex-auto gt-xs:pr-3">
          <mat-label>Contact Type</mat-label>
          <input
            matInput
            class="cursor-not-allowed"
            [formControlName]="'category_name'"
            [placeholder]="'Contact Type'"
            [readonly]="true"
          />
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="createNew">
        <mat-form-field class="flex-auto gt-xs:pr-3">
          <mat-label>Contact Type</mat-label>
          <mat-select [formControlName]="'category_id'">
            <mat-option *ngFor="let category of categories" [value]="category.id">
              {{ category.name }}
            </mat-option>
          </mat-select>
          <mat-hint
            *ngIf="contactForm.get('category_id').hasError('required') && contactForm.get('category_id').touched"
          >
            Contact type is required.
          </mat-hint>
        </mat-form-field>
      </ng-container>

      <!-- Contact Associated Company Control -->
      <ng-container *ngIf="hasSelectedContact">
        <mat-form-field class="flex-auto cursor-not-allowed">
          <mat-label>Associated Company</mat-label>
          <input
            matInput
            class="cursor-not-allowed"
            [formControlName]="'company_name'"
            [placeholder]="'Associated company'"
            [readonly]="true"
          />
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="createNew">
        <mat-form-field class="flex-auto">
          <mat-label>Associated Company</mat-label>
          <input
            matInput
            [formControlName]="'company_name'"
            [placeholder]="'Start typing...'"
            [matAutocomplete]="matAutocompleteCompany"
            [spellcheck]="false"
          />
          <ng-container *ngIf="isAssociatedCompanySearching">
            <div [ngTemplateOutlet]="LoadingSpinner"></div>
          </ng-container>
          <mat-hint *ngIf="associatedCompanyResults && !associatedCompanyResults.length">
            Must associate contact to an existing company
          </mat-hint>
        </mat-form-field>
        <mat-autocomplete
          #matAutocompleteCompany="matAutocomplete"
          [disableRipple]="true"
          (optionSelected)="autocompleteAssociatedCompanySelected($event)"
        >
          <mat-option disabled class="no-results" *ngIf="associatedCompanyResults && !associatedCompanyResults.length">
            No companies found!
          </mat-option>

          <mat-option
            *ngFor="let associatedCompanyResult of associatedCompanyResults"
            [value]="associatedCompanyResult"
          >
            {{ associatedCompanyResult }}
          </mat-option>
        </mat-autocomplete>
      </ng-container>
    </div>

    <div class="flex flex-col gt-xs:flex-row">
      <!-- Contact Email Control -->
      <ng-container *ngIf="hasSelectedContact">
        <mat-form-field class="flex-auto gt-xs:pr-3">
          <mat-label>Email</mat-label>
          <input
            matInput
            [ngClass]="{ 'cursor-not-allowed': hasSelectedContact }"
            [formControlName]="'email'"
            [placeholder]="'Email'"
            [readonly]="true"
          />
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="createNew">
        <mat-form-field class="flex-auto gt-xs:pr-3">
          <mat-label>Email</mat-label>
          <input matInput [formControlName]="'email'" [placeholder]="'Email'" />
          <mat-hint *ngIf="contactForm.get('email')?.hasError('required') && contactForm.get('email')?.touched">
            Email is required.
          </mat-hint>
          <mat-hint *ngIf="contactForm.get('email')?.hasError('pattern') && contactForm.get('email')?.touched">
            Please enter a valid email address.
          </mat-hint>
        </mat-form-field>
      </ng-container>

      <!-- Contact Phone Control -->
      <ng-container *ngIf="hasSelectedContact">
        <mat-form-field class="flex-auto number-without-arrows">
          <mat-label>Phone</mat-label>
          <input
            matInput
            [ngClass]="{ 'cursor-not-allowed': hasSelectedContact }"
            [formControlName]="'phone'"
            [placeholder]="'Phone'"
            [value]="contactForm.get('phone').value | phone"
            [readonly]="true"
            autocomplete="off"
            digitOnly
          />
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="createNew">
        <mat-form-field class="flex-auto number-without-arrows">
          <mat-label>Phone</mat-label>
          <input
            matInput
            [formControlName]="'phone'"
            [placeholder]="'Phone'"
            [value]="contactForm.get('phone').value | phone"
            autocomplete="off"
            digitOnly
          />
        </mat-form-field>
      </ng-container>
    </div>

    <!-- Contact Tags Control -->
    <div class="flex flex-col gt-xs:flex-row">
      <ng-container *ngIf="hasSelectedContact && selectedContact?.tag_list.length">
        <mat-form-field class="flex-auto cursor-not-allowed">
          <mat-label>Tags</mat-label>
          <mat-chip-list #chipList aria-label="Tags display">
            <mat-chip *ngFor="let tag of tags" class="cursor-not-allowed" [selectable]="false" [removable]="false">{{
              tag
            }}</mat-chip>
          </mat-chip-list>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="createNew">
        <mat-form-field class="flex-auto">
          <mat-label>Tags</mat-label>
          <mat-chip-list #chipList aria-label="Tag selection">
            <mat-chip
              *ngFor="let tag of tags | keyvalue"
              [selectable]="true"
              [removable]="true"
              (removed)="removeTag(tag.value)"
            >
              {{ tag.value }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="New tag..."
              #tagsInput
              [matAutocomplete]="auto"
              [formControlName]="'tagsInput'"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addTag($event)"
              (focus)="tagsInput.focused = true"
              (blur)="tagsInput.focused = false"
              data-cy="tags-input"
            />
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTag($event)">
            <ng-container *ngIf="tagsSearchResult$ | async as tagsSearchResult">
              <mat-option *ngFor="let tag of tagsSearchResult | keyvalue" [value]="tag.key">
                {{ tag.key }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
          <mat-hint *ngIf="tagsInput.focused" class="mt-2">
            Press <strong>TAB</strong> key after finishing typing to add a new tag
          </mat-hint>
        </mat-form-field>
      </ng-container>
    </div>
  </ng-container>

  <div class="buttons mt-6 w-full justify-end">
    <ng-container *ngIf="hasSelectedContact">
      <button
        class="reset-button mr-auto"
        mat-stroked-button
        type="button"
        (click)="resetForm()"
        [matTooltip]="'Click to clear all fields and search for a new contact'"
      >
        <span>Clear</span>
      </button>
    </ng-container>
    <ng-container *ngIf="error">
      <mat-error class="w-1/2 px-4 mx-auto my-0">{{ error?.error.message }}</mat-error>
    </ng-container>
    <button class="discard-button" mat-button type="button" (click)="discard()">
      <span>Cancel</span>
    </button>
    <button
      class="send-button"
      mat-flat-button
      [color]="'primary'"
      [disabled]="isSaveBtnDisabled"
      DebounceClick
      (debounceLoading)="isDebounceLoading($event)"
      (debounceClick)="saveContactAssociation()"
    >
      <span *ngIf="!isLoading; else LoadingSpinner">
        {{ createNew ? 'Create and Save' : 'Save' }}
      </span>
    </button>
  </div>
</form>

<ng-template #LoadingSpinner>
  <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
</ng-template>
