import { Pipe, PipeTransform } from '@angular/core';
import UrlUtils from 'app/shared/Utils/url.utils';
import { TasksTabService } from '@shared/modules/tasks/services/tasks.service';
import { Task } from 'app/shared/modules/tasks/tasks.types';

@Pipe({ name: 'taskFilter' })
export class TaskFilterPipe implements PipeTransform {
  constructor(private readonly taskService: TasksTabService) {}

  transform(value: Task[], searchedIds: number[]): unknown {
    const temp = UrlUtils.getFromLocalStorage('projectTaskQueryFilters', true);

    if (!searchedIds) {
      return value;
    }

    if (searchedIds.length === 0) {
      return [];
    }

    if (searchedIds?.length) {
      const tasks = this.taskService.flattenTasks(value);
      const filteredTasks = tasks.filter((x) => searchedIds.includes(x.id));
      let result: any[] = [];
      let paths: Array<any[]> = [];
      for (const task of filteredTasks) {
        let pathItems = [task.id];
        let pointer = task.id;
        let tempTask: any = null;

        while ((tempTask = tasks.find(({ id }) => id === pointer))) {
          if (!tempTask.parent_id) {
            break;
          }

          pathItems.push(tempTask.parent_id);
          pointer = tempTask.parent_id;
        }

        pathItems = pathItems.filter((pathItem) => pathItem !== task.id).reverse() as any[];
        pathItems.push(task.id);
        tempTask = null;
        paths.push(pathItems);
      }

      for (const path of paths) {
        for (const [index, pathItem] of path.entries()) {
          if (index === 0 && !result.find((x) => x.id === pathItem)) {
            result.push({ ...tasks.find((x) => x.id === pathItem), children: [] });
          } else if (index === 1) {
            const parentId = path[index - 1];
            const i = result.findIndex((x) => x.id === parentId);
            if (result[i].children.find((x) => x.id === pathItem)) {
              continue;
            }
            result[i].children.push({
              ...tasks.find((x) => x.id === pathItem),
              children: [],
            });
          } else if (index === 2) {
            const gparentId = path[index - 2];
            const parentId = path[index - 1];
            const ig = result.findIndex((x) => x.id === gparentId);
            const i = result[ig].children.findIndex((x) => x.id === parentId);
            result[ig].children[i].children.push({
              ...tasks.find((x) => x.id === pathItem),
              children: [],
            });
          }
        }
      }

      return result;
    }

    return value;
  }
}
