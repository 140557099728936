import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import type { TreoVerticalNavigationComponent } from '../../vertical.component';
import { TreoNavigationService } from '../../../navigation.service';
import { TreoNavigationItem } from '../../../navigation.types';
import { VERTICAL_PARENT } from '../../vertical-parent.token';

@Component({
  selector: 'treo-vertical-navigation-basic-item',
  templateUrl: './basic.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreoVerticalNavigationBasicItemComponent implements OnInit, OnDestroy {
  // Item
  @Input()
  item: TreoNavigationItem;

  // Name
  @Input()
  name: string;

  // Private
  // private _treoVerticalNavigationComponent: TreoVerticalNavigationComponent;
  private _unsubscribeAll: Subject<void>;

  /**
   * Constructor
   *
   * @param {TreoNavigationService} _treoNavigationService
   * @param {ChangeDetectorRef} _changeDetectorRef
   */
  constructor(
    private _treoNavigationService: TreoNavigationService,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(VERTICAL_PARENT) private _treoVerticalNavigationComponent: TreoVerticalNavigationComponent,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get the parent navigation component
    this._treoVerticalNavigationComponent = this._treoNavigationService.getComponent(this.name);

    // Subscribe to onRefreshed on the navigation component
    this._treoVerticalNavigationComponent.onRefreshed.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
