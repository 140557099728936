import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CacheBuster, Cacheable } from 'ts-cacheable';

import { ObjectLink } from '../objects-manager.types';
import { CustomObject } from 'app/shared/modules/custom-objects/custom-objects.types';

const objectsCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class ObjectsManagerService {
  public bustObjectsCache(): void {
    objectsCacheBuster$.next();
  }

  constructor(private readonly httpClient: HttpClient) {}

  @Cacheable({ maxAge: 120000, cacheBusterObserver: objectsCacheBuster$ })
  @CacheBuster({
    cacheBusterNotifier: objectsCacheBuster$,
  })
  public get(accountId: number): Observable<CustomObject[]> {
    return this.httpClient
      .get<{ objects: CustomObject[] }>(`accounts/${accountId}/objects`)
      .pipe(map((response) => response.objects));
  }

  public getById(accountId: number, objectId: number): Observable<CustomObject> {
    return this.httpClient
      .get<{ custom_object: CustomObject }>(`accounts/${accountId}/custom_objects/${objectId}`)
      .pipe(map((response) => response.custom_object));
  }

  @CacheBuster({
    cacheBusterNotifier: objectsCacheBuster$,
  })
  public create(accountId: number, customObject: CustomObject): Observable<CustomObject> {
    return this.httpClient
      .post<{ custom_object: CustomObject }>(`accounts/${accountId}/custom_objects`, customObject)
      .pipe(map((response) => response.custom_object));
  }

  @CacheBuster({
    cacheBusterNotifier: objectsCacheBuster$,
  })
  public patch(accountId: number, objectId: number, customObject: CustomObject): Observable<CustomObject> {
    return this.httpClient
      .patch<{ custom_object: CustomObject }>(`accounts/${accountId}/custom_objects/${objectId}`, {
        custom_object: customObject,
      })
      .pipe(map((response) => response?.custom_object));
  }

  @CacheBuster({
    cacheBusterNotifier: objectsCacheBuster$,
  })
  public createLink(accountId: number, customObjectId: number, link: ObjectLink): Observable<ObjectLink> {
    return this.httpClient
      .post<{ link: ObjectLink }>(`accounts/${accountId}/custom_objects/${customObjectId}/links`, { link })
      .pipe(map((response) => response.link));
  }

  @CacheBuster({
    cacheBusterNotifier: objectsCacheBuster$,
  })
  public delete(accountId: number, customObjectId: number): Observable<void> {
    return this.httpClient.delete<void>(`accounts/${accountId}/custom_objects/${customObjectId}`);
  }
}
