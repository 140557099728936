<div
  class="container"
  [ngClass]="{
    medium: size === 'medium',
    large: size === 'large'
  }"
>
  <div class="container__list">
    <ts-filter-list
      [fields]="fields"
      [filters]="filters"
      [hasGroups]="hasGroups"
      [lookupContract]="lookupContract"
      [hasGroups]="hasGroups"
      (filterDefChanged)="filterDefChange.emit($event)"
      (filterRemoved)="filterRemove.emit($event)"
    >
    </ts-filter-list>
  </div>
  <div class="container__actions">
    <ts-button type="flat" color="basic" icon="add" (click)="addItem.emit()">Add filter</ts-button>
  </div>

  <div class="container__bottom">
    <a href="https://help.termsheet.com/add-filters-to-views" target="_blank">
      <mat-icon>help_outline</mat-icon> Learn about filters
    </a>
    <div>
      <ts-button type="stroked" color="accent" (click)="filterClear.emit()" [disabled]="!filters?.length"
        >Clear</ts-button
      >
      <ts-button type="flat" (click)="filterApply.emit()" [disabled]="!canFilter()">Apply</ts-button>
    </div>
  </div>
</div>
