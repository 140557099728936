<mat-form-field #lala class="flex-auto gt-xs:pr-3 w-full">
  <mat-label>
    <span
      class="block text-ellipsis overflow-hidden pointer-events-auto"
      [matTooltip]="fieldDef.label"
      tooltipIfEllipsis
    >
      {{ fieldDef.label }}
    </span>
  </mat-label>
  <div class="w-full" (click)="edit()" (focus)="edit()">
    <input
      #inputControl
      matInput
      currencyMask
      [(ngModel)]="value"
      (keyup.enter)="onBlur($event)"
      (blur)="onBlur($event)"
      [options]="currencyMaskOptions"
      [disabled]="isDisabled"
    />
  </div>
</mat-form-field>
