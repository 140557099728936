import { Role } from 'app/core/models';

export namespace Settings {
  export namespace RolesPermissions {
    export class SetRoles {
      static readonly type = 'Set Roles';

      constructor() {}
    }

    export class SetPermissions {
      static readonly type = 'Set Permissions';

      constructor() {}
    }

    export class UpdatePermissions {
      static readonly type = 'Update Permissions';

      constructor(public model: { roleId: number; permissionIds: number[] }) {}
    }

    export class CreateRole {
      static readonly type = 'Create Role';

      constructor(public model: Partial<Role>) {}
    }

    export class EditRole {
      static readonly type = 'Edit Role';

      constructor(public model: Partial<Role>) {}
    }

    export class DeleteRole {
      static readonly type = 'Delete Role';

      constructor(public roleId: number) {}
    }
  }
}
