import { SortDirection } from '@angular/material/sort';
import { FieldDef } from 'app/core/models';
import { SummarizeMethod } from 'app/modules/reporting/reporting.types';

import { RelatedToType } from '../enums';
import { Dictionary } from './dictionary';

export interface GraphqlResultEdge<T> {
  cursor: string;
  node: T;
}

export interface GraphqlResultPageInfo {
  endCursor: string;
  filteredCount: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  totalCount: number;
  currentPage?: number;
  pageSize?: number;
}

export type GraphqlResult<T> = {
  [key: string]: GraphqlResultInfo<T>;
};

export type GraphqlResultInfo<T> = {
  edges: GraphqlResultEdge<T>[];
  pageInfo: GraphqlResultPageInfo;
  bucketsCount?: Dictionary<number> | string;
  timeSeries?: {
    headers: any[];
    data: any[][];
  };
};

export const GraphqlConnection = Object.freeze({
  projects: 'projects',
  properties: 'properties',
  companies: 'company',
  contact: 'contact',
  propertiesSqlBased: 'propertiesSqlBased',
  projectsKanban: 'projectsKanban',
  timeSeries: 'timeSeries',
});

export const GraphqlConnectionMap = {
  [RelatedToType.Project]: GraphqlConnection.projects,
  [RelatedToType.Property]: GraphqlConnection.properties,
  [GraphqlConnection.timeSeries]: GraphqlConnection.timeSeries,
  [GraphqlConnection.projectsKanban]: GraphqlConnection.projectsKanban,
};

export interface GraphqlFilter {
  fjson: string;
  query?: string;
  sort?: GraphqlFilterSort[];
  location?: any;
  queryObject?: GraphqlFilterQueryObject;
  pagination?: {
    page: number;
    size: number;
  };
  filterDictionary?: Dictionary<any>;
}

export interface GraphqlFilterQueryObject {
  force_include_child?: boolean;
  cadence?: string;
  group?: string;
  relatedToType?: string;
  groupBy?: string[];
  report_layout_id?: number;
}

export interface GraphqlFilterSort {
  field: string;
  order: SortDirection;
}

export interface GraphqlParams<T extends GraphqlFilter = GraphqlFilter> {
  connection: string;
  fields: string[];
  fieldDefs?: FieldDef[];
  filter?: T;
  options?: {
    keepConnectionCase: boolean;
    bucketsCount?: boolean;
  };
  group?: string;
  startDate?: string;
  endDate?: string;
  cadence?: string;
  groupBy?: GraphqlFilterSort[];
  relatedToType?: RelatedToType;
  summarizeMethod?: SummarizeMethod;
  excludeSubtotals?: boolean;
  format?: 'json' | 'time_series_json';
}
