import { ReportLayoutSummaryFormula } from '../reporting/reporting.types';

export enum ReportType {
  Legacy = 'legacy',
  SingleObject = 'single_object',
  TimeSeries = 'time_series',
}

// Those do not represent IDs but values the API
// expects in query strings for filtering report_type
export enum ReportTypeQueryParamValues {
  Legacy = 1,
  SingleObject = 2,
  TimeSeries = 3,
}

export interface ReportLayout<T> {
  auto_send?: boolean;
  column_color?: string;
  created_at: string;
  definition: T;
  description?: string;
  header_alignment?: string;
  id: number;
  logo_url?: string;
  name: string;
  recipients?: Array<any>;
  scheduled_days_of_week?: Array<number>;
  scheduled_time_of_day?: number;
  scheduled_report_format?: string;
  section_total_format?: string;
  updated_at?: string;
  user_id?: string | number;
  account_id: number;
  filters?: string;
  running?: boolean;
  scheduled_period: string;
  report_type: ReportType;
  is_favorite?: boolean;
  report_layout_summary_formulas?: ReportLayoutSummaryFormula[];
}

export interface ReportLayoutDefinition {
  charts: Array<any>;
  pipeline: {
    fields: string[][];
    config: {
      group_by: string;
      groupings: Array<string>;
      sort_by: ReportLayoutDefinitionSort;
      sortings: Array<ReportLayoutDefinitionSort>;
      show_comments?: boolean;
      show_dispositions?: any;
      include_deal_export?: any;
      include_child_deals?: boolean;
    };
  };
}

export interface DaysOfWeek {
  id: number;
  name: string;
}

type ReportLayoutDefinitionSort = { field: string; order: 'asc' | 'desc' };
