import { Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TreoNavigationItem } from 'tsui/@treo';
import { Subject } from 'rxjs';

@Component({
  selector: 'sharing-layout',
  templateUrl: './sharing.component.html',
  styleUrls: ['./sharing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SharingLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;

  @HostBinding('class.fixed-header')
  fixedHeader: boolean;

  @HostBinding('class.fixed-footer')
  fixedFooter: boolean;

  public horizontalNavigation: TreoNavigationItem[];

  // Private
  private _unsubscribeAll: Subject<void>;

  /**
   * Constructor
   */
  constructor() {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {}

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
