import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Parceled staging environment CORS policy blocks the X-TS-FE-Version header
    if (request.url.startsWith(environment.tilesApi.url)) {
      return next.handle(request);
    }

    const modifiedRequest = request.clone({
      headers: request.headers.set('X-TS-FE-Version', environment.rollbar?.codeVersion),
    });
    return next.handle(modifiedRequest);
  }
}
