import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TsSnackbarService } from 'tsui';
import { catchError, Observable, of, switchMap, tap, throwError, zip } from 'rxjs';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { StateBase } from 'app/state/state-base';
import { CustomObject } from 'app/shared/modules/custom-objects/custom-objects.types';
import { ObjectsManagerService } from '../services/objects-manager.service';
import { ObjectsManagerActions } from './actions';
import { ObjectLink } from '../objects-manager.types';
import { FieldDef, SavedView } from '@core/models';
import { createFieldDefGroupMetadata, formatRelatedFieldToNestedField } from '@shared/functions';
import { RelatedToType } from '../../../../shared/enums';
import { TsTableOptions } from 'tsui/lib/ts-table/ts-table.model';
import { TableRecordRendererComponent } from '@shared/components/ts-table-renderers/table-record-renderer.component';

export interface ObjectsManagerStateModel {
  menuCustomObjects: CustomObject[];
  customObjects: CustomObject[];
  customObject: CustomObject;
}

export interface SelectObjectManagerOptions {
  allowedFieldsOnly: boolean;
  addNestedFields: boolean;
}

@State<ObjectsManagerStateModel>({
  name: new StateToken<ObjectsManagerStateModel>('objectsManagerState'),
  defaults: {
    menuCustomObjects: null,
    customObjects: [],
    customObject: null,
  },
})
@Injectable()
export class ObjectsManagerState extends StateBase {
  constructor(
    protected readonly store: Store,
    private readonly objectsManagerService: ObjectsManagerService,
    private readonly snackBar: TsSnackbarService,
  ) {
    super(store);
  }

  @Selector()
  static getForMenu(state: ObjectsManagerStateModel): CustomObject[] {
    return state.menuCustomObjects;
  }

  @Selector()
  static get(state: ObjectsManagerStateModel): CustomObject[] {
    return state.customObjects;
  }

  @Selector()
  static getMappedObjects(state: ObjectsManagerStateModel): { [key: string]: CustomObject } {
    return state.customObjects.reduce((acc, object) => {
      acc[object.name] = object;
      return acc;
    }, {});
  }

  @Selector()
  static getById(state: ObjectsManagerStateModel): CustomObject {
    return state.customObject;
  }

  static getMappedFieldsByObjectName(objectName: string) {
    return createSelector(
      [ObjectsManagerState, ObjectsManagerState.getMappedObjects],
      (state: ObjectsManagerStateModel, tsObject: { [key: string]: CustomObject }): { [key: string]: FieldDef[] } => {
        return tsObject[objectName]?.fields.reduce((acc, field) => {
          acc[field.name] = field;
          return acc;
        }, {});
      },
    );
  }

  static getFieldsByObjectName(
    objectName: string,
    options: SelectObjectManagerOptions = {
      allowedFieldsOnly: false,
      addNestedFields: false,
    },
  ) {
    return createSelector(
      [ObjectsManagerState, ObjectsManagerState.getMappedObjects],
      (state: ObjectsManagerStateModel, mappedObjects: { [key: string]: CustomObject }): FieldDef[] => {
        const tsObject = mappedObjects[objectName];

        if (!tsObject) return [];

        // we need to check hidden fields twice, once for the main object and once for when the nested fields are added
        // we also need to add the group metadata to the fields
        let result = this.processAllowedFields(options.allowedFieldsOnly, tsObject.fields).map((field) => {
          return {
            ...field,
            meta: {
              ...field.meta,
              group: createFieldDefGroupMetadata(tsObject.name, tsObject.label),
            },
          };
        });

        if (objectName === RelatedToType.Trail) {
          result = result.filter(
            (field) => field.meta?.reporting?.hidden === undefined || field.meta?.reporting?.hidden === false,
          );
        }

        if (options.addNestedFields) {
          const fieldsToRelate = result
            .filter((field: FieldDef) => field.type === 'record')
            .filter(
              (field: FieldDef) =>
                field.meta?.exclude_in_nested_fields === false || field.meta?.exclude_in_nested_fields === undefined,
            );
          const nestedFields = [];

          fieldsToRelate.forEach((field) => {
            const recordType = field?.meta?.record_type;
            const fieldsRelatedToRecordType = mappedObjects[recordType]?.fields;

            const formattedNestedFields = fieldsRelatedToRecordType?.map((relatedField: FieldDef) =>
              formatRelatedFieldToNestedField(relatedField, field),
            );

            if (formattedNestedFields && formattedNestedFields.length > 0) {
              nestedFields.push(...formattedNestedFields);
            }
          });

          result = [...result, ...nestedFields];
        }

        result = result.map((field) => ({
          ...field,
          tsTableOptions: this.getTsTableOption(field),
        }));

        return this.processAllowedFields(options.allowedFieldsOnly, result);
      },
    );
  }

  private static processAllowedFields(allowedFieldsOnly: boolean, result: FieldDef[]): FieldDef[] {
    return allowedFieldsOnly ? result.filter((field: FieldDef) => !field?.hidden) : result;
  }

  private static getTsTableOption(field: FieldDef): TsTableOptions {
    const isNestedField = field.name.includes('.');
    return {
      headerName: isNestedField ? `${field._parentFieldLabel} ${field.label}` : field.label,
      field: isNestedField ? field._parentFieldName : field.name,
      editable: !isNestedField,
      cellRenderer: isNestedField ? TableRecordRendererComponent : null,
      cellRendererParams: isNestedField
        ? { nestedKeyName: field.name.split('.')[1], recordType: field._parentFieldMeta?.record_type }
        : null,
    };
  }

  @Action(ObjectsManagerActions.GetForMenu)
  getCustomObjectsForMenu(context: StateContext<ObjectsManagerStateModel>): Observable<CustomObject[]> {
    return this.objectsManagerService.get(Number(this.account.id)).pipe(
      tap((response) => {
        const state = context.getState();
        context.setState({ ...state, menuCustomObjects: response.filter(({ id }) => id) });
      }),
    );
  }

  @Action(ObjectsManagerActions.Get)
  getCustomObjects(context: StateContext<ObjectsManagerStateModel>): Observable<CustomObject[]> {
    return this.objectsManagerService.get(Number(this.account.id)).pipe(
      tap((objects) => {
        const state = context.getState();
        context.setState({ ...state, customObjects: [...objects] });
      }),
    );
  }

  @Action(ObjectsManagerActions.GetById)
  getCustomObjectById(
    context: StateContext<ObjectsManagerStateModel>,
    { customObjectId }: { customObjectId: number },
  ): Observable<CustomObject> {
    return this.objectsManagerService.getById(Number(this.account.id), customObjectId).pipe(
      tap((response) => {
        const state = context.getState();
        context.setState({ ...state, customObject: response });
      }),
    );
  }

  @Action(ObjectsManagerActions.Create)
  createCustomObject(
    context: StateContext<ObjectsManagerStateModel>,
    { customObject }: { customObject: CustomObject },
  ): Observable<CustomObject> {
    const state = context.getState();
    return this.objectsManagerService.create(Number(this.account.id), customObject).pipe(
      switchMap((customObject) =>
        zip(
          of(customObject),
          this.accountService.createSavedView({
            id: null,
            name: `All ${customObject.label_plural}`,
            related_to_type: customObject.name,
          }),
        ),
      ),
      switchMap(([customObject]: [CustomObject, SavedView]) => {
        const customObjects = [...state.customObjects, { ...customObject, fields: [] }];
        context.setState({
          ...state,
          customObjects,
          menuCustomObjects: customObjects.filter(({ id }) => id),
        });
        return of(customObject);
      }),
    );
  }

  @Action(ObjectsManagerActions.Patch)
  patchCustomObject(
    context: StateContext<ObjectsManagerStateModel>,
    { customObjectId, customObject }: { customObjectId: number; customObject: CustomObject },
  ): Observable<CustomObject> {
    const state = context.getState();

    return this.objectsManagerService.patch(Number(this.account.id), customObjectId, customObject).pipe(
      tap((response) => {
        context.setState({ ...state, customObjects: [...state.customObjects, response], customObject: response });
        this.store.dispatch(new ObjectsManagerActions.Get());
      }),
    );
  }

  @Action(ObjectsManagerActions.CreateLink)
  createCustomObjectLink(
    context: StateContext<ObjectsManagerStateModel>,
    { customObjectId, link }: { customObjectId: number; link: ObjectLink },
  ): Observable<ObjectLink> {
    return this.objectsManagerService
      .createLink(Number(this.account.id), customObjectId, link)
      .pipe(tap(() => context.dispatch(new ObjectsManagerActions.GetById(customObjectId))));
  }

  @Action(ObjectsManagerActions.Delete)
  deleteCustomObject(
    context: StateContext<ObjectsManagerStateModel>,
    { customObjectId }: { customObjectId: number },
  ): Observable<void> {
    const state = context.getState();

    return this.objectsManagerService.delete(Number(this.account.id), customObjectId).pipe(
      catchError((err: HttpErrorResponse) => {
        this.snackBar.open(err.error?.custom_object?.errors, 'error');
        return throwError(() => err);
      }),
      tap(() => {
        let customObjects = state.customObjects;

        customObjects = customObjects.filter((v) => v.id !== customObjectId);
        context.setState({ ...state, customObjects });
        this.store.dispatch(new ObjectsManagerActions.GetForMenu());
      }),
    );
  }

  @Action(ObjectsManagerActions.AddOrUpdateObjectField)
  addOrUpdateObjectField(context: StateContext<ObjectsManagerStateModel>, { fieldDef }: { fieldDef: FieldDef }): void {
    const state = context.getState();
    const customObjects = [...state.customObjects];
    const customObject = customObjects.find(({ name }) => name === fieldDef.related_to_type);
    let fieldIndex: number;
    if ((fieldIndex = customObject.fields.findIndex(({ name }) => name === fieldDef.name)) > -1) {
      customObject.fields[fieldIndex] = fieldDef;
    } else {
      customObject.fields.push(fieldDef);
    }
    context.patchState({ customObjects });
  }
}
