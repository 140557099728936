<ng-container *ngIf="isOpened">
  <section class="alert-banner" [ngStyle]="{ 'background-color': alertConfig.bgColor }">
    <article class="alert-banner__icon">
      <mat-icon [svgIcon]="alertConfig.iconName"></mat-icon>
    </article>
    <article class="alert-banner__text">
      <ng-content></ng-content>
    </article>
    <ng-container *ngIf="closable">
      <article class="alert-banner__action">
        <button mat-icon-button [matTooltip]="'Close'" (click)="close()">
          <mat-icon [svgIcon]="'close'"></mat-icon>
        </button>
      </article>
    </ng-container>
  </section>
</ng-container>
