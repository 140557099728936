import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsBreadcrumbComponent } from './ts-breadcrumb.component';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { TSIconModule } from '../ts-icon';

@NgModule({
  imports: [CommonModule, RouterModule, TSIconModule],
  declarations: [TsBreadcrumbComponent],
  exports: [TsBreadcrumbComponent],
})
export class TsBreadcrumbModule {}
