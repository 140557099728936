<div class="field-history">
  <table>
    <thead>
      <tr class="mat-header-row">
        <th class="mat-header-cell bg-cool-gray-50 dark:bg-cool-gray-700 border-t">New</th>
        <th class="mat-header-cell bg-cool-gray-50 dark:bg-cool-gray-700 border-t">Old</th>
        <th class="mat-header-cell bg-cool-gray-50 dark:bg-cool-gray-700 border-t">Date</th>
        <th class="mat-header-cell bg-cool-gray-50 dark:bg-cool-gray-700 border-t">User</th>
        <th class="mat-header-cell bg-cool-gray-50 dark:bg-cool-gray-700 border-t">Source</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="activities$ | async as activities; else loading">
        <tr class="mat-row cdk-row order-row h-10" *ngFor="let history of activities">
          <td class="mat-cell cdk-cell cdk-column-title mat-column-title val">
            {{ history.new_val | readableField: model:fieldDef:options }}
          </td>
          <td class="mat-cell cdk-cell cdk-column-title mat-column-title val">
            {{ history.old_val | readableField: model:fieldDef:options }}
          </td>
          <td class="mat-cell cdk-cell cdk-column-title mat-column-title">
            {{ history.created_at | date: 'LLL dd, y h:mm a' }}
          </td>
          <td class="mat-cell cdk-cell cdk-column-title mat-column-title">
            {{ history?.user_name }}
          </td>
          <td class="mat-cell cdk-cell cdk-column-title mat-column-title">
            {{ displayActivitySource(history) }}
          </td>
        </tr>
        <tr *ngIf="!activities.length">
          <td colspan="4" class="empty"><p>No records found</p></td>
        </tr>
      </ng-container>
      <ng-template #loading>
        <tr>
          <td colspan="4"><mat-spinner diameter="50"></mat-spinner></td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
