import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TsDividerComponent, TSIconModule } from 'tsui';
import { MatDialogConfig } from '@angular/material/dialog';

export interface TsBaseDialog<R = unknown> {
  closeDialog: (result?: R) => void;
}

@Component({
  selector: 'ts-dialog',
  standalone: true,
  imports: [TSIconModule, TsDividerComponent],
  templateUrl: './ts-dialog.component.html',
})
export class TsDialogComponent {
  @Input() title: string = '-';
  @Output() closeDialog = new EventEmitter<void>();

  /**
   * This can be extended to provide different configurations for the dialog
   */
  static getDefaultDialogConfig(): Pick<MatDialogConfig, 'maxWidth' | 'width' | 'restoreFocus'> {
    return {
      maxWidth: '541px',
      width: '100%',
      restoreFocus: false,
    };
  }
}
