import { ModuleWithProviders, NgModule } from '@angular/core';

import { TREO_APP_CONFIG } from './config.constants';

@NgModule()
export class TreoConfigModule {
  constructor() {}

  static forRoot(config: any): ModuleWithProviders<TreoConfigModule> {
    return {
      ngModule: TreoConfigModule,
      providers: [
        {
          provide: TREO_APP_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
