import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatSnakeCase',
})
export class FormatSnakeCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    let newValue = value.replace(/_/g, ' ');
    newValue = newValue
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    return newValue;
  }
}

// Utility class to encapsulate FormatSnakeCasePipe logic to be used inside .ts files.
export class FormatSnakeCasePipeUtility {
  static transform(value: string): string {
    return FormatSnakeCasePipe.prototype.transform(value);
  }
}
