import { ConnectedPosition, Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

const OVERLAY_POSITIONS: { [key: string]: ConnectedPosition } = {
  top: {
    offsetX: -12,
    offsetY: 0,
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
  },
  right: {
    offsetX: 12,
    offsetY: 0,
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top',
  },
  bottom: {
    offsetX: -12,
    offsetY: 0,
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
  },
  'bottom-right': {
    offsetX: 12,
    offsetY: 0,
    originX: 'end',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
  },
  left: {
    offsetX: -12,
    offsetY: 0,
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
  },
};

@Component({
  selector: 'ts-overlay',
  template: `<ng-template #tabPanel> <ng-content></ng-content> </ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .cdk-overlay-transparent-backdrop {
        background-color: transparent;
      }

      .ts-overlay-panel {
        background-color: #fff;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        padding: 8px 0px;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TsOverlayComponent extends OnDestroyMixin {
  @Input() position: string = 'right';
  @Input() overlayOrigin: any;
  @Input() hasBackdrop = true;
  @Input() transparentBackdrop = false;
  @Output() closeByBackdropClick = new EventEmitter();

  @ViewChild('tabPanel') private tabPanel: TemplateRef<any>;

  private overlayRef: OverlayRef;
  private overlayTemplatePortal: TemplatePortal;

  constructor(private readonly overlay: Overlay, private readonly viewContainerRef: ViewContainerRef) {
    super();
  }

  displayOverlay(target): void {
    const devPosition = OVERLAY_POSITIONS[this.position];
    this.overlayRef = this.overlay.create({
      hasBackdrop: this.hasBackdrop,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      backdropClass: this.transparentBackdrop ? 'cdk-overlay-transparent-backdrop' : 'cdk-overlay-dark-backdrop',
      panelClass: 'ts-overlay-panel',
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(target)
        .withViewportMargin(5)
        .withPositions([devPosition, OVERLAY_POSITIONS['bottom'], OVERLAY_POSITIONS['top']]),
    });

    const component = new TemplatePortal(this.tabPanel, this.viewContainerRef);
    this.overlayRef.attach(component);
    this.overlayRef
      .backdropClick()
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.closeOverlay();
        this.closeByBackdropClick.emit();
      });
  }

  closeOverlay(): void {
    if (this.overlayRef?.hasAttached()) this.overlayRef.detach();
    if (this.overlayTemplatePortal?.isAttached) this.overlayTemplatePortal.detach();
  }
}
