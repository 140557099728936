import { EventEmitter, Injectable, Input, Output } from '@angular/core';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { FieldDef } from 'app/core/models';
import { BehaviorSubject } from 'rxjs';

export interface FormFieldInterfaceComponent {
  model: any;
  fieldDef: any;
  update: any;
  data: any;
  readonly?: boolean;
  isRequired?: boolean;
  fieldMask?: (fieldValue?: string) => string;
  updateChangedModel?(model: any): any;
}

@Injectable()
export abstract class FormFieldComponent extends OnDestroyMixin implements FormFieldInterfaceComponent {
  showTip: string;

  readonly mask$ = new BehaviorSubject<string>('');

  @Input() model: any;
  @Input() fieldDef: FieldDef;
  @Input() data: any;
  @Input() readonly: boolean = false;
  @Input() fieldMask?: (fieldValue: string) => string;
  @Input() isRequired: boolean = false;
  @Output() update: EventEmitter<PartialFormField> = new EventEmitter();

  get isDisabled(): boolean {
    return this.readonly || this.fieldDef?.locked;
  }

  abstract updateChangedModel?(model: any): any;

  ngOnInit(): void {
    this.showTip = this.fieldDef.description || this.fieldDef.meta?.calc_code;
  }
}

export interface PartialFormField {
  [key: string]: string | number | string[];
  id?: number;
}
