import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsButtonComponent } from './ts-button.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TSIconModule } from '../ts-icon/ts-icon.module';
import { TSDebounceClickDirective } from '../directives/debounce-click.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    TSIconModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatMenuModule,
    OverlayModule,
    MatTooltipModule,
  ],
  declarations: [TsButtonComponent, TSDebounceClickDirective],
  exports: [TsButtonComponent],
})
export class TsButtonModule {}
