import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'zeroToNullPipe' })
export class ZeroToNullPipe implements PipeTransform {
  transform(value: any): string | null | any {
    const possibleZeroes = ['0', '$0', '0.00%'];

    return possibleZeroes.includes(String(value)) ? null : value;
  }
}
