import { ProjectTemplate } from 'app/modules/projects/projects.types';
import { ExportOptions } from 'app/shared/models/export-options';
import { GraphqlParams } from 'app/shared/models/graphql';
import {
  CustomObjectValue,
  CustomObjectValueTemplate,
  GetCustomObjectValueByIdFilter,
  GetCustomObjectValuesFilter,
} from '../custom-objects.types';

export namespace CustomObjectActions {
  export class GetValues {
    static readonly type = 'Get Custom Object Values';
    constructor(public filter: GetCustomObjectValuesFilter) {}
  }

  export class GetValueById {
    static readonly type = 'Get Custom Object Value By Id';
    constructor(public filter: GetCustomObjectValueByIdFilter) {}
  }

  export class ClearSelectedValue {
    static readonly type = 'Clear Selected Value';
    constructor() {}
  }

  export class SetSelectedValue {
    static readonly type = 'Set Selected Value';
    constructor(public value: CustomObjectValue) {}
  }

  export class UpdateSelectedValue {
    static readonly type = 'Update Selected Value';
    constructor(public value: Partial<CustomObjectValue>) {}
  }

  export class UpdateCustomObjectValueTemplateDefinition {
    static readonly type = 'Update Custom Object Value Template Definition';
    constructor(public template: ProjectTemplate<CustomObjectValueTemplate>) {}
  }

  export class AddAndUpdateCustomObjectValueTemplate {
    static readonly type = 'Add and Update Custom Object Value Template';
    constructor(public value: Partial<CustomObjectValue>) {}
  }

  export class UpdateCustomObjectValueTemplateId {
    static readonly type = 'Add and Update Custom Object Value Template Id';
    constructor(public value: Partial<CustomObjectValue>) {}
  }

  export class CreateValue {
    static readonly type = 'Create Custom Object Value';
    constructor(public customObjectId: number, public value: CustomObjectValue) {}
  }

  export class UpdateValue {
    static readonly type = 'Update Custom Object Value';
    constructor(public customObjectId: number, public customObjectValueId: number, public value: CustomObjectValue) {}
  }

  export class DeleteValue {
    static readonly type = 'Delete Custom Object Value';
    constructor(public customObjectId: number, public customObjectValueId: number) {}
  }

  export namespace Graphql {
    export interface Params extends GraphqlParams {
      exportOptions?: ExportOptions;
    }

    export class Action {
      static readonly type = 'Custom Object Graphql';

      constructor(public graphqlParams: Params, public hiddenFilter: any[][]) {}
    }
  }
}
