export enum Permission {
  PROJECT_DASHBOARD_EDIT_LAYOUT = 'PROJECT_DASHBOARD_EDIT_LAYOUT',
  SETTINGS_ACCESS = 'SETTINGS_ACCESS',
  PROJECT_ADD_USER = 'PROJECT_ADD_USER',
  PROJECT_PIPELINE_CHANGE_VISIBLE_COLUMNS = 'PROJECT_PIPELINE_CHANGE_VISIBLE_COLUMNS',
  PROPERTY_DASHBOARD_EDIT_LAYOUT = 'PROPERTY_DASHBOARD_EDIT_LAYOUT',
  PROJECT_PIPELINE_ADD_USER = 'PROJECT_PIPELINE_ADD_USER',
  PROJECT_ACCESS_ALL = 'PROJECT_ACCESS_ALL',
  PROJECT_SHARE = 'PROJECT_SHARE',
  PIPELINE_MANAGE = 'PIPELINE_MANAGE',
  PIPELINE_EDIT_DYNAMIC_FILTER = 'PIPELINE_EDIT_DYNAMIC_FILTER',
  DASHBOARDS_VIEW = 'DASHBOARDS_VIEW',
  DASHBOARDS_CREATE_EDIT = 'DASHBOARDS_CREATE_EDIT',
  DASHBOARDS_DELETE = 'DASHBOARDS_DELETE',
  PROJECT_ACCESS_ALL_PIPELINES = 'PROJECT_ACCESS_ALL_PIPELINES',
  PIPELINE_DEAL_FORM_EDIT = 'PIPELINE_DEAL_FORM_EDIT',
  DETAILS_TAB = 'DETAILS_TAB',
  FILES_TAB = 'FILES_TAB',
  TASKS_TAB = 'TASKS_TAB',
  NOTES_TAB = 'NOTES_TAB',
  INSIGHTS_TAB = 'INSIGHTS_TAB',
  CONTACTS_TAB = 'CONTACTS_TAB',
  CUSTOM_OBJECTS_TAB = 'CUSTOM_OBJECTS_TAB',
  CONTROL_GROUP_BY_DROPDOWN = 'CONTROL_GROUP_BY_DROPDOWN',
  CONTACTS_ACCESS_ALL = 'CONTACTS_ACCESS_ALL',
  CONTACTS_CONTROL_COLUMNS = 'CONTACTS_CONTROL_COLUMNS',
  CONTACTS_ADD = 'CONTACTS_ADD',
  CONTACTS_MERGE = 'CONTACTS_MERGE',
  CONTACTS_DELETE = 'CONTACTS_DELETE',
  CONTACT_FORM_EDIT = 'CONTACT_FORM_EDIT',
  COMPANY_FORM_EDIT = 'COMPANY_FORM_EDIT',
  TASK_LIST_ACCESS_ALL = 'TASK_LIST_ACCESS_ALL',
  TASK_LIST_ADD_USER = 'TASK_LIST_ADD_USER',
  TASK_LIST_GLOBAL = 'TASK_LIST_GLOBAL',
  TASK_LIST_AGGREGATE = 'TASK_LIST_AGGREGATE',
  TASK_GLOBAL_SETTINGS = 'TASK_GLOBAL_SETTINGS',
  TASK_LIST_APPROVALS = 'TASK_LIST_APPROVALS',
  TASK_LIST_CHANGE_VISIBLE_COLUMNS = 'TASK_LIST_CHANGE_VISIBLE_COLUMNS',
  TASK_LIST_EDIT_LAYOUT = 'TASK_LIST_EDIT_LAYOUT',
  TASK_LIST_VIEWS = 'TASK_LIST_VIEWS',
  PROPERTY_DETAILS_TAB = 'PROPERTY_DETAILS_TAB',
  PROPERTY_FILES_TAB = 'PROPERTY_FILES_TAB',
  PROPERTY_NOTES_TAB = 'PROPERTY_NOTES_TAB',
  PROPERTY_LEASES_TAB = 'PROPERTY_LEASES_TAB',
  PROPERTY_DEALS_TAB = 'PROPERTY_DEALS_TAB',
  PROPERTY_NEARBY_TAB = 'PROPERTY_NEARBY_TAB',
  PROPERTY_CUSTOM_OBJECTS_TAB = 'PROPERTY_CUSTOM_OBJECTS_TAB',
  PROPERTY_CREATE = 'PROPERTY_CREATE',
  MAPS_ACCESS_ALL = 'MAPS_ACCESS_ALL',
  MAPS_EDIT_LAYOUT = 'MAPS_EDIT_LAYOUT',
  REPORTS_PDF = 'REPORTS_PDF',
  REPORTS_REPORT_BUILDER = 'REPORTS_REPORT_BUILDER',
  SETTINGS_USERS = 'SETTINGS_USERS',
  SETTINGS_PERMISSIONS = 'SETTINGS_PERMISSIONS',
  SETTINGS_DROPDOWNS = 'SETTINGS_DROPDOWNS',
  SETTINGS_FIELDS = 'SETTINGS_FIELDS',
  SETTINGS_TASK_TEMPLATES = 'SETTINGS_TASK_TEMPLATES',
  SETTINGS_TASK_ROLES = 'SETTINGS_TASK_ROLES',
  SETTINGS_SHEETS = 'SETTINGS_SHEETS',
  SETTINGS_APPS = 'SETTINGS_APPS',
  SETTINGS_CODE = 'SETTINGS_CODE',
  SETTINGS_IMPORTS = 'SETTINGS_IMPORTS',
  SETTINGS_OBJECT_MANAGER = 'SETTINGS_OBJECT_MANAGER',
  TASK_LIST_COLLABORATOR = 'TASK_LIST_COLLABORATOR',
  DEAL_TASK_SETTINGS = 'DEAL_TASK_SETTINGS',
  DEALS_ADD = 'DEALS_ADD',
  DEALS_DELETE = 'DEALS_DELETE',
  PROPERTY_TASKS_TAB = 'PROPERTY_TASKS_TAB',
  SETTINGS_NOTIFICATIONS = 'SETTINGS_NOTIFICATIONS',
  PRIVATE_PROPERTY_TASKS = 'PRIVATE_PROPERTY_TASKS',
  PRIVATE_DEAL_TASKS = 'PRIVATE_DEAL_TASKS',
  SETTINGS_AUTOMATIONS = 'SETTINGS_AUTOMATIONS',
  PROPERTY_DELETE = 'PROPERTY_DELETE',
  PROPERTY_FORM_EDIT = 'PROPERTY_FORM_EDIT',
}

export enum Module {
  DASHBOARDS = 'DASHBOARDS',
  DEALS = 'DEALS',
  CONTACTS = 'CONTACTS',
  TASKS = 'TASKS',
  PROPERTIES = 'PROPERTIES',
  MAPS = 'MAPS',
  REPORTS = 'REPORTS',
  SETTINGS = 'SETTINGS',
}

export enum ReservedRole {
  Admin = 'admin',
  Collaborator = 'collaborator',
  Limited = 'limited',
  Standard = 'standard',
}

export const ModulePermission = {
  [Module.DASHBOARDS]: [Permission.DASHBOARDS_CREATE_EDIT, Permission.DASHBOARDS_VIEW, Permission.DASHBOARDS_DELETE],
  [Module.DEALS]: [
    Permission.DEALS_ADD,
    Permission.PROJECT_PIPELINE_ADD_USER,
    Permission.PROJECT_ADD_USER,
    Permission.PROJECT_ACCESS_ALL_PIPELINES,
    Permission.PROJECT_ACCESS_ALL,
    Permission.PROJECT_SHARE,
    Permission.DETAILS_TAB,
    Permission.FILES_TAB,
    Permission.TASKS_TAB,
    Permission.PRIVATE_DEAL_TASKS,
    Permission.DEAL_TASK_SETTINGS,
    Permission.NOTES_TAB,
    Permission.INSIGHTS_TAB,
    Permission.CONTACTS_TAB,
    Permission.CUSTOM_OBJECTS_TAB,
    Permission.PIPELINE_DEAL_FORM_EDIT,
    Permission.PROJECT_DASHBOARD_EDIT_LAYOUT,
    Permission.PIPELINE_EDIT_DYNAMIC_FILTER,
    Permission.PIPELINE_MANAGE,
    Permission.PROJECT_PIPELINE_CHANGE_VISIBLE_COLUMNS,
    Permission.CONTROL_GROUP_BY_DROPDOWN,
    Permission.DEALS_DELETE,
  ],
  [Module.CONTACTS]: [
    Permission.CONTACTS_ACCESS_ALL,
    Permission.CONTACTS_ADD,
    Permission.CONTACTS_CONTROL_COLUMNS,
    Permission.CONTACTS_MERGE,
    Permission.CONTACTS_DELETE,
    Permission.CONTACT_FORM_EDIT,
    Permission.COMPANY_FORM_EDIT,
  ],
  [Module.TASKS]: [
    Permission.TASK_LIST_GLOBAL,
    Permission.TASK_LIST_ACCESS_ALL,
    Permission.TASK_GLOBAL_SETTINGS,
    Permission.TASK_LIST_AGGREGATE,
    Permission.TASK_LIST_VIEWS,
    Permission.TASK_LIST_ADD_USER,
    Permission.TASK_LIST_COLLABORATOR,
    Permission.TASK_LIST_APPROVALS,
    Permission.TASK_LIST_CHANGE_VISIBLE_COLUMNS,
    Permission.TASK_LIST_EDIT_LAYOUT,
  ],
  [Module.PROPERTIES]: [
    Permission.PROPERTY_CREATE,
    Permission.PROPERTY_DETAILS_TAB,
    Permission.PROPERTY_FILES_TAB,
    Permission.PROPERTY_TASKS_TAB,
    Permission.PRIVATE_PROPERTY_TASKS,
    Permission.PROPERTY_NOTES_TAB,
    Permission.PROPERTY_LEASES_TAB,
    Permission.PROPERTY_DEALS_TAB,
    Permission.PROPERTY_NEARBY_TAB,
    Permission.PROPERTY_CUSTOM_OBJECTS_TAB,
    Permission.PROPERTY_DASHBOARD_EDIT_LAYOUT,
    Permission.PROPERTY_DELETE,
    Permission.PROPERTY_FORM_EDIT,
  ],
  [Module.MAPS]: [Permission.MAPS_ACCESS_ALL, Permission.MAPS_EDIT_LAYOUT],
  [Module.REPORTS]: [Permission.REPORTS_PDF, Permission.REPORTS_REPORT_BUILDER],
  [Module.SETTINGS]: [
    Permission.SETTINGS_ACCESS,
    Permission.SETTINGS_USERS,
    Permission.SETTINGS_PERMISSIONS,
    Permission.SETTINGS_DROPDOWNS,
    Permission.SETTINGS_FIELDS,
    Permission.SETTINGS_TASK_TEMPLATES,
    Permission.SETTINGS_TASK_ROLES,
    Permission.SETTINGS_SHEETS,
    Permission.SETTINGS_APPS,
    Permission.SETTINGS_CODE,
    Permission.SETTINGS_IMPORTS,
    Permission.SETTINGS_OBJECT_MANAGER,
    Permission.SETTINGS_NOTIFICATIONS,
    Permission.SETTINGS_AUTOMATIONS,
  ],
};
