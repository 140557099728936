import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import clone from 'lodash-es/clone';
import { AccountService } from 'app/core';
import { PropertyPagination } from './properties.types';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Contact } from 'app/modules/contacts/contacts.types';
import { PartialFormField } from 'app/shared/components/form-fields/form-field.types';
import { Address, Project, Property, ProjectPagination, Image } from 'app/modules/projects/projects.types';

@Injectable({
  providedIn: 'root',
})
export class PropertiesService {
  private _property: BehaviorSubject<Property | null>;
  private _properties: BehaviorSubject<Property[] | null>;
  private _pagination: BehaviorSubject<PropertyPagination | null>;
  private _accountId: string;
  private _panelDefinition: BehaviorSubject<any | null>;
  private _changePanelState: BehaviorSubject<boolean | false>;
  private _contacts: BehaviorSubject<Contact[] | null>;

  constructor(private _httpClient: HttpClient, private _accountService: AccountService) {
    this._property = new BehaviorSubject(null);
    this._properties = new BehaviorSubject(null);
    this._pagination = new BehaviorSubject(null);
    this._changePanelState = new BehaviorSubject(false);
    this._panelDefinition = new BehaviorSubject(null);
    this._contacts = new BehaviorSubject(null);

    this._accountService.currentAccount.subscribe((accountData) => {
      this._accountId = accountData.id;
    });
  }

  get property$(): Observable<Property> {
    return this._property.asObservable();
  }

  get properties$(): Observable<Property[]> {
    return this._properties.asObservable();
  }

  get pagination$(): Observable<PropertyPagination> {
    return this._pagination.asObservable();
  }

  get changePanelState$(): Observable<boolean> {
    return this._changePanelState.asObservable();
  }

  get contacts$(): Observable<Contact[]> {
    return this._contacts.asObservable();
  }

  get panelDefinition$(): Observable<any> {
    return this._panelDefinition.asObservable();
  }

  set accountId(val: string) {
    this._accountId = val;
  }

  private _massageRequestFields(fields: string[]) {
    let requestFields = {};
    for (let field of fields) {
      requestFields[field] = true;
    }
    if (!fields.includes('id')) {
      requestFields['id'] = true;
    }
    if (!fields.includes('address_string')) {
      requestFields['address_string'] = true;
    }
    if (!fields.includes('featured_image_url')) {
      requestFields['featured_image_url'] = true;
    }
    if (!fields.includes('name')) {
      requestFields['name'] = true;
    }
    if (!fields.includes('address_string')) {
      requestFields['address_string'] = true;
    }

    if (fields.includes('address')) {
      requestFields['address'] = {
        latitude: true,
        longitude: true,
      };
    }
    return requestFields;
  }

  getPropertiesWithoutSort(
    fields: string[] = [],
    queryFilters: any = {},
    page: number = 0,
    size: number = 20,
    search: string = '',
    format: string = '',
    group: string = '',
    updateNext = true,
  ): Observable<any> {
    let node = this._massageRequestFields(clone(fields));

    const filter = clone(queryFilters);
    for (const key in filter) {
      if (filter[key] && filter[key].length > 0) {
        if (Array.isArray(filter[key])) {
          filter[key] = filter[key].join(',');
        }
      } else if (filter[key] && filter[key].length === 0) {
        if (Array.isArray(filter[key])) {
          filter[key] = '';
        }
      }
    }
    filter['q'] = search;

    const query = {
      query: {
        properties: {
          __args: {
            filter,
            page: page + 1,
            perPage: size,
          },
          pageInfo: {
            hasNextPage: true,
            hasPreviousPage: true,
            endCursor: true,
            startCursor: true,
            totalCount: true,
            filteredCount: true,
          },
          edges: {
            cursor: true,
            node,
          },
        },
      },
    };
    const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

    return this._httpClient
      .post<{ pagination: ProjectPagination; projects: Project[] }>(
        `graphql?account_id=${this._accountId}&format=${format}&group=${group}`,
        {
          query: gqlQuery,
        },
      )
      .pipe(
        map((response: any) => {
          if (format == 'csv' || format == 'pdf') {
            return response.data;
          } else {
            const pageInfo = response.data.properties.pageInfo;

            const lastPage = Math.max(Math.ceil(pageInfo.filteredCount / size), 1);
            const begin = page * size;
            const end = Math.min(size * (page + 1), pageInfo.filteredCount);

            const pagination = {
              length: pageInfo.filteredCount,
              size: size,
              page: page,
              lastPage: lastPage,
              startIndex: begin,
              endIndex: end,
            };
            const properties = response.data.properties.edges.map((edge) => edge.node);
            if (updateNext) {
              this._pagination.next(pagination);
              this._properties.next(properties);
            }
            return {
              pagination,
              properties,
            };
          }
        }),
      );
  }

  getProperties(
    fields: string[] = [],
    queryFilters: any = {},
    page: number = 0,
    size: number = 20,
    sort = 'name',
    order: 'asc' | 'desc' | '' = 'asc',
    search: string = '',
    format: string = '',
    group: string = '',
    enableNestedFilters = false,
  ): Observable<{ pagination: PropertyPagination; properties: Property[] }> {
    const collectionKey = !enableNestedFilters ? 'properties' : 'propertiesSqlBased';
    let node = this._massageRequestFields(clone(fields));

    const filter = clone(queryFilters);
    for (const key in filter) {
      if (filter[key] && filter[key].length > 0) {
        if (Array.isArray(filter[key])) {
          filter[key] = filter[key].join(',');
        }
      } else if (filter[key] && filter[key].length === 0) {
        if (Array.isArray(filter[key])) {
          filter[key] = '';
        }
      }
    }

    filter['q'] = search || queryFilters['q'] || '*';

    const query = {
      query: {
        [collectionKey]: {
          __args: {
            filter,
            page: page + 1,
            perPage: size,
            sort: { field: sort, order: order },
          },
          pageInfo: {
            hasNextPage: true,
            hasPreviousPage: true,
            endCursor: true,
            startCursor: true,
            totalCount: true,
            filteredCount: true,
          },
          edges: {
            cursor: true,
            node,
          },
        },
      },
    };
    const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

    return this._httpClient
      .post<{ pagination: ProjectPagination; projects: Project[] }>(
        `graphql?account_id=${this._accountId}&format=${format}&group=${group}`,
        {
          query: gqlQuery,
        },
      )
      .pipe(
        map((response: any) => {
          if (format == 'csv' || format == 'pdf') {
            return response.data;
          } else {
            const pageInfo = response.data[collectionKey].pageInfo;

            const lastPage = Math.max(Math.ceil(pageInfo.filteredCount / size), 1);
            const begin = page * size;
            const end = Math.min(size * (page + 1), pageInfo.filteredCount);

            const pagination = {
              length: pageInfo.filteredCount,
              size: size,
              page: page,
              lastPage: lastPage,
              startIndex: begin,
              endIndex: end,
            };
            const properties = response.data[collectionKey].edges.map((edge) => edge.node);
            this._pagination.next(pagination);
            this._properties.next(properties);
            return {
              pagination,
              properties,
            };
          }
        }),
      );
  }

  /**
   * Get contacts
   *
   * @param sortField
   * @param sortDirection
   */
  getPropertiesOld(
    page: number = 1,
    size: number = 20,
    sort = 'first_name',
    order: 'asc' | 'desc' | '' = 'asc',
    search: string = '',
    filters: any = {},
    csv = false,
  ): Observable<{ pagination: PropertyPagination; contacts: Property[] }> {
    const { name = '', email = '', phone = '', tags = '', category_name = '', job = '' } = filters;
    //set the page number to 1 if 0 as can happen in pagination
    if (page < 1) {
      page = 1;
    }
    return this._httpClient
      .get<{ pagination: PropertyPagination; contacts: Property[] }>(
        `properties${csv ? '.csv' : ''}?account_id=${this._accountId}`,
        {
          params: {
            page: '' + page,
            per_page: '' + size,
            sort: sort,
            order: order,
            search: search,
            name: name,
            email: email,
            phone: phone,
            tags: tags?.length ? tags.join(',') : '',
            category_id: category_name,
            job_title: job,
            company: job,
          },
        },
      )
      .pipe(
        map((response: any) => {
          if (csv && response) {
            return response.data;
          }
          const lastPage = Math.max(Math.ceil(response.count / size), 1);
          const begin = page * size;
          const end = Math.min(size * (page + 1), response.count);

          const pagination = {
            length: response.count,
            size: size,
            page: page - 1,
            lastPage: lastPage - 1,
            startIndex: begin,
            endIndex: end,
          };

          let properties = response.results;
          this._pagination.next(pagination);
          this._properties.next(properties);

          return {
            pagination,
            properties,
          };
        }),
      );
  }

  /**
   * Get contact by id
   */
  getPropertyById(id: string, touch: boolean = false): Observable<Property> {
    return this._httpClient.get<any>(`properties/${id}?account_id=${this._accountId}&touch=${touch}`).pipe(
      tap((response) => {
        this._property.next(response.property);
        return response.property;
      }),
    );
  }

  addAddressToProperty(address: Address, propertyId?: number): Observable<Address> {
    return this.property$.pipe(
      take(1),
      switchMap((project) =>
        this._httpClient
          .post<any>(`addresses?account_id=${this._accountId}&property_id=${propertyId}`, { address: address })
          .pipe(
            map((response) => {
              return response.address;
            }),
          ),
      ),
    );
  }

  updatePropertyAddress(address: Address, propertyId?: number): Observable<Address> {
    return this.property$.pipe(
      take(1),
      switchMap((project) =>
        this._httpClient
          .put<any>(`addresses/${address.id}?account_id=${this._accountId}&property_id=${propertyId}`, {
            address: address,
          })
          .pipe(
            map((response) => {
              return response.address;
            }),
          ),
      ),
    );
  }

  saveProperty(
    property: Property | PartialFormField,
    fillProperty: Property | PartialFormField = {},
  ): Observable<Property> {
    if (property.id) {
      const params: any = {
        property,
      };
      return this.properties$.pipe(
        take(1),
        switchMap((properties) =>
          this._httpClient.put<any>(`properties/${property.id}?account_id=${this._accountId}`, params).pipe(
            map((response) => {
              let updatedProperty = response.property;
              updatedProperty = { ...fillProperty, ...property, ...response.property };
              // Find the index of the updated contact
              if (properties) {
                const index = properties.findIndex((item) => item.id === property.id);

                // Update the contact
                properties[index] = updatedProperty;

                // Update the contacts
                this._properties.next(properties);
              }

              // Return the updated contact
              return updatedProperty;
            }),
            switchMap((updatedProperty) =>
              this.property$.pipe(
                take(1),
                filter((item) => item && +item.id === +property.id),
                tap(() => {
                  // Update the contact if it's selected
                  this._property.next(updatedProperty);

                  // Return the updated contact
                  return updatedProperty;
                }),
              ),
            ),
          ),
        ),
      );
    } else {
      return this.properties$.pipe(
        take(1),
        switchMap((properties) =>
          this._httpClient.post<any>(`properties?account_id=${this._accountId}`, { property: property }).pipe(
            map((reponse) => {
              if (properties) {
                // Update the contacts with the new contact
                this._properties.next([reponse.property, ...properties]);
              }

              //update so that we can add properties
              this._property.next(reponse.property);

              // Return the new contact
              return reponse.property;
            }),
          ),
        ),
      );
    }
  }

  /**
   * Use this method if if you don't have the properties observable and just want to update the property
   * @param property
   * @returns
   */
  savePropertyDirect(
    property: Property | PartialFormField,
    fillProperty: Property | PartialFormField = {},
  ): Observable<{ property: Property }> {
    if (property.id) {
      const params: any = {
        property,
      };
      return this._httpClient.put<any>(`properties/${property.id}?account_id=${this._accountId}`, params).pipe(
        map((response) => {
          let updatedProperty = response.property;
          updatedProperty = { ...fillProperty, ...property, ...response.property };
          return { property: updatedProperty };
        }),
      );
    } else {
      return this._httpClient.post<any>(`properties?account_id=${this._accountId}`, { property: property }).pipe(
        map((reponse) => {
          return { property: reponse.property };
        }),
      );
    }
  }

  deleteProperty(id: number): Observable<any> {
    return this.properties$.pipe(
      take(1),
      switchMap((properties) =>
        this._httpClient.delete<any>(`properties/${id}?account_id=${this._accountId}`).pipe(
          map((response) => {
            if (properties) {
              // Find the index of the deleted label within the labels
              const index = properties.findIndex((item) => item.id == id);

              // Delete the label
              properties.splice(index, 1);

              // Update the labels
              this._properties.next(properties);
            }

            // Return the deleted status
            return response.property;
          }),
        ),
      ),
    );
  }

  /**
   * Upload Image to property
   */
  uploadImage(file: File): Observable<Image> {
    const uploadData = new FormData();
    uploadData.append('image[attachment]', file);

    return this.property$.pipe(
      take(1),
      switchMap((property) =>
        this._httpClient.post<any>(`images?account_id=${this._accountId}&property_id=${property.id}`, uploadData).pipe(
          map((response) => {
            let images = property.images;
            images.push(response?.image);
            property.images = images;
            this._property.next(property);
            return response;
          }),
        ),
      ),
    );
  }

  /**
   * Delete image
   */
  deleteImage(id: number): Observable<any> {
    return this.property$.pipe(
      take(1),
      switchMap((property) =>
        this._httpClient.delete<any>(`images/${id}?account_id=${this._accountId}`).pipe(
          map((response) => {
            let images = property.images;
            // Find the index of the deleted label within the labels
            const index = images.findIndex((item) => item.id === id);

            // Delete the label
            images.splice(index, 1);
            property.images = images;

            // Update the labels
            this._property.next(property);

            // Return the deleted status
            return response;
          }),
        ),
      ),
    );
  }

  getContacts(): Observable<Contact[]> {
    return this.property$.pipe(
      take(1),
      switchMap((property) => this.getContactassociations(property)),
    );
  }

  getContactassociations(property) {
    return this._httpClient
      .get<any>(
        `contact_associations?account_id=${this._accountId}&related_to_type=Property&related_to_id=${property.id}`,
      )
      .pipe(
        map((response) => {
          let contacts = [];

          if (response?.contact_associations) {
            contacts = response.contact_associations.filter((ca) => ca.contact != null).map((ca) => ca.contact);
          }

          this._contacts.next(contacts);

          return contacts;
        }),
      );
  }

  updateContactInfo(contact: Contact): Observable<Contact> {
    return this.contacts$.pipe(
      take(1),
      switchMap((contacts) =>
        this._httpClient
          .patch<any>(`contacts/${contact.id}?account_id=${this._accountId}`, {
            contact,
          })
          .pipe(
            map((response) => {
              if (contacts) {
                // Find the index of the updated contact
                const index = contacts.findIndex((item) => item.id === contact.id);

                // Update the contact
                contacts[index] = response.contact;

                // Update the contacts
                this._contacts.next(contacts);
              }
              return contact;
            }),
          ),
      ),
    );
  }

  createContactAssociation(contact: Contact, propertyId: number): Observable<Contact> {
    const contactAssociation = {
      related_to_id: propertyId,
      related_to_type: 'Property',
      contact: contact,
    };
    return this.contacts$.pipe(
      take(1),
      switchMap((contacts) =>
        this._httpClient
          .post<any>(`contact_associations?account_id=${this._accountId}`, {
            contact_association: contactAssociation,
          })
          .pipe(
            map((response) => {
              let newContact = response.contact_association.contact;
              if (contacts) {
                contacts.unshift(newContact);
                this._contacts.next(contacts);
              }
              return newContact;
            }),
          ),
      ),
    );
  }

  deleteContactAssociation(id: number, propertyId: number): Observable<any> {
    return this.contacts$.pipe(
      take(1),
      switchMap((contacts) =>
        this._httpClient
          .delete<any>(
            `contact_associations/${id}?account_id=${this._accountId}&related_to_type=Property&related_to_id=${propertyId}`,
          )
          .pipe(
            map((response) => {
              // Find the index of the deleted label within the labels
              const index = contacts.findIndex((item) => item.id == id.toString());
              // Delete the label
              contacts.splice(index, 1);
              // Update the labels
              this._contacts.next(contacts);

              // Return the deleted status
              return response;
            }),
          ),
      ),
    );
  }

  generatePropertyDocument(
    id: number,
    format: string = 'json',
    file_resource_id: number = null,
    sendToDocusign: boolean = null,
  ): Observable<any> {
    return this._httpClient
      .get<any>(
        // eslint-disable-next-line max-len
        `properties/${id}?account_id=${this._accountId}&format=${format}&file_resource_id=${file_resource_id}&send_to_docusign=${sendToDocusign}`,
      )
      .pipe(
        map((response) => {
          if (format == 'pdf' || format == 'docx') {
            return response;
          } else {
            this._property.next(response.property);
            return response.project;
          }
        }),
      );
  }

  createContact(contact: Contact): Observable<Contact> {
    return this._httpClient.post<any>(`contacts?account_id=${this._accountId}`, { contact: contact }).pipe(
      map((response) => {
        return response.contact;
      }),
    );
  }

  changePanelLayout(state) {
    this._changePanelState.next(state);
    return state;
  }

  addPanelDefinition(panelDef) {
    this._panelDefinition.next(panelDef);
    return panelDef;
  }
}
