import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { Automation, AutomationResponse, AutomationsResponse } from './automations.types';
import { Automation as AutomationModel } from './builder/builder.types';

@Injectable({ providedIn: 'root' })
export class AutomationsService {
  constructor(private readonly httpClient: HttpClient) {}

  public create(accountId: number, automation: AutomationModel): Observable<AutomationModel> {
    return this.httpClient
      .post<{ automation: AutomationModel }>(`automations?account_id=${accountId}`, { automation })
      .pipe(map((response) => response.automation));
  }

  public get(accountId: number): Observable<AutomationsResponse> {
    return this.httpClient.get<AutomationsResponse>(`automations?account_id=${accountId}`).pipe(take(1));
  }

  public add(accountId: number, automation: Automation): Observable<AutomationResponse> {
    const newAutomation = {
      name: automation.name,
      is_active: automation.is_active,
      target_model: automation.target_model,
      triggers: automation.triggers,
      actions: automation.actions,
    };

    return this.httpClient
      .post<AutomationResponse>(`automations?account_id=${accountId}`, { automation: newAutomation })
      .pipe(
        take(1),
        catchError((response: HttpErrorResponse) => {
          return throwError(response?.error as AutomationResponse);
        }),
      );
  }

  public update(accountId: number, automation: Automation): Observable<AutomationResponse> {
    const id = automation.id;
    const updatedAutomation = {
      name: automation.name,
      is_active: automation.is_active,
      target_model: automation.target_model,
      triggers: automation.triggers,
      actions: automation.actions,
    };

    return this.httpClient
      .put<AutomationResponse>(`automations/${id}?account_id=${accountId}`, { automation: updatedAutomation })
      .pipe(
        take(1),
        catchError((response: HttpErrorResponse) => {
          return throwError(response?.error as AutomationResponse);
        }),
      );
  }

  public remove(accountId: number, id: number): Observable<AutomationResponse> {
    return this.httpClient.delete<AutomationResponse>(`automations/${id}?account_id=${accountId}`).pipe(take(1));
  }
}
