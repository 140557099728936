import { GraphqlFilter, GraphqlParams, GraphqlResultPageInfo } from '@shared/models/graphql';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { StateBase } from '@state/state-base';
import { ContactActions } from './actions';
import GraphqlService from '@services/graphql.service';
import { tap } from 'rxjs';
import { ExportOptions } from '@shared/models/export-options';
import { FileFormat } from '@shared/enums';
import { Contact } from '../contacts.types';
import { generateDownloadableFile } from '@shared/functions';
import { TsSnackbarService } from 'tsui';
import { ObjectsManagerState } from '../../settings/objects-manager/state/state';

export interface ContactStateModel {
  contacts: Contact[];
  pageInfo: GraphqlResultPageInfo;
}

const initialPageInfoState: GraphqlResultPageInfo = {
  hasNextPage: false,
  hasPreviousPage: false,
  startCursor: '',
  endCursor: '',
  pageSize: 20,
  currentPage: 1,
  totalCount: 0,
  filteredCount: 0,
};

@State<ContactStateModel>({
  name: new StateToken<ContactStateModel>('contactState'),
  defaults: { contacts: [], pageInfo: initialPageInfoState },
})
@Injectable()
export class ContactState extends StateBase {
  constructor(
    private readonly graphqlService: GraphqlService,
    private readonly snackBar: TsSnackbarService,
    protected readonly store: Store,
  ) {
    super(store);
  }

  @Selector()
  static selectState(state: ContactStateModel): ContactStateModel {
    return state;
  }

  @Selector()
  static selectPagination(state: ContactStateModel): GraphqlResultPageInfo {
    return state.pageInfo;
  }

  @Selector()
  static selectContacts(state: ContactStateModel): Contact[] {
    return state.contacts;
  }

  @Selector([ContactState.selectContacts])
  static selectContactsCount(_: ContactStateModel, contact: Contact[]): number {
    return contact.length;
  }

  @Action(ContactActions.GetContacts)
  getContacts(context: StateContext<ContactStateModel>, { params }: { params: GraphqlParams }) {
    const graphQlQueryParams = this.getContactsGraphQlQueryParams(params);

    return this.graphqlService.query(Number(this.accountId), graphQlQueryParams).pipe(
      tap(({ edges, pageInfo }) => {
        const rows = edges.map((edge) => edge.node) as Contact[];

        context.patchState({
          contacts: rows,
          pageInfo,
        });
      }),
    );
  }

  @Action(ContactActions.ExportContacts)
  exportContacts(
    context: StateContext<ContactStateModel>,
    {
      params,
      savedViewId,
    }: {
      params: GraphqlParams;
      savedViewId: string;
    },
  ) {
    const graphQlQueryParams = this.getContactsGraphQlQueryParams(params);
    const exportOptions: ExportOptions = {
      format: FileFormat.CSV,
      fileName: 'Contacts',
      savedViewId: savedViewId,
    };

    return this.graphqlService.query(Number(this.accountId), graphQlQueryParams, exportOptions).pipe(
      tap((res: any) => {
        if (res?.message) {
          this.snackBar.open(res?.message, 'info');
        } else {
          generateDownloadableFile(res, FileFormat.CSV, `Contacts`);
        }
      }),
    );
  }

  private getContactsGraphQlQueryParams(params: GraphqlParams<GraphqlFilter>) {
    const contactFieldDefs = this.store.selectSnapshot(
      ObjectsManagerState.getFieldsByObjectName('Contact', {
        allowedFieldsOnly: true,
        addNestedFields: true,
      }),
    );
    return {
      ...params,
      fields: ['id', ...params.fields],
      fieldDefs: contactFieldDefs,
    };
  }
}
