import { Directive, ElementRef, OnInit, Output, EventEmitter, NgZone } from '@angular/core';
import { AddressPart, Place, googlePlacesDictionary, parsePlace } from '../models/address';

declare let google: any;

@Directive({
  selector: '[google-place]',
})
export class GooglePlacesDirective implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onSelectParts: EventEmitter<AddressPart> = new EventEmitter();

  private get element(): HTMLInputElement {
    return this.elementRef.nativeElement;
  }

  constructor(private readonly elementRef: ElementRef, private readonly ngZone: NgZone) {}

  ngOnInit(): void {
    const autoComplete = new google.maps.places.Autocomplete(this.element);

    google.maps.event.addListener(autoComplete, 'place_changed', () => {
      const selectedPlace: Place = autoComplete.getPlace();
      let results = {} as AddressPart;
      let formattedAddress = '';

      if (selectedPlace) {
        results = parsePlace(selectedPlace);

        results['route'] = results['route'] ?? selectedPlace.name;
        formattedAddress = selectedPlace.formatted_address;
      }

      this.ngZone.run(() => {
        this.onSelectParts.emit(results);
        this.onSelect.emit(formattedAddress);
      });
    });
  }
}
