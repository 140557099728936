import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AccountService, AuthService } from 'app/core';
import { getSubdomain } from './shared/Utils/common.utils';
import { Account } from './core/models';
import { Permission } from './core/enums/permission';
import { Store } from '@ngxs/store';
import { AccountActions } from './state/account/actions';
import { UserState } from './state/user/state';
import { AccountState } from './state/account/state';

@Injectable({
  providedIn: 'root',
})
export class InitialDataResolver {
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private _accountService: AccountService,
    private readonly authService: AuthService,
    private readonly store: Store,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Load messages
   *
   * @private
   */
  private _loadMessages(): Observable<any> {
    return this._httpClient.get('api/common/messages');
  }

  /**
   * Load navigation data
   *
   * @private
   */
  private _loadNavigation(): Observable<any> {
    return this._httpClient.get('api/common/navigation');
  }

  /**
   * Load notifications
   *
   * @private
   */
  private _loadNotifications(): Observable<any> {
    return of([]);
  }

  /**
   * Load shortcuts
   *
   * @private
   */
  private _loadShortcuts(): Observable<any> {
    return this._httpClient.get('api/common/shortcuts');
  }

  /**
   * Load user
   *
   * @private
   */
  private _loadUser(): Observable<any> {
    const account = this.store.selectSnapshot(AccountState.getAccount);
    const user = this.store.selectSnapshot(UserState.getUser);
    const hasPermissionsAssigned = this.authService.hasPermissionsAssigned;

    if (account && user && hasPermissionsAssigned) {
      return of(user);
    } else {
      const subdomain = getSubdomain();
      if (subdomain) {
        return this._accountService.getAccount(subdomain).pipe(
          switchMap((account: Account) => this._accountService.getUserPermissionByAccountId(Number(account.id))),
          tap((permissions) => this.authService.setPermissions(permissions.map(({ name }) => name as Permission))),
          switchMap(() => this._accountService.getUser()),
        );
      }

      return this._accountService.getUser();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin([
      // User
      this._loadUser(),
    ]).pipe(
      map((data) => {
        return {
          user: data[0],
        };
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class AccountResolver {
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private readonly accountService: AccountService, private readonly store: Store) {}

  private _loadAccount(): Observable<Account> {
    const subdomain = getSubdomain();
    if (subdomain) {
      return this.accountService.loadAccount(subdomain);
    } else {
      return null;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Account> {
    const account = this.store.selectSnapshot(AccountState.getAccount);

    if (account) {
      return of(account);
    }

    return this._loadAccount().pipe(tap((account) => this.store.dispatch(new AccountActions.SetAccount(account))));
  }
}
