import { ReportLayout } from 'app/modules/reports/reports.types';
import { ExportOptions } from 'app/shared/models/export-options';
import { GraphqlParams } from 'app/shared/models/graphql';
import { CreateReportModel, ReportLayoutQueryParams, ReportGraphqlFilter, ReportLayoutChart } from '../reporting.types';

export namespace Report {
  export class Get {
    static readonly type = '[Report] Get Reports';

    constructor(public filter: ReportLayoutQueryParams) {}
  }

  export class GetById {
    static readonly type = '[Report] Get Report By Id';

    constructor(public id: number, public refreshChart?: boolean) {}
  }

  export class Create {
    static readonly type = '[Report] Create Report';

    constructor(public model: CreateReportModel) {}
  }

  export class Delete {
    static readonly type = '[Report] Delete Report';

    constructor(public reportId: number) {}
  }

  export class Update {
    static readonly type = '[Report] Update Report';

    constructor(
      public model: ReportLayout<any>,
      public options: { dispatchBuildChart: boolean; refreshChart: boolean },
    ) {}
  }

  export class Duplicate {
    static readonly type = '[Report] Duplicate Report';

    constructor(public model: ReportLayout<any>) {}
  }

  export class ClearSelected {
    static readonly type = '[Report] Clear Selected Report';
  }

  export class Export {
    static readonly type = '[Report] Export Report';

    constructor(public id: number) {}
  }

  export class ToggleFavorite {
    static readonly type = '[Report] Toggle Favorite';

    constructor(public id: number) {}
  }
}

export namespace BuildChartData {
  export interface Params {
    reportLayoutId: number;
    charts: Partial<ReportLayoutChart>[];
  }

  export class Action {
    static readonly type = '[Report] Build Chart Data';

    constructor(public buildChartDataParams: Params, public options: { refreshChart: boolean }) {}
  }
}

export namespace Graphql {
  export interface Params extends GraphqlParams<ReportGraphqlFilter> {
    exportOptions?: ExportOptions;
  }

  export class Action {
    static readonly type = '[Report] Reporting Graphql';

    constructor(public graphqlParams: Params) {}
  }
}

export namespace DrillData {
  export class Action {
    static readonly type = '[Report] Drill Data';

    constructor(public grapqlParams: Graphql.Params) {}
  }
}

export namespace SummaryFormula {
  export class Get {
    static readonly type = '[SummaryFormula] Get Summary Formulas';

    constructor() {}
  }

  export class GetById {
    static readonly type = '[SummaryFormula] Get Report By Id';

    constructor(public id: number) {}
  }
}
