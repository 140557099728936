import { FieldDef } from 'app/core/models';
import { DrillFilter, ListFilterItem, ReportLayoutChart } from './reporting.types';

const replaceSortField = (field: string): string => {
  switch (field) {
    case 'investment_type_id':
      return 'investment_type_rank';
    case 'property_type_id':
      return 'property_type_name';
    case 'project_stage_id':
      return 'project_stage_rank';
    case 'team_id':
      return 'team_rank';
    case 'vehicle_id':
      return 'vehicles.rank';
    default:
      return field;
  }
};

const getFilterRows = (params: { options: any[]; fields: FieldDef[] }): any[] => {
  const newFilterFields = [];

  const approvedType = (field) => {
    return (
      field.type === 'number' ||
      field.type === 'decimal' ||
      field.type === 'percent' ||
      field.type === 'date' ||
      field.type === 'datetime' ||
      field.type === 'money' ||
      field.type === 'text' ||
      field.type === 'year' ||
      field.type === 'checkbox' ||
      field.type === 'select' ||
      field.type === 'record'
    );
  };

  const getOptions = (field) => {
    if (field.meta.option_name) {
      // Manually handle task_types for Report of type Task.
      if (field.meta.option_name === 'task_types') {
        params.options['task_types'] = [
          { id: 0, name: 'Task' },
          { id: 1, name: 'Section' },
          { id: 2, name: 'KeyDate' },
        ];
      }

      let options = params.options[field.meta.option_name]?.filter((option) => !option.hidden);

      if (field.meta.option_name == 'pipelines') {
        options = options.filter((pipeline) => pipeline?.pipeline_type != 'dynamic');
      }

      if (field.meta.options) {
        options = field.meta.options.map((option) => ({ id: option, name: option }));
      }

      return options;
    } else if (field.meta.options) {
      return field.meta.options.map((option) => ({ id: option, name: option }));
    }
  };

  const getCustomOptions = (field) => {
    return field.meta.options;
  };

  const addStandardField = (field) => {
    newFilterFields.push({
      id: field.name,
      name: field.label,
      type: field.type,
      options: [],
      custom: false,
      operators: field.meta.filtering.operators,
      functions: field.meta.filtering?.functions,
    });
  };

  const addCustomField = (field) => {
    newFilterFields.push({
      id: field.name,
      name: field.label,
      type: field.type,
      options: [],
      custom: true,
      operators: field.meta.filtering.operators,
      field_id: field.id,
      functions: field.meta.filtering?.functions,
    });
  };

  const addDefaultSelectField = (field) => {
    let options = getOptions(field);
    newFilterFields.push({
      id: field.name,
      name: field.label,
      type: 'multiple',
      options: options,
      custom: false,
      operators: field.meta.filtering.operators,
    });
  };

  const addCustomSelectField = (field) => {
    let options = getCustomOptions(field);
    newFilterFields.push({
      id: field.name,
      name: field.label,
      type: 'select',
      options: options,
      custom: true,
      operators: field.meta.filtering.operators,
      field_id: field.id,
    });
  };

  const addRecordCustomField = (field) => {
    const options = getCustomOptions(field);
    newFilterFields.push({
      id: field.name,
      name: field.label,
      type: options.length > 0 ? 'multiple' : 'autocomplete',
      options,
      custom: true,
      record_type: field.meta.record_type,
      operators: field.meta.filtering.operators,
      field_id: field.id,
      functions: field.meta.filtering?.functions,
      related_to_type: field.related_to_type,
    });
  };

  const customFieldDefs = params.fields.filter((o) => {
    return o.meta?.is_custom;
  });

  const standardFieldDefs = params.fields.filter((o) => {
    return !o.meta?.is_custom;
  });

  for (let standardField of standardFieldDefs) {
    if (approvedType(standardField)) {
      if (
        standardField.meta &&
        standardField.meta.filtering &&
        standardField.meta.filtering.operators &&
        standardField.type !== 'select'
      ) {
        addStandardField(standardField);
      }

      if (
        standardField.meta &&
        standardField.meta.filtering &&
        standardField.meta.filtering.operators &&
        standardField.type === 'select'
      ) {
        addDefaultSelectField(standardField);
      }
    }
  }

  for (let customField of customFieldDefs) {
    if (approvedType(customField) && customField.meta?.filtering?.operators) {
      if (customField.type === 'record') {
        addRecordCustomField(customField);
      } else if (customField.type !== 'select') {
        addCustomField(customField);
      } else if (customField.type === 'select') {
        addCustomSelectField(customField);
      }
    }
  }

  return newFilterFields;
};

const fjsonToListFilterItem = (params: { queryFilters: any; options: any[]; fields: FieldDef[] }): ListFilterItem[] => {
  const filterRows = [];
  const newFilterFields = getFilterRows(params);

  const getMultiRecordName = (record) => {
    if (Array.isArray(record) && record.length === 2 && typeof record[1] === 'string') {
      return record[1];
    }

    if (record[0]) {
      let result = record[0][0][1];
      if (result === undefined) {
        result = record[0][1];
      }

      return result;
    }
  };

  const populateFilters = (filters) => {
    if (filters && newFilterFields.length > 0) {
      Object.keys(filters).forEach((x) => {
        let field = newFilterFields.find((filter) => {
          return filter.id === x;
        });
        if (x && !field && x.includes('Id')) {
          let x2 = x
            .split(/(?=[A-Z])/)
            .join('_')
            .toLowerCase()
            .replace('_in', '');
          field = newFilterFields.find((filter) => {
            return filter.id === x2;
          });
        }
        if (field) {
          if (field?.type === 'multiple') {
            let valueArray = Array.from(filters[x]);
            if (valueArray.length > 0) {
              filterRows.push({
                id: field.id,
                field: field,
                type: field.type,
                filter: 'in',
                value: filters[x].map((i) => Number(i)),
                options: field.options,
              });
            } else filters[x] = '';
          } else if (filters[x]) {
            filterRows.push({
              id: field.id,
              field: field,
              type: field.type,
              filter: 'eq',
              value: filters[x],
              options: field.options,
            });
          }
        }
      });

      if (filters['fjson']) {
        if (newFilterFields.length > 0) {
          const fjson = JSON.parse(filters['fjson']);
          for (const customFilter in fjson) {
            const field = newFilterFields.find((filter) => filter.id === fjson[customFilter][0]);

            if (!field) {
              continue;
            }

            if (field?.type === 'record') {
              filterRows.push({
                id: fjson[customFilter][0],
                field: field,
                type: field.type,
                filter: fjson[customFilter][1],
                value: getMultiRecordName(fjson[customFilter][2]),
                alternate_value: fjson[customFilter][2],
                options: field.options,
              });
            } else {
              filterRows.push({
                id: fjson[customFilter][0],
                field: field,
                type: field.type,
                filter: fjson[customFilter][1],
                value: fjson[customFilter][2],
                options: field.options,
              });
            }
          }
        }
      }
    }
  };

  populateFilters(params.queryFilters);

  return filterRows;
};

const formatFilterItem = (
  key: string,
  rawValue: any,
  seriesValue: { name: string; value: any },
  fieldDefs: FieldDef[],
  hasGroupBy = false,
): [string, string, any] => {
  const fieldDef = fieldDefs.find(({ name }) => name === key);
  const fieldType = fieldDef?.type;
  const isRecord = fieldType === 'record';

  // if ((isRecord || (key.startsWith('cf_linked') && !key.includes('.'))) && seriesValue && hasGroupBy) {
  //   return [key, 'in', [[seriesValue.value, seriesValue.name]]];
  // } else {
  const hasInOperator = fieldDef?.meta?.filtering?.operators?.includes('in');

  if (hasInOperator) {
    return [key, 'in', fieldType !== 'datetime' ? [rawValue] : rawValue];
  }

  const isKey = key.endsWith('_id') || key === 'id';
  let value = isKey || isRecord ? [rawValue] : rawValue;
  let filter = isKey || isRecord ? 'in' : 'eq';
  if ([null, undefined].includes(value) || value === '') {
    value = [null];
    filter = 'in';
  }

  return [key, filter, value];
  //}
};

const getDrillFilters = (chartData: ReportLayoutChart, fieldDefs: FieldDef[], hasGroupBy = false): DrillFilter => {
  return chartData.rankedCategories
    .map((rankedCategory) =>
      chartData.filters.find(({ value, category }) => value === rankedCategory || category === rankedCategory),
    )
    .reduce(
      (previous, current, index) => ({
        ...previous,
        [index]: formatFilterItem(current.key, current.value, chartData.series[index], fieldDefs, hasGroupBy),
      }),
      {},
    );
};

const sortByProp = (a: any, b: any, prop: string) => {
  if (a[prop] < b[prop]) return -1;
  if (a[prop] > b[prop]) return 1;
  return 0;
};

export { fjsonToListFilterItem, replaceSortField, formatFilterItem, getDrillFilters, getFilterRows, sortByProp };
