/**
 * ref.: https://github.com/JsDaddy/ngx-mask/blob/develop/USAGE.md
 */
export type PhoneNumberMasks = '000-00009' | '(000) 000-00009' | '0*';

export abstract class FieldMaskUtils {
  static maskFormattingFunctionsMap = Object.freeze({
    phone: FieldMaskUtils.getPhoneNumberMask,
    mobile_phone: FieldMaskUtils.getPhoneNumberMask,
  });

  static getPhoneNumberMask(fieldValue?: string): PhoneNumberMasks {
    const noAreaCodeUsPhoneFormatLength = 7;
    const withAreaCodeUsPhoneFormatLength = 10;

    switch (fieldValue?.length) {
      case noAreaCodeUsPhoneFormatLength:
        return '000-00009';
      case withAreaCodeUsPhoneFormatLength:
        return '(000) 000-00009';
      default:
        return '0*';
    }
  }
}
