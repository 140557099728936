import { Account, AccountSettings } from 'app/core/models';

export namespace AccountActions {
  export class SetAccount {
    static readonly type = '[Account] Set Account';

    constructor(public account: Account) {}
  }

  export class UpdateAccount {
    static readonly type = '[Account] Update Account';

    constructor(public settings: AccountSettings) {}
  }

  export class UploadAccountImage {
    static readonly type = '[Account] Upload Account Image';

    constructor(public file: File) {}
  }

  export class RemoveAccountImage {
    static readonly type = '[Account] Remove Account Image';
  }
}
