<div class="confirm-dialog" [ngStyle]="{ 'max-width': maxWidth }">
  <h2 mat-dialog-title>{{ dialogData.title }}</h2>
  <mat-dialog-content>
    <p [innerHtml]="dialogData.message"></p>
  </mat-dialog-content>
  <mat-dialog-actions [align]="'end'">
    <button mat-stroked-button mat-dialog-close (click)="delete()">No</button>
    <button mat-raised-button [color]="'primary'" (click)="delete(true)">Yes</button>
  </mat-dialog-actions>
</div>
