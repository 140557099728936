import { Component } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { SharedModule } from 'app/shared/shared.module';
import { HubspotChatbotService } from 'app/core';

@Component({
  selector: 'support-hub',
  templateUrl: './support-hub.component.html',
  styleUrls: ['./support-hub.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, MatTooltipModule, MatIconModule, SharedModule],
})
export class SupportHubComponent {
  constructor(private readonly _hubspotChatService: HubspotChatbotService) {}
  public pageAddresses = {
    systemStatus: 'https://status.termsheet.com/',
    support: 'https://help.termsheet.com/',
    releases: 'https://releases.termsheet.com/',
    training: 'https://www.youtube.com/playlist?list=PL40_MaUuXYOJfo1WqwdtBZeFvpFEMZd_n',
  };

  public navigateToAddress(address: string): Window {
    return window.open(address, '_blank');
  }

  public openChatBot(): void {
    this._hubspotChatService.loadWidget();
  }

  public emailUs(): string {
    return `mailto:support@termsheet.com`;
  }
}
