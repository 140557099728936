<mat-form-field class="flex-auto gt-xs:pr-3 w-full">
  <mat-label>
    <span
      class="block text-ellipsis overflow-hidden pointer-events-auto"
      [matTooltip]="fieldDef.label"
      tooltipIfEllipsis
    >
      {{ fieldDef.label }}
    </span>
  </mat-label>
  <input
    matInput
    [matDatepicker]="dp"
    (dateChange)="onDateChange()"
    (blur)="dateBlur($event)"
    [formControl]="date"
    [readonly]="readonly"
    [disabled]="isDisabled"
    [matTooltip]="showTip"
  />
  <mat-datepicker #dp [disabled]="isDisabled"></mat-datepicker>
  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
  <mat-error> Date should be in {{ dateDisplayFormat }} format. </mat-error>
</mat-form-field>
