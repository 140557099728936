<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'"></empty-layout>

<!-- Modern -->
<modern-layout *ngIf="layout === 'modern'"></modern-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<div
  *ngIf="showActivitiesPanel$ | async"
  class="activities-drawer-toggle fixed flex items-center justify-center right-0 w-12 h-12 shadow-md rounded-l-4 z-99 cursor-pointer bg-cool-gray-500"
  style="top: 310px"
  (click)="activitiesDrawer.toggle()"
>
  <mat-icon class="icon-size-24 text-white" [svgIcon]="'dynamic_feed'"></mat-icon>
</div>

<treo-drawer
  [fixed]="true"
  name="customDrawer"
  position="right"
  mode="over"
  [transparentOverlay]="false"
  (openedChanged)="customDrawerOpenedChanged($event)"
  #customDrawer
  class="custom-drawer"
>
  <ng-template #childLoader></ng-template>
</treo-drawer>

<!-- Recent Activities Drawer -->
<treo-drawer
  [fixed]="true"
  [name]="'activitiesDrawer'"
  [position]="'right'"
  [mode]="'over'"
  (openedChanged)="onSettingsDrawerOpenChanged($event)"
  [transparentOverlay]="true"
  class="activities-drawer"
  #activitiesDrawer
>
  <div class="flex flex-col w-full light:bg-cool-gray-100 overflow-auto">
    <div class="flex flex-row items-center px-6 h-20 min-h-20 border-b">
      <mat-icon class="mr-3 text-secondary" [svgIcon]="'message'"></mat-icon>
      <div class="text-lg font-semibold tracking-wide">Recent Activity</div>
    </div>

    <div class="flex flex-col">
      <div class="layout-content">
        <shared-activity [activities]="activities"></shared-activity>
      </div>
    </div>
  </div>
</treo-drawer>
