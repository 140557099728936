<ng-container *ngIf="customObjType === 'Company' || customObjType === 'Contact' || isCustomObjectLookup">
  <mat-form-field class="flex-auto gt-xs:pr-3 w-full contact-wrapper">
    <mat-label>{{ fieldDef.label }}</mat-label>
    <input
      style="display: none"
      #inputControl
      matInput
      [ngModel]="value | readableField: model:fieldDef:[]"
      (ngModelChange)="value = $event"
      [disabled]="isDisabled"
    />

    <button
      *ngIf="!!value && !isMultipleField && !isReadOnly"
      matSuffix
      mat-icon-button
      [matMenuTriggerFor]="menu"
      matTooltip="More actions"
      [matTooltipDisabled]="isDisabled"
      [disabled]="isDisabled"
    >
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button
        *ngIf="!!value && isFilterable()"
        mat-menu-item
        [routerLink]="['/properties']"
        [queryParams]="{ filter: filterQueryParams() }"
      >
        <mat-icon [svgIcon]="'search'"></mat-icon>
        <span>Filter</span>
      </button>
      <ng-container *ngIf="value && !isDisabled">
        <button mat-menu-item (click)="removeModelField($event)">
          <mat-icon>cancel</mat-icon>
          <span>Remove</span>
        </button>
      </ng-container>
    </mat-menu>

    <div class="flex flex-row items-center justify-between w-full" [matTooltip]="showTip">
      <span class="underline pointer" (click)="value && !isDisabled && goToRecord()" *ngIf="!isMultipleField">
        {{ value }}
      </span>

      <ng-container
        *ngIf="isMultipleField"
        [ngTemplateOutlet]="multipleRecordList"
        [ngTemplateOutletContext]="{ value: value }"
      ></ng-container>

      <ng-container *ngIf="canShowAssociationButton()">
        <button
          [class.always-visible]="isMultipleField"
          class="associate-btn mr-2"
          type="button"
          mat-icon-button
          (click)="openTypeRelatedModal()"
        >
          <mat-icon [svgIcon]="'add_box'"></mat-icon>
        </button>
      </ng-container>
    </div>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="customObjType === 'User'">
  <mat-form-field class="flex-auto gt-xs:pr-3 w-full" (click)="edit()" (focus)="edit()">
    <mat-label>{{ fieldDef.label }}</mat-label>
    <div [matTooltip]="showTip" class="w-full">
      <mat-select
        [multiple]="isMultipleField"
        (selectionChange)="onSelectionChange($event)"
        [ngModel]="multipleUserIds || value"
        (ngModelChange)="multipleUserIds || (value = $event)"
        (blur)="onBlur($event)"
        [disabled]="isDisabled"
      >
        <mat-option [value]="null">-- None --</mat-option>
        <mat-option *ngFor="let option of filteredOptions" [value]="option.id" [disabled]="!option?.active">{{
          option?.name || option?.full_name
        }}</mat-option>
      </mat-select>
    </div>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="customObjType === 'FileResource'">
  <mat-form-field class="flex-auto gt-xs:pr-3 w-full contact-wrapper">
    <mat-label>{{ fieldDef.label }}</mat-label>
    <input style="display: none" matInput />
    <div [matTooltip]="showTip" class="flex flex-row items-center justify-between w-full" *ngIf="!uploadingFile">
      <span class="file-resource-label" *ngIf="!value"> Upload a file... </span>
      <span class="underline truncate w-40 pointer" (click)="viewFile(fileResource)" *ngIf="!isMultipleField">
        {{ value | readableField: model:fieldDef:[] }}
      </span>

      <ng-container
        *ngIf="isMultipleField"
        [ngTemplateOutlet]="multipleRecordList"
        [ngTemplateOutletContext]="{ value: value }"
      ></ng-container>

      <ng-container *ngIf="!value || isMultipleField">
        <button
          [class.always-visible]="isMultipleField"
          class="add-file-btn mr-2"
          type="button"
          mat-icon-button
          (click)="addFileInput.click()"
        >
          <mat-icon [svgIcon]="'add_box'"></mat-icon>
          <input style="display: none" type="file" (change)="onFileChanged($event)" #addFileInput />
        </button>
      </ng-container>

      <ng-container *ngIf="!isMultipleField && !!value">
        <button matSuffix mat-icon-button [matMenuTriggerFor]="menu" matTooltip="More actions">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngIf="value && !isDisabled" (click)="downloadFile(fileResource)">
            <mat-icon [svgIcon]="'get_app'"></mat-icon>
            <span>Download</span>
          </button>

          <button mat-menu-item (click)="editFileInput.click()">
            <mat-icon>edit</mat-icon>
            <input style="display: none" type="file" (change)="onFileChanged($event)" #editFileInput />
            <span>Edit</span>
          </button>

          <button mat-menu-item (click)="removeModelField($event)" *ngIf="value && !isDisabled">
            <mat-icon>cancel</mat-icon>
            <span>Remove</span>
          </button>
        </mat-menu>
      </ng-container>
    </div>
    <mat-progress-bar *ngIf="uploadingFile" mode="indeterminate"></mat-progress-bar>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="hasNoRecordType()">
  <mat-form-field class="flex-auto gt-xs:pr-3 w-full contact-wrapper">
    <mat-label>{{ fieldDef.label }}</mat-label>
    <input style="display: none" #inputControl matInput [ngModel]="value" (ngModelChange)="value = $event" />
    <div [matTooltip]="showTip" class="flex flex-row items-center w-full" *ngIf="value">
      <task-status [task]="value" [taskStatuses]="workflowStates" (onUpdate)="updateTask(value)"></task-status>
      <a (click)="goToRecord()">
        <div class="flex flex-col">
          <span class="font-semibold leading-none">{{ value.title }}</span>
          <span class="text-sm text-secondary leading-none mt-1">
            <span *ngIf="value.due_date">{{ value.due_date | date: 'LLL dd, y' }}</span>
            <span *ngIf="value.assigned_user_name">• {{ value.assigned_user_name }}</span>
          </span>
        </div>
      </a>
    </div>
  </mat-form-field>
</ng-container>

<!-- RECORD MULTIPLE INPUT -->
<ng-template let-value="value" #multipleRecordList>
  <mat-chip-list #chipList class="my-3">
    <mat-chip
      *ngFor="let item of value"
      [removable]="!isReadOnly"
      (removed)="removeItem(item)"
      (click)="goToRecord(item)"
    >
      {{ item.split('|')[1] }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
</ng-template>
