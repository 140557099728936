import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

export interface TsPaginatorChangeEvent {
  activePage: number;
  pageSize: number;
  totalPages: number;
}

@Component({
  selector: 'ts-paginator',
  templateUrl: './ts-paginator.component.html',
  styleUrls: ['./ts-paginator.component.scss'],
})
export class TsPaginatorComponent extends OnDestroyMixin implements OnInit {
  #totalPages = 1;
  #totalItems = 1;
  #activePage = 1;
  readonly defaultPageSize = 20;
  readonly maxRowButtons = 7;
  readonly pageSizeControl: FormControl<number> = new FormControl(this.defaultPageSize);

  rowButtons: (number | '...')[] = [];
  pageSizeOptions = [20, 50, 100];

  @Input() set pageSize(value: number) {
    this.pageSizeControl.setValue(value || this.defaultPageSize, { emitEvent: false });
    this.onPageSizeChanged(value);
  }

  @Input() set totalItems(value: number) {
    this.#totalItems = value;
    this.#totalPages = this.calculateTotalPages(value, this.pageSize);
    this.rowButtons = this.generatePageButtonsRow(this.totalPages, this.activePage);
  }

  @Input() set activePage(value: number) {
    this.#activePage = value;
    this.rowButtons = this.generatePageButtonsRow(this.totalPages, this.activePage);
  }

  @Output() pageChanged = new EventEmitter<TsPaginatorChangeEvent>();

  get totalPages(): number {
    return this.#totalPages;
  }

  get activePage(): number {
    return this.#activePage;
  }

  get pageSize(): number {
    return this.pageSizeControl.value || 20;
  }

  ngOnInit(): void {
    this.rowButtons = this.generatePageButtonsRow(this.totalPages, this.activePage);

    this.pageSizeControl.valueChanges.pipe(untilComponentDestroyed(this)).subscribe((value) => {
      const formValue = Number(value);

      this.onPageSizeChanged(formValue);

      this.pageChanged.emit({
        activePage: this.activePage,
        pageSize: formValue,
        totalPages: this.totalPages,
      });
    });
  }

  changeActivePage(page: number): void {
    if (page < 1 || page > this.totalPages) return;

    this.activePage = page;
    this.rowButtons = this.generatePageButtonsRow(this.totalPages, this.activePage);
    this.pageChanged.emit({
      activePage: this.activePage,
      pageSize: this.pageSizeControl.value,
      totalPages: this.totalPages,
    });
  }

  private updateActivePageOnPageSizeChange(): number {
    return this.activePage > this.totalPages ? this.totalPages : this.activePage;
  }

  private calculateTotalPages(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }

  private onPageSizeChanged(newPageSize: number): void {
    this.#totalPages = this.calculateTotalPages(this.#totalItems, newPageSize);
    this.activePage = this.updateActivePageOnPageSizeChange();
    this.rowButtons = this.generatePageButtonsRow(this.totalPages, this.activePage);
  }

  private generatePageButtonsRow(pagesCount: number, activePage: number): (number | '...')[] {
    if (pagesCount <= 1) return [1];

    if (pagesCount <= this.maxRowButtons) {
      return Array(pagesCount)
        .fill(null)
        .map((_, i) => i + 1);
    }

    if (activePage <= 4) {
      return [1, 2, 3, 4, 5, '...', pagesCount];
    }

    if (activePage >= pagesCount - 3) {
      return [1, '...', pagesCount - 4, pagesCount - 3, pagesCount - 2, pagesCount - 1, pagesCount];
    }

    return [1, '...', activePage - 1, activePage, activePage + 1, '...', pagesCount];
  }
}
