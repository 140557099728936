import { NgModule, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsOverlayComponent } from './ts-overlay.component';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  declarations: [TsOverlayComponent],
  imports: [CommonModule, OverlayModule],
  exports: [TsOverlayComponent, OverlayModule],
})
export class TsOverlayModule {}
