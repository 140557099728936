import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Overlay } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {
  MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY as MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
  MatLegacyAutocompleteModule as MatAutocompleteModule,
} from '@angular/material/legacy-autocomplete';

import { TreoFindByKeyPipeModule } from 'tsui/@treo';
import { IConfig, provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { ModalsModule } from 'app/shared/modals/modals.module';
import { DirectivesModule } from 'app/shared/directives/directives.module';
import { WidgetsModule } from 'app/shared/components/widgets/widgets.module';
import { EditableComponent } from './editable.component';
import { EditableDirective } from './editable.directive';
import { UrlEditableComponent } from './url-editable/url-editable.component';
import { DateEditableComponent } from './date-editable/date-editable.component';
import { TextEditableComponent } from './text-editable/text-editable.component';
import { TagsEditableComponent } from './tags-editable/tags-editable.component';
import { YearEditableComponent } from './year-editable/year-editable.component';
import { LinkableRelatedToFieldCell } from './data-table-linked-field.component';
import { MoneyEditableComponent } from './money-editable/money-editable.component';
import { ImagesEditableComponent } from './images-editable/images-editable.component';
import { NumberEditableComponent } from './number-editable/number-editable.component';
import { RecordEditableComponent } from './record-editable/record-editable.component';
import { SelectEditableComponent } from './select-editable/select-editable.component';
import { DecimalEditableComponent } from './decimal-editable/decimal-editable.component';
import { PercentEditableComponent } from './percent-editable/percent-editable.component';
import { CheckboxEditableComponent } from './checkbox-editable/checkbox-editable.component';
import { DateTimeEditableComponent } from './datetime-editable/datetime-editable.component';
import { TextAreaEditableComponent } from './textarea-editable/textarea-editable.component';
import { TypeaheadEditableComponent } from './typeahead-editable/typeahead-editable.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    EditableComponent,
    TextEditableComponent,
    DateEditableComponent,
    DateTimeEditableComponent,
    DecimalEditableComponent,
    NumberEditableComponent,
    MoneyEditableComponent,
    ImagesEditableComponent,
    PercentEditableComponent,
    TagsEditableComponent,
    TextAreaEditableComponent,
    TypeaheadEditableComponent,
    SelectEditableComponent,
    YearEditableComponent,
    CheckboxEditableComponent,
    UrlEditableComponent,
    RecordEditableComponent,
    EditableDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TreoFindByKeyPipeModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatIconModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    CurrencyMaskModule,
    DirectivesModule,
    PipesModule,
    MatMenuModule,
    ModalsModule,
    WidgetsModule,
    MatTooltipModule,
    MatProgressBarModule,
    LinkableRelatedToFieldCell,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [EditableComponent],
  providers: [
    provideNgxMask(),
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => {
        return () => overlay.scrollStrategies.block();
      },
      deps: [Overlay],
    },
  ],
})
export class EditableModule {}
