import { TaskRoleCreate } from '../task-roles.types';

export namespace TaskRolesActions {
  export class Get {
    static readonly type = 'Get Task Roles';
    constructor(public accountId: string) {}
  }

  export class Create {
    static readonly type = 'Create Task Role';
    constructor(public taskRole: TaskRoleCreate) {}
  }

  export class Delete {
    static readonly type = 'Delete Task Role';
    constructor(public taskRoleId: number, public accountId: string) {}
  }
}
