<mat-accordion>
  <mat-expansion-panel expanded="false" togglePosition="before">
    <mat-expansion-panel-header>
      <mat-panel-title>Flood Map Legend </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="flood-map-legend">
      <div class="flood-map-legend__item flex items-center mb-2" *ngFor="let item of legendItems">
        <span class="flood-map-legend__icon" [style.background-image]="'url(' + item.descriptionImage + ')'"></span>
        <span class="flood-map-legend__description"> {{ item.descriptionText }}</span>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
