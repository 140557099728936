export const floodMapLegendItems = [
  {
    id: 'a-legend',
    descriptionImage: '/assets/images/map/fema-flood-legend/a.png',
    descriptionText: 'A: 1% Annual chance of Flooding, no BFE',
  },
  {
    id: 'ae-legend',
    descriptionImage: '/assets/images/map/fema-flood-legend/ae.png',
    descriptionText: 'AE: 1% Annual chance of Flooding, with BFE',
  },
  {
    id: 'ae-regulatory-legend',
    descriptionImage: '/assets/images/map/fema-flood-legend/ae-regulatory.png',
    descriptionText: 'AE: Regulatory Floodway',
  },
  {
    id: 'ah-legend',
    descriptionImage: '/assets/images/map/fema-flood-legend/ah.png',
    descriptionText: 'AH: 1% Annual chance of 1-3ft ponding, with BFE',
  },
  {
    id: 'ao-legend',
    descriptionImage: '/assets/images/map/fema-flood-legend/ao.png',
    descriptionText: 'AO: 1% Annual chance of 1-3ft Sheet flow Flooding, with Depth',
  },
  {
    id: 've-legend',
    descriptionImage: '/assets/images/map/fema-flood-legend/ve.png',
    descriptionText: 'VE: High Risk coastal Area',
  },
  {
    id: 'd-legend',
    descriptionImage: '/assets/images/map/fema-flood-legend/d.png',
    descriptionText: 'D: Possible But undetermined Hazard',
  },
  {
    id: 'x-legend',
    descriptionImage: '/assets/images/map/fema-flood-legend/x.png',
    descriptionText: 'X: 0.2 Annual Chance of Flooding',
  },
  {
    id: 'x-drainage-legend',
    descriptionImage: '/assets/images/map/fema-flood-legend/x-drainage.png',
    descriptionText: 'X: 1% Drainage Area < 1 Sq. Mi.',
  },
  {
    id: 'x-reduced-legend',
    descriptionImage: '/assets/images/map/fema-flood-legend/x-reduced.png',
    descriptionText: 'X: Reduced Flood Risk due to Levee',
  },
  {
    id: 'not-included-legend',
    descriptionImage: '/assets/images/map/fema-flood-legend/not-included.png',
    descriptionText: 'Area not included',
  },
];
