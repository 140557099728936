import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TaskRole, TaskRoleCreate, TaskRoleCreateResponse, TaskRoleGetResponse } from '../task-roles.types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaskRolesService {
  constructor(private readonly httpClient: HttpClient) {}

  getByAccountId(accountId: number): Observable<TaskRole[]> {
    return this.httpClient
      .get<TaskRoleGetResponse>(`accounts/${accountId}/task_roles`)
      .pipe(map((response: TaskRoleGetResponse) => response.task_roles || []));
  }

  delete(taskRoleId: number, accountId: number): Observable<void> {
    return this.httpClient.delete<void>(`accounts/${accountId}/task_roles/${taskRoleId}`);
  }

  create(taskRole: TaskRoleCreate): Observable<TaskRole> {
    const { accountId } = taskRole;
    return this.httpClient
      .post<TaskRoleCreateResponse>(`accounts/${accountId}/task_roles`, { task_role: taskRole })
      .pipe(map((response: TaskRoleCreateResponse) => response.task_role));
  }
}
