/* eslint-disable max-len */
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { RelatedToType } from 'app/shared/enums';
import { Activity } from 'app/core/models/account.types';

@Component({
  selector: 'shared-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedActivityComponent {
  @Input() activities: Activity[];
  @Input() relatedTo?: RelatedToType;

  public getActivityOutput(activity: Activity): string {
    const type = activity?.field_type || activity?.related_to_type;
    if (activity.summary) {
      return activity.summary;
    }

    const textHash = {
      pipeline: {
        create: `<strong>${activity.user_name}</strong> added <strong>${activity.pipeline_name}</strong>`,
        update: `<strong>${activity.user_name}</strong> updated <strong>${activity.field_label}</strong> to <strong>${activity.new_val}</strong>, it was <strong>${activity.old_val}</strong>`,
        destroy: `<strong>${activity.user_name}</strong> deleted <strong>${activity.pipeline_name}</strong>`,
      },
      custom_field: {
        create: `<strong>${activity.user_name}</strong> added <strong>${activity.new_val}</strong>`,
        update: `<strong>${activity.user_name}</strong> updated the field <strong>${activity.field_label}</strong> from <strong>${activity.related_to_name}</strong> to <strong>${activity.new_val}</strong>, it was <strong>${activity.old_val}</strong>`,
        destroy: `<strong>${activity.user_name}</strong> deleted <strong>${activity.related_to_name}</strong>`,
      },
      dashboard: {
        create: `<strong>${activity.user_name}</strong> added a new dashboard <strong>${activity.related_to_name}</strong>`,
        update: `<strong>${activity.user_name}</strong> updated the <strong>${activity.field_label}</strong> from <strong>${activity.related_to_name}</strong>`,
        destroy: `<strong>${activity.user_name}</strong> deleted dashboard <strong>${activity.old_val}</strong>`,
      },
      facility_report: {
        create: `<strong>${activity.user_name}</strong> added a new facility report <strong>${activity.project_title}</strong>`,
        update: `<strong>${activity.user_name}</strong> updated <strong>${activity.field_label}</strong> to <strong>${activity.new_val}</strong>, it was <strong>${activity.old_val}</strong>`,
        destroy: `<strong>${activity.user_name}</strong> deleted facility report <strong>${activity.project_title}</strong>`,
      },
      project: {
        create: `<strong>${activity.user_name}</strong> added a new deal <strong>${activity.project_title}</strong>${
          activity.pipeline_name ? ` to <strong>${activity.pipeline_name}</strong>` : ''
        }`,
        update: `<strong>${activity.user_name}</strong> updated the field<strong> ${activity.field_label}</strong> from <strong>${activity.pipeline_name}</strong> to <strong>${activity.new_val}</strong>, it was <strong>${activity.old_val}</strong>`,
        destroy: `<strong>${activity.user_name}</strong> deleted the deal <strong>${activity.project_title}</strong>${
          activity.pipeline_name ? ` from <strong>${activity.pipeline_name}</strong>` : ''
        }`,
      },
      property: {
        create: `<strong>${activity.user_name}</strong> added a new property <strong>${activity.related_to_name}</strong>`,
        update: `<strong>${activity.user_name}</strong> updated the field <strong>${activity.field_label}</strong> from <strong>${activity.related_to_name}</strong> to <strong>${activity.new_val}</strong>, it was <strong>${activity.old_val}</strong>`,
        destroy: `<strong>${activity.user_name}</strong> deleted property <strong>${activity.old_val}</strong>`,
      },
      property_tenant: {
        create: `<strong>${activity.user_name}</strong> added a new property tenant <strong>${activity.project_title}</strong>`,
        update: `<strong>${activity.user_name}</strong> updated <strong>${activity.field_label}</strong> to <strong>${activity.new_val}</strong>, it was <strong>${activity.old_val}</strong> on <strong>${activity.project_title}</strong>`,
        destroy: `<strong>${activity.user_name}</strong> deleted the property tenant <strong>${activity.related_to_name}</strong>`,
      },
      property_unit: {
        create: `<strong>${activity.user_name}</strong> added a new property unit <strong>${activity.project_title}</strong>`,
        update: `<strong>${activity.user_name}</strong> updated <strong>${activity.field_label}</strong> to <strong>${activity.new_val}</strong>, it was <strong>${activity.old_val}</strong> on <strong>${activity.project_title}</strong>`,
        destroy: `<strong>${activity.user_name}</strong> deleted the property unit <strong>${activity.related_to_name}</strong>`,
      },
      note: {
        create: `<strong>${activity.user_name}</strong> added the note <strong>${activity.new_val}</strong>`,
        update: `<strong>${activity.user_name}</strong> updated <strong>${activity.field_label}</strong> to <strong>${activity.new_val}</strong>, it was <strong>${activity.old_val}</strong>`,
        destroy: `<strong>${activity.user_name}</strong> deleted the note <strong>${activity.old_val}</strong>`,
      },
      comment: {
        create: `<strong>${activity.user_name}</strong> added a comment in <strong>${activity.project_title}</strong>`,
        update: `<strong>${activity.user_name}</strong> updated a comment in <strong>${activity.project_title}</strong> to <strong>${activity.new_val}</strong>, it was <strong>${activity.old_val}</strong>`,
        destroy: `<strong>${activity.user_name}</strong> deleted a comment in <strong>${activity.project_title}</strong>`,
      },
      company: {
        create: `<strong>${activity.user_name}</strong> added a new company <strong>${activity.related_to_name}</strong>`,
        update: `<strong>${activity.user_name}</strong> updated the field <strong>${activity.field_label}</strong> ${
          this.relatedTo === RelatedToType.Company ? '' : `from <strong>${activity.related_to_name}</strong>`
        } to <strong>${activity.new_val}</strong>, it was <strong>${activity.old_val}</strong>`,
        destroy: `<strong>${activity.user_name}</strong> deleted company <strong>${activity.old_val}</strong>`,
      },
      task: {
        create: `<strong>${activity.user_name}</strong> added new task <strong>${activity.related_to_name}</strong>`,
        update: `<strong>${activity.user_name}</strong> updated the field <strong>${activity.field_label}</strong> ${
          this.relatedTo === RelatedToType.Task ? '' : `from <strong>${activity.related_to_name}</strong>`
        } to <strong>${activity.new_val}</strong>, it was <strong>${activity.old_val}</strong>`,
        destroy: `<strong>${activity.user_name}</strong> deleted task <strong>${activity.old_val}</strong>`,
      },
      contact: {
        create: `<strong>${activity.user_name}</strong> added the contact <strong>${activity.related_to_name}</strong>`,
        update: `<strong>${activity.user_name}</strong> updated the field <strong>${activity.field_label}</strong> ${
          this.relatedTo === RelatedToType.Contact ? '' : `from <strong>${activity.related_to_name}</strong>`
        } to <strong>${activity.new_val}</strong>, it was <strong>${activity.old_val}</strong>`,
        destroy: `<strong>${activity.user_name}</strong> deleted the contact <strong>${activity.old_val}</strong>`,
      },
    };
    if (activity.field_type in textHash) {
      return textHash[type][activity.action];
    } else {
      return '';
    }
  }

  public getActivityTitle(activity: Activity): string {
    const type = activity?.field_type || activity?.related_to_type;
    const textHash = {
      pipeline: `View`,
      custom_field: `Custom Field`,
      dashboard: `Dashboard`,
      facility_report: `Facility Report`,
      project: `Deal`,
      property: `Property`,
      property_tenant: `Property Tenant`,
      property_unit: `Property Unit`,
      note: `Note`,
      comment: `Comment`,
      company: `${this.relatedTo === RelatedToType.Company ? '' : 'Company'}`,
      task: `${this.relatedTo === RelatedToType.Task ? '' : 'Task'}`,
      contact: `${this.relatedTo === RelatedToType.Contact ? '' : 'Contact'}`,
    };
    return textHash[type];
  }

  public showToolTip(activity: Activity): string {
    return activity?.source ? `Source: ${activity.source}` : null;
  }

  public toggleShowFullDescription(event): void {
    event.target.classList.toggle('description');
  }
}
