@if (!variant) {
<ng-container *ngIf="type === 'flat'">
  <button
    mat-flat-button
    [matMenuTriggerFor]="matMenuTriggerFor"
    TSDebounceClick
    (debounceClick)="onClick($event)"
    [debounceTime]="debounceTime"
    [color]="color"
    [disabled]="disabled || loading"
    class="ts-button icon-position-{{ iconPosition }}"
  >
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'raised'">
  <button
    mat-raised-button
    [matMenuTriggerFor]="matMenuTriggerFor"
    TSDebounceClick
    (debounceClick)="onClick($event)"
    [debounceTime]="debounceTime"
    [color]="color"
    [disabled]="disabled || loading"
    class="ts-button icon-position-{{ iconPosition }}"
  >
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'stroked'">
  <button
    mat-stroked-button
    [matMenuTriggerFor]="matMenuTriggerFor"
    TSDebounceClick
    (debounceClick)="onClick($event)"
    [debounceTime]="debounceTime"
    [color]="color"
    [disabled]="disabled || loading"
    class="ts-button icon-position-{{ iconPosition }} bg-white"
  >
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'icon'">
  <button
    mat-icon-button
    [matMenuTriggerFor]="matMenuTriggerFor"
    TSDebounceClick
    (debounceClick)="onClick($event)"
    [debounceTime]="debounceTime"
    [color]="color"
    [disabled]="disabled || loading"
    [matTooltip]="iconTooltip"
  >
    <ts-icon [icon]="icon" [color]="iconColor"></ts-icon>
  </button>
</ng-container>
} @else { @switch (variant) { @case ('secondary') {
<button
  TSDebounceClick
  mat-stroked-button
  class="bg-white ts-button icon-position-{{ iconPosition }}"
  color="accent"
  [debounceTime]="debounceTime"
  [matMenuTriggerFor]="matMenuTriggerFor"
  [ngClass]="{ 'bg-gray-200': disabled }"
  [disabled]="disabled || loading"
  (debounceClick)="onClick($event)"
>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>
} @case ('danger') {
<button
  TSDebounceClick
  mat-flat-button
  class="ts-button icon-position-{{ iconPosition }}"
  color="warn"
  [debounceTime]="debounceTime"
  [matMenuTriggerFor]="matMenuTriggerFor"
  [disabled]="disabled || loading"
  (debounceClick)="onClick($event)"
>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>
} @default {
<button
  TSDebounceClick
  mat-flat-button
  class="ts-button icon-position-{{ iconPosition }}"
  color="primary"
  [debounceTime]="debounceTime"
  [matMenuTriggerFor]="matMenuTriggerFor"
  [disabled]="disabled || loading"
  (debounceClick)="onClick($event)"
>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>
} } }

<ng-template #buttonContent>
  <div class="flex flex-row items-center justify-between">
    @if (loading) {
    <mat-spinner diameter="16" class="mr-2" [strokeWidth]="2" mode="indeterminate" [color]="color"></mat-spinner>
    <ng-container [ngTemplateOutlet]="buttonText"></ng-container>
    } @else {
    <ts-icon [icon]="icon" *ngIf="icon && iconPosition === 'prefix'" [color]="iconColor"></ts-icon>
    <ng-container [ngTemplateOutlet]="buttonText"></ng-container>
    <ts-icon [icon]="icon" *ngIf="icon && iconPosition === 'suffix'" [color]="iconColor"></ts-icon>
    }
  </div>
</ng-template>

<ng-template #buttonText>
  <ng-content></ng-content>
</ng-template>
