import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';

import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class InvitationGuard {
  constructor(private readonly authService: AuthService, private readonly router: Router) {}

  checkInvitationToken(invite_token: string): Observable<boolean> {
    return this.authService.isInvitationValid(invite_token).pipe(
      switchMap((isValid) => {
        if (!isValid) {
          this.router.navigate(['sign-in'], { queryParams: { invalidInviteToken: true } });
          return of(false);
        }
        return of(isValid);
      }),
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.queryParams?.invite_token) {
      return this.checkInvitationToken(route.queryParams.invite_token);
    } else {
      return of(true);
    }
  }
}
