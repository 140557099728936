import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ts-color-bubble',
  template: `
    <div
      [ngClass]="bubbleSize"
      [ngStyle]="{ backgroundColor: hexColor ?? '#FFFFFF' }"
      class="rounded-full border border-black-200"
    ></div>
  `,
  standalone: true,
  imports: [CommonModule],
})
export class TsColorBubbleComponent {
  @Input() hexColor: string;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';

  get bubbleSize() {
    switch (this.size) {
      case 'sm':
        return 'w-4 h-4';
      case 'md':
        return 'w-6 h-6';
      case 'lg':
        return 'w-8 h-8';
      default:
        return 'w-6 h-6';
    }
  }
}
