import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { guid } from 'app/shared/Utils/common.utils';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AccountService } from 'app/core';
import { environment } from '@env/environment';
import { User } from 'app/core/models/user.types';
import { UserService } from 'app/layout/common/user/user.service';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-floating-ethan',
  templateUrl: './floating-ethan.component.html',
  styleUrls: ['./floating-ethan.component.scss'],
})
export class FloatingEthanComponent extends OnDestroyMixin implements OnInit {
  comment: Comment = {} as Comment;
  comments: Comment[] = [];
  public messages: any[] = [];
  public isLoading: boolean = false;
  public ethanQuestions: { question?: string; answer?: string; rating?: number; id?: number }[] = [];
  public recentQuestion: any = null;
  public ethanForm: FormGroup;
  public reader: ReadableStreamDefaultReader<Uint8Array> | null = null;
  public streamData: string = '';
  public currentUser: User;
  private _userService: UserService;

  constructor(
    private router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly accountsService: AccountService,
    private readonly userService: UserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly matDialogRef: MatDialogRef<FloatingEthanComponent>,
  ) {
    super();
    this.ethanForm = this.formBuilder.group({
      question: [''],
    });
    this._userService = userService;
  }

  ngOnInit() {
    this.accountsService.ethanQuestions$.pipe(untilComponentDestroyed(this)).subscribe((ethanQuestions) => {
      this.ethanQuestions = ethanQuestions;
      this.changeDetectorRef.markForCheck();
    });

    this.accountsService.getEthanQuestions().pipe(untilComponentDestroyed(this)).subscribe();
    this._userService.user$.pipe(untilComponentDestroyed(this)).subscribe((user: User) => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  public submitQuestion(): void {
    let question = this.ethanForm.get('question').value;
    this.ethanForm.get('question').setValue('');
    this.isLoading = true;
    let ethanQuestion = {
      guid: guid(),
      question: question,
    };
    this.recentQuestion = ethanQuestion;
    this.accountsService
      .askEthan(ethanQuestion)
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  public voteEthanAnswer(ethanQuestion, rating: number): void {
    ethanQuestion.rating = rating;
    this.accountsService.updateEthanQuestion(ethanQuestion).pipe(untilComponentDestroyed(this)).subscribe();
  }

  public closeEthan(): void {
    this.matDialogRef.close();
  }

  public openFullScreen(): void {
    this.router.navigate(['/ethan']);
    this.matDialogRef.close();
  }

  public askQuestionToEthan(): void {
    this.isLoading = true;
    let question = this.ethanForm.get('question').value;
    let ethanQuestion = {
      guid: guid(),
      question: question,
    };
    this.accountsService
      .askEthan(ethanQuestion)
      .pipe(
        untilComponentDestroyed(this),
        switchMap((data) => {
          this.ethanQuestions.push({
            ...data,
          });
          this.ethanForm.get('question').setValue('');

          return this.accountsService.ethanPostStream(
            environment.chatAssistant.url,
            { input: question },
            this.currentUser,
          );
        }),
      )
      .subscribe(
        async (stream) => {
          this.reader = stream.getReader();
          const decoder = new TextDecoder();

          const readChunk = async () => {
            while (this.reader && this.isLoading) {
              try {
                const { done, value } = await this.reader.read();
                if (done) {
                  this.reader = null;
                  break;
                }
                const chunk = decoder.decode(value, { stream: true });
                this.streamData += chunk;
                this.ethanQuestions[this.ethanQuestions.length - 1].answer = this.streamData;
              } catch (error) {
                console.error('Stream error:', error);
                this.reader = null;
                this.isLoading = false;
                break;
              }
            }
            this.isLoading = false;
            this.accountsService
              .updateEthanAnswer(
                this.ethanQuestions[this.ethanQuestions.length - 1],
                this.ethanQuestions[this.ethanQuestions.length - 1].id,
              )
              .pipe(untilComponentDestroyed(this))
              .subscribe(() => {
                this.isLoading = false;
                this.streamData = '';
              });
          };

          readChunk();
        },
        (error) => {
          console.error('Error:', error);
        },
      );
  }

  public stopReading() {
    this.isLoading = false;
    if (this.reader) {
      this.reader.cancel();
      this.reader = null;
    }
  }
}
