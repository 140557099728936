import { GraphqlParams } from '../../../shared/models/graphql';

export namespace ContactActions {
  export class GetContacts {
    static readonly type = '[Contact] Get Contacts';

    constructor(public params: GraphqlParams) {}
  }

  export class ExportContacts {
    static readonly type = '[Contact] Export Contacts';

    constructor(public params: GraphqlParams, public savedViewId: string) {}
  }
}
