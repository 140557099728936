import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable, noop } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { TreoMediaWatcherService } from 'tsui/@treo';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { TreoNavigationService, TreoNavigationItem } from 'tsui/@treo';
import { guid } from 'app/shared/Utils/common.utils';
import { AccountService, AuthService } from 'app/core';
import { Account } from 'app/core/models/account.types';
import { UserService } from 'app/layout/common/user/user.service';
import { Project, Pipeline, Property } from 'app/modules/projects/projects.types';
import { Contact } from 'app/modules/contacts/contacts.types';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { FeatureFlag } from 'app/core/enums/feature-flag';
import { ObjectsManagerActions } from 'app/modules/settings/objects-manager/state/actions';
import { ObjectsManagerState } from 'app/modules/settings/objects-manager/state/state';
import { CustomObject } from 'app/shared/modules/custom-objects/custom-objects.types';
import { FloatingEthanComponent } from 'app/shared/components/organism/floating-ethan/floating-ethan.component';
import { Company } from 'app/modules/companies/companies.types';
import { Module, Permission, ReservedRole } from 'app/core/enums';
import { User } from 'app/core/models';
import { LayoutService } from 'app/layout/layout.service';

@Component({
  selector: 'modern-layout',
  templateUrl: './modern.component.html',
  styleUrls: ['./modern.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModernLayoutComponent extends OnDestroyMixin implements OnInit {
  data: Data = {};
  isScreenSmall: boolean;
  menuItems: TreoNavigationItem[] = [];
  chatIsOpen = false;
  chatInstance: MatDialogRef<any, any>;
  account: Account;
  pipelines: Pipeline[];
  projects: Project[];
  contacts: Contact[];
  companies: Company[];
  favoriteRecords: any[];
  customObjects: CustomObject[] = [];
  isTaskCollaborator: boolean;
  fromAccounts = false;
  newUi = false;

  @HostBinding('class.fixed-header') fixedHeader: boolean;
  @HostBinding('class.fixed-footer') fixedFooter: boolean;
  @ViewChild('mainNavigation') mainNavigation: ElementRef;
  @Select(ObjectsManagerState.getForMenu) customObjects$: Observable<CustomObject[]>;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly treoMediaWatcherService: TreoMediaWatcherService,
    private readonly treoNavigationService: TreoNavigationService,
    private readonly accountService: AccountService,
    private readonly userService: UserService,
    private readonly dashboardService: DashboardService,
    private readonly projectsService: ProjectsService,
    private readonly authService: AuthService,
    private readonly store: Store,
    private readonly matDialog: MatDialog,
    private readonly layoutService: LayoutService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
    this.fixedHeader = false;
    this.fixedFooter = false;

    this.accountService.currentAccount.pipe(untilComponentDestroyed(this)).subscribe((accountData) => {
      this.account = accountData;
      this.dashboardService.accountId = this.account.id;
    });
  }

  get canAccessEthan(): boolean {
    if (this.account) {
      return this.account.features?.includes(FeatureFlag.ETHAN);
    }
  }

  get currentYear(): number {
    return new Date().getFullYear();
  }

  ngOnInit(): void {
    this.fromAccounts = this.activatedRoute.snapshot.data.fromAccounts;
    this.newUi = this.layoutService.newUi;

    if (this.fromAccounts) {
      return;
    }

    this.data = this.activatedRoute.snapshot.data.initialData;
    this.isTaskCollaborator = (this.data.user as User).roles.some((role) => role.name === ReservedRole.Collaborator);
    this.initialMenuItems();

    this.activatedRoute.data.pipe(untilComponentDestroyed(this)).subscribe((data: Data) => {
      this.data = data.initialData;
    });

    if (this.account?.features?.includes(FeatureFlag.CUSTOM_OBJECTS)) {
      this.store.dispatch(new ObjectsManagerActions.GetForMenu());

      this.customObjects$.pipe(untilComponentDestroyed(this)).subscribe((customObjects) => {
        this.customObjects = customObjects;
        this.generateMenuLinks();
      });
    }

    this.userService.recentProjects$.pipe(untilComponentDestroyed(this)).subscribe((projects: Project[]) => {
      this.projects = projects;
      this.updateRecentProjects();
    });

    this.userService.recentContacts$.pipe(untilComponentDestroyed(this)).subscribe((contacts: Contact[]) => {
      if (contacts) {
        this.contacts = contacts;
        this.updateRecentContacts();
      }
    });

    this.userService.recentCompanies$.pipe(untilComponentDestroyed(this)).subscribe((companies: Company[]) => {
      if (companies) {
        this.companies = companies;
        this.updateRecentContacts();
      }
    });

    this.userService.recentProperties$.pipe(untilComponentDestroyed(this)).subscribe((properties: Contact[]) => {
      if (properties) {
        this.updateRecentProperties(properties);
      }
    });

    this.projectsService.pipelines$.pipe(untilComponentDestroyed(this)).subscribe((pipelines) => {
      if (pipelines) {
        this.pipelines = pipelines.filter((pipeline) => pipeline?.pipeline_type != 'dynamic');
        this.updateRecentProjects();
      }
    });

    this.userService.favoriteRecords$.pipe(untilComponentDestroyed(this)).subscribe((favoriteRecords: any[]) => {
      this.favoriteRecords = favoriteRecords;
      this.updateRecentProjects();
    });

    if (this.account.id) {
      this.userService.getRecentItems(this.account.id).pipe(untilComponentDestroyed(this)).subscribe(noop);
      this.userService.getFavoriteRecords(this.account.id).pipe(untilComponentDestroyed(this)).subscribe();
    }

    this.treoMediaWatcherService.onMediaChange$.pipe(untilComponentDestroyed(this)).subscribe(({ matchingAliases }) => {
      // Check if the breakpoint is 'lt-xlg'
      // 'lt-xlg is a custom breakpoint to prevent desktop topmenu from generating a horizontal scrollbar.
      this.isScreenSmall = matchingAliases.includes('lt-xlg');
    });
  }

  private generateMenuLinks(): void {
    const customObjects = this.customObjects || [];

    if (customObjects.length && !this.isTaskCollaborator) {
      const customObjectsIds = customObjects.map(({ id }) => `custom_object_${id}`);
      const customObjectsAsNavItems = customObjects.map(
        ({ id, label_plural }) =>
          ({
            id: `custom_object_${id}`,
            title: label_plural,
            link: `/objects/${id}`,
            type: 'basic',
            icon: 'heroicons_outline:qrcode',
            exactMatch: true,
            classes: 'custom-object-nav-item',
          } as TreoNavigationItem),
      );
      const newItems = [];
      const customObjectsGroup: TreoNavigationItem = {
        id: guid(),
        title: 'More',
        type: 'collapsable',
        icon: 'heroicons_outline:qrcode',
        iconClasses: 'text-white',
        meta: {
          showExpandIcon: true,
          isMoreItem: true,
        },
        children: customObjectsAsNavItems,
      };
      newItems.push(customObjectsGroup);
      this.menuItems = [
        ...this.menuItems.filter(({ id, meta }) => !customObjectsIds.includes(id) && !meta?.isMoreItem),
        ...newItems,
      ];
    }
  }

  private updateRecentProjects(): void {
    let children: TreoNavigationItem[] = [
      {
        id: 'deals.all',
        title: 'All Views',
        link: '/projects',
        icon: 'heroicons_outline:cash',
        type: 'basic',
        exactMatch: true,
      },
    ];

    const index = this.menuItems.findIndex((item) => item.id === 'projects');
    if (this.pipelines) {
      let pipelineMenu: TreoNavigationItem = {
        id: 'pipelines',
        title: 'Views',
        icon: 'heroicons_outline:view-list',
        type: 'collapsable',
        children: [],
      };

      this.pipelines.forEach((pipeline) => {
        let pipelineChild: TreoNavigationItem = {
          id: pipeline.id.toString(),
          title: pipeline.name,
          type: 'collapsable',
          icon: 'heroicons_outline:view-list',
          children: [
            {
              id: 'deals-' + pipeline.id.toString(),
              title: 'View Pipeline Deals',
              type: 'basic',
              icon: 'heroicons_outline:cash',
              link: `/projects/${pipeline.id}`,
              exactMatch: true,
            },
          ],
        };

        if (this.projects) {
          let pipelineDeals = this.projects.filter((proj) => proj.pipeline_id == pipeline.id);

          pipelineDeals.forEach((project) => {
            let child: TreoNavigationItem = {
              id: project.id.toString(),
              title: project.name,
              type: 'basic',
              icon: 'heroicons_outline:cash',
              link: `/projects/${project.pipeline_id}/${project.id}`,
              exactMatch: true,
            };
            pipelineChild.children.push(child);
          });
          pipelineMenu.children.push(pipelineChild);
        }
      });
      children.push(pipelineMenu);
    }

    if (this.projects) {
      let recentProjectsMenu: TreoNavigationItem = {
        id: 'projects',
        title: 'Recent Deals',
        icon: 'heroicons_outline:clock',
        type: 'collapsable',
        children: [],
      };
      this.projects.forEach((project: Project & { name: string; url: string }) => {
        let child: TreoNavigationItem = {
          id: project.id.toString(),
          title: project.name,
          type: 'basic',
          icon: 'heroicons_outline:clock',
          link: project.url.replace('https://', '').split('/').slice(1).join('/'),
          exactMatch: true,
        };
        recentProjectsMenu.children.push(child);
      });
      children.push(recentProjectsMenu);
    }

    if (this.favoriteRecords) {
      let favoriteProjects = this.favoriteRecords.filter((item) => item.related_to_type == 'Project');
      let favoriteProjectsMenu: TreoNavigationItem = {
        id: 'projects',
        title: 'Favorites',
        icon: 'heroicons_outline:star',
        type: 'collapsable',
        children: [],
      };
      favoriteProjects.forEach((fave) => {
        let project = fave.favorite_object;
        if (project) {
          let child: TreoNavigationItem = {
            id: project.id.toString(),
            title: project.title,
            type: 'basic',
            icon: 'heroicons_outline:star',
            link: `/projects/${project.pipeline_id}/${project.id}`,
            exactMatch: true,
          };
          favoriteProjectsMenu.children.push(child);
        }
      });
      children.push(favoriteProjectsMenu);
    }
    if (index > -1) {
      let menuItem = this.menuItems[index];
      menuItem.children = children;
      this.menuItems[index] = menuItem;
    }
  }

  private updateRecentContacts(): void {
    const index = this.menuItems.findIndex((item) => item.id === 'contacts');
    let children: TreoNavigationItem[] = [
      {
        id: 'companies.all',
        title: 'Companies',
        link: '/companies',
        icon: 'heroicons_outline:user-group',
        type: 'basic',
        exactMatch: true,
      },
    ];

    let recentCompanies: TreoNavigationItem = {
      id: 'companies.recent',
      title: 'Recent Companies',
      icon: 'heroicons_outline:clock',
      type: 'collapsable',
      children: [],
    };

    if (index > -1 && this.companies) {
      let menuItem = this.menuItems[index];
      if (menuItem) {
        this.companies.forEach((company: Company & { url: string }) => {
          let child: TreoNavigationItem = {
            id: company.id.toString(),
            title: `${company.name}`,
            type: 'basic',
            icon: 'heroicons_outline:user',
            link: company.url.replace('https://', '').split('/').slice(1).join('/'),
            exactMatch: true,
          };
          recentCompanies.children.push(child);
        });
        children.push(recentCompanies);
        menuItem.children = children;
        this.menuItems[index] = menuItem;
      }
    }

    children.push({
      id: 'contacts.all',
      title: 'Contacts',
      link: '/contacts',
      icon: 'heroicons_outline:users',
      type: 'basic',
      exactMatch: true,
    });

    let recentContacts: TreoNavigationItem = {
      id: 'contacts.recent',
      title: 'Recent Contacts',
      icon: 'heroicons_outline:clock',
      type: 'collapsable',
      children: [],
    };

    if (index > -1 && this.contacts) {
      let menuItem = this.menuItems[index];
      if (menuItem) {
        this.contacts.forEach((contact: Contact & { url: string }) => {
          let child: TreoNavigationItem = {
            id: contact.id.toString(),
            title: `${contact.name}`,
            type: 'basic',
            icon: 'heroicons_outline:user',
            link: contact.url.replace('https://', '').split('/').slice(1).join('/'),
            exactMatch: true,
          };
          recentContacts.children.push(child);
        });
        children.push(recentContacts);
        menuItem.children = children;
        this.menuItems[index] = menuItem;
      }
    }
  }

  private updateRecentCompanies(companies): void {}

  private updateRecentProperties(properties): void {
    const index = this.menuItems.findIndex((item) => item.id === 'properties');
    let children: TreoNavigationItem[] = [
      {
        id: 'properties.all',
        title: 'All Properties',
        link: '/properties',
        icon: 'heroicons_outline:office-building',
        type: 'basic',
        exactMatch: true,
      },
    ];

    if (index > -1 && properties) {
      let menuItem = this.menuItems[index];
      if (menuItem) {
        properties.forEach((property: Property & { url: string }) => {
          let child: TreoNavigationItem = {
            id: property.id.toString(),
            title: `${property?.name || property?.address_string || 'Untitled'}`,
            type: 'basic',
            icon: 'heroicons_outline:clock',
            link: property.url.replace('https://', '').split('/').slice(1).join('/'),
            exactMatch: true,
          };
          children.push(child);
        });
        menuItem.children = children;
        this.menuItems[index] = menuItem;
      }
    }
  }

  public toggleNavigation(key: string): void {
    const navigation = this.treoNavigationService.getComponent(key);

    if (navigation) {
      navigation.toggle();
    }
  }

  public openCloseChat(): void {
    if (!this.chatIsOpen) {
      this.chatInstance = this.matDialog.open(FloatingEthanComponent, {
        width: '400px',
        height: '550px',
        position: {
          bottom: '75px',
          left: '70px',
        },
        hasBackdrop: true,
        closeOnNavigation: true,
        panelClass: 'ethan-dialog',
      });
      this.chatIsOpen = true;
      this.chatInstance
        .afterClosed()
        .pipe(untilComponentDestroyed(this))
        .subscribe(() => {
          this.chatIsOpen = false;
          this.changeDetectorRef.markForCheck();
        });
    } else {
      this.chatInstance.close();
    }
  }

  private initialMenuItems(): void {
    this.menuItems = this.authService.isGuest()
      ? [
          {
            id: 'projects',
            title: 'Deals',
            icon: 'heroicons_outline:office-building',
            iconClasses: 'text-white',
            type: 'collapsable',
          },
        ]
      : this.isTaskCollaborator
      ? [
          {
            id: 'tasks',
            title: 'Tasks',
            link: '/tasks',
            icon: 'heroicons_outline:clipboard-list',
            iconClasses: 'text-white',
            type: 'collapsable',
            meta: {
              showExpandIcon: true,
            },
            children: [
              {
                id: 'tasks.global',
                title: 'Global',
                link: '/tasks/global',
                icon: 'heroicons_outline:clipboard-list',
                type: 'basic',
                exactMatch: false,
              },
            ],
          },
        ]
      : [
          {
            id: 'projects',
            title: 'Deals',
            link: '/projects',
            icon: 'heroicons_outline:cash',
            iconClasses: 'text-white',
            type: 'collapsable',
            meta: {
              showExpandIcon: true,
            },
            hidden: () => !this.authService.hasAnyPermissionInModule(Module.DEALS),
          },
          {
            id: 'contacts',
            title: 'CRM',
            link: '/contacts',
            icon: 'heroicons_outline:user-group',
            iconClasses: 'text-white',
            type: 'collapsable',
            meta: {
              showExpandIcon: true,
            },
            hidden: () => !this.authService.hasPermission(Permission.CONTACTS_ACCESS_ALL),
          },
          {
            id: 'tasks',
            title: 'Tasks',
            link: '/tasks',
            icon: 'heroicons_outline:clipboard-list',
            iconClasses: 'text-white',
            type: 'collapsable',
            meta: {
              showExpandIcon: true,
            },
            hidden: () =>
              !this.authService.hasAnyPermission(Permission.TASK_LIST_GLOBAL, Permission.TASK_LIST_AGGREGATE),
            children: [
              {
                id: 'tasks.global',
                title: 'Global',
                link: '/tasks/global',
                icon: 'heroicons_outline:clipboard-list',
                type: 'basic',
                exactMatch: false,
                hidden: () => !this.authService.hasPermission(Permission.TASK_LIST_GLOBAL),
              },
              {
                id: 'tasks.aggregated',
                title: 'Aggregated',
                link: '/tasks/aggregatedtasks',
                icon: 'heroicons_outline:clipboard-list',
                type: 'basic',
                exactMatch: true,
                hidden: () => !this.authService.hasPermission(Permission.TASK_LIST_AGGREGATE),
              },
            ],
          },
          {
            id: 'properties',
            title: 'Properties',
            link: '/properties',
            icon: 'heroicons_outline:office-building',
            iconClasses: 'text-white',
            type: 'collapsable',
            meta: {
              showExpandIcon: true,
            },
            exactMatch: false,
            hidden: () => !this.authService.hasAnyPermissionInModule(Module.PROPERTIES),
          },
          {
            id: 'research',
            title: 'Maps',
            link: '/research/currentLocation',
            icon: 'heroicons_outline:globe',
            iconClasses: 'text-white',
            type: 'basic',
            hidden: () => !this.authService.hasAnyPermissionInModule(Module.MAPS),
          },
          {
            id: 'reporting',
            title: 'Reports',
            link: '/reporting',
            icon: 'heroicons_outline:document-report',
            iconClasses: 'text-white',
            type: 'collapsable',
            meta: {
              showExpandIcon: true,
            },
            hidden: () => !this.authService.hasAnyPermissionInModule(Module.REPORTS),
            children: [
              {
                id: 'reports.dashboard',
                title: 'Dashboard',
                link: '/dashboard',
                icon: 'ts_create-dashboard',
                type: 'basic',
                hidden: () => !this.authService.hasAnyPermissionInModule(Module.DASHBOARDS),
              },
              {
                id: 'reports.builder',
                title: 'Report Builder',
                link: '/reporting/list',
                icon: 'ts_graph-bar',
                type: 'basic',
                exactMatch: true,
                hidden: () => !this.authService.hasPermission(Permission.REPORTS_REPORT_BUILDER),
              },
              {
                id: 'reports.pdf',
                title: 'PDF Reports',
                link: '/reports',
                icon: 'ts_file-info-alt',
                type: 'basic',
                exactMatch: false,
                hidden: () => !this.authService.hasPermission(Permission.REPORTS_PDF),
              },
            ],
          },
        ];
  }
}
