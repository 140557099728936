import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { CalculatorModalComponent } from 'app/layout/common/calculator/calculator-modal/calculator-modal.component';

@Component({
  selector: 'calculator',
  template: `
    <button mat-icon-button (click)="openCalculatorModal()">
      <mat-icon [svgIcon]="'functions'"></mat-icon>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculatorComponent extends OnDestroyMixin {
  constructor(private readonly matDialog: MatDialog) {
    super();
  }

  public openCalculatorModal(): void {
    this.matDialog.open(CalculatorModalComponent).afterClosed().pipe(untilComponentDestroyed(this)).subscribe();
  }
}
