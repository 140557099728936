import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { AccountService } from 'app/core';
import { Account } from 'app/core/models';
import { AccountState } from './account/state';

export abstract class StateBase {
  protected accountService = inject(AccountService);

  constructor(protected readonly store: Store) {}

  get account(): Account {
    return this.store.selectSnapshot(AccountState.getAccount) || this.accountService.getCurrentAccount();
  }

  get accountId(): number {
    return Number(this.account.id);
  }
}
