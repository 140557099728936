<ng-container *ngIf="activities?.length; else NoActivities">
  <ng-container *ngFor="let activity of activities">
    <div class="notification">
      <ng-container *ngIf="activity.pipeline_id && activity.project_id">
        <a [routerLink]="['/projects', activity.pipeline_id, activity.project_id]" [title]="showToolTip(activity)">
          <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
        </a>
      </ng-container>
      <ng-container *ngIf="!activity.pipeline_id || !activity.project_id">
        <a [title]="showToolTip(activity)">
          <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
        </a>
      </ng-container>
    </div>

    <ng-template #notificationContent>
      <!-- Image -->
      <img class="image" *ngIf="activity.avatar_url" [src]="activity.avatar_url" />

      <!-- Title, description & time -->
      <div class="notification-content">
        <div class="title" [innerHTML]="getActivityTitle(activity)"></div>
        <div
          (click)="toggleShowFullDescription($event)"
          class="description"
          *ngIf="activity.user_name"
          [innerHTML]="getActivityOutput(activity)"
        ></div>
        <div class="time">{{ activity.created_at | date: 'LLL dd, y h:mm a' }}</div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #NoActivities>
  <div class="empty">
    <mat-icon [svgIcon]="'notifications'"></mat-icon>
    <h4 class="leading-tight">No activities found</h4>
  </div>
</ng-template>
