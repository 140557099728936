import { MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';

export const DEFAULT_CONFIG: MatSnackBarConfig<any> = {
  direction: 'ltr',
  duration: 3000,
  horizontalPosition: 'center',
  verticalPosition: 'bottom',
};

export interface TsSnackbarData {
  message: string;
  actionText: string | null;
  type: panelClasses;
  closeButton: boolean;
  onReset?: () => void;
  onSave?: () => void;
}

export enum SnackbarType {
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Default = 'default',
}

export type panelClasses = 'info' | 'error' | 'success' | 'warning' | 'default';
