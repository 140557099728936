import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import GraphqlService from '@services/graphql.service';
import { GraphqlResultPageInfo } from 'app/shared/models/graphql';
import { StateBase } from 'app/state/state-base';
import { Properties } from './actions';
import { generateDownloadableFile } from 'app/shared/functions';
import { tap } from 'rxjs';
import { TsSnackbarService } from 'tsui';

export interface PropertiesStateModel {
  graphqlResults: {
    rows: any[];
    pageInfo?: GraphqlResultPageInfo;
    timeSeries?: {
      columns: string[];
      isGrouped?: boolean;
    };
  };
}

@State<PropertiesStateModel>({
  name: new StateToken<PropertiesStateModel>('propertiesState'),
  defaults: { graphqlResults: null },
})
@Injectable()
export class PropertiesState extends StateBase {
  constructor(
    protected readonly store: Store,
    private readonly graphqlService: GraphqlService,
    private readonly snackBarService: TsSnackbarService,
  ) {
    super(store);
  }

  @Selector()
  static graphqlResults(state: PropertiesStateModel) {
    return state.graphqlResults;
  }

  @Action(Properties.Graphql.Action)
  graphql(
    context: StateContext<PropertiesStateModel>,
    { graphqlParams }: { graphqlParams: Properties.Graphql.Params },
  ) {
    const filter = { ...graphqlParams.filter };

    return this.graphqlService
      .query(
        Number(this.account.id),
        {
          connection: graphqlParams.connection,
          fieldDefs: graphqlParams.fieldDefs || [],
          fields: [...new Set([...(graphqlParams.fields ?? []), 'name', 'id']).values()],
          filter,
          options: graphqlParams.options,
        },
        graphqlParams.exportOptions,
      )
      .pipe(
        tap((result: any) => {
          if (graphqlParams.exportOptions && result.message) {
            this.snackBarService.open(result.message);
          } else if (graphqlParams.exportOptions) {
            generateDownloadableFile(
              result as any,
              graphqlParams.exportOptions.format,
              graphqlParams.exportOptions.fileName,
            );
          } else {
            const { edges, pageInfo } = result;
            const state = context.getState();

            context.setState({
              ...state,
              graphqlResults: {
                rows: edges.map(({ node }) => node),
                pageInfo,
              },
            });
          }
        }),
      );
  }
}
