import { AccountService, AuthService } from './core';
import { Observable, of, switchMap, tap } from 'rxjs';
import { getSubdomain } from './shared/Utils/common.utils';
import { Permission } from './core/enums';
import { Store } from '@ngxs/store';
import { AccountActions } from './state/account/actions';
import { SetPermissions, SetUser, SetUsers } from './state/user/actions';
import { ObjectsManagerActions } from './modules/settings/objects-manager/state/actions';

export function appInitializer(
  authService: AuthService,
  accountService: AccountService,
  store: Store,
): () => Observable<any> {
  return () => {
    const subdomain = getSubdomain();

    if (!subdomain) {
      return of(null);
    }

    // SC-29360 - Clearing the old task filters from local storage
    localStorage.removeItem('projectTasksQueryFilters');
    localStorage.removeItem('propertyTasksQueryFilters');
    localStorage.removeItem('globalTaskQueryFilters');

    return authService.check().pipe(
      switchMap((isAuthenticated) => {
        if (isAuthenticated) {
          return accountService.getAccount(subdomain).pipe(
            switchMap((account) => {
              accountService.setAccount(account);
              store.dispatch(new AccountActions.SetAccount(account)).subscribe((value) => {
                store.dispatch(new ObjectsManagerActions.Get());
              });
              return accountService.getUserPermissionByAccountId(Number(account.id));
            }),
            switchMap((permissions) => {
              const permissionNames = permissions.map(({ name }) => name as Permission);
              store.dispatch(new SetPermissions(permissionNames));
              authService.setPermissions(permissionNames);
              return accountService.getUser();
            }),
            switchMap((user) => {
              store.dispatch(new SetUser(user));
              return accountService.getUsers();
            }),
            tap((users) => {
              store.dispatch(new SetUsers(users));
            }),
          );
        }

        return of(null);
      }),
    );
  };
}
