import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TSTreeMenuComponent } from './ts-tree-menu.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { TsBadgeModule } from '../ts-badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TSIconModule } from '../ts-icon';

@NgModule({
  imports: [CommonModule, MatTreeModule, MatIconModule, TsBadgeModule, MatButtonModule, MatTooltipModule, TSIconModule],
  declarations: [TSTreeMenuComponent],
  exports: [TSTreeMenuComponent],
  providers: [],
})
export class TSTreeMenuModule {}
