import { Dashboard } from '../dashboard.types';

export class GetDashboardById {
  static readonly type = '[Dashboard] Get Dashboard By Id';

  constructor(public dashboardId: number) {}
}

export class UpdateDashboard {
  static readonly type = '[Dashboard] Update Dashboard';

  constructor(public dashboard: Dashboard) {}
}

export class DuplicateDashboard {
  static readonly type = '[Dashboard] Duplicate Dashboard';

  constructor(public dashboardId: number) {}
}
