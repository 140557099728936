import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { floodMapLegendItems } from './flood-map-legend-items';

@Component({
  selector: 'app-flood-map-legend',
  standalone: true,
  imports: [CommonModule, MatExpansionModule],
  templateUrl: './flood-map-legend.component.html',
  styleUrls: ['./flood-map-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloodMapLegendComponent {
  legendItems = floodMapLegendItems;

  constructor() {}
}
