import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { RelatedToType } from 'app/shared/enums';
import { typeTitleMap } from 'app/shared/models/type-title-map';
import { SearchResultItem } from 'app/shared/models/global-search';

@Component({
  selector: 'app-result-item',
  templateUrl: './result-item.component.html',
  styleUrls: ['./result-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultItemComponent {
  @Input() item: SearchResultItem;
  @Input() query: string;
  @Output() itemClicked = new EventEmitter<MouseEvent>();

  get projectStageName(): string {
    return this.item.projectStage?.name;
  }

  constructor(private readonly router: Router) {}

  getLabel(relatedToType: RelatedToType): string {
    return typeTitleMap[relatedToType] ?? relatedToType;
  }

  onItemClick(event: MouseEvent) {
    if (event.ctrlKey || event.metaKey) {
      return;
    } else {
      event.preventDefault();
      this.router.navigateByUrl(this.item.link);
    }
  }
}
