<mat-form-field class="flex-auto gt-xs:pr-3 w-full" (click)="edit()" (focus)="edit()">
  <mat-label>
    <span
      class="block text-ellipsis overflow-hidden pointer-events-auto"
      [matTooltip]="fieldDef.label"
      tooltipIfEllipsis
      >{{ fieldDef.label }}</span
    >
  </mat-label>
  <div class="w-full">
    <input
      #inputControl
      matInput
      type="text"
      [ngModel]="value"
      (ngModelChange)="value = $event"
      (keyup.enter)="onBlur($event)"
      (blur)="onBlur($event)"
      [disabled]="isDisabled"
    />
  </div>
</mat-form-field>
