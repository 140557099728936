import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { QuillModule } from 'ngx-quill';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TreoCardModule } from 'tsui/@treo';
import moment from 'moment';
import { TreoAutogrowModule } from 'tsui/@treo';
import { TreoFindByKeyPipeModule } from 'tsui/@treo';
import { TreoMessageModule } from 'tsui/@treo';
import { Overlay } from '@angular/cdk/overlay';
import {
  MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY as MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
  MatLegacyAutocompleteModule as MatAutocompleteModule,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';

import { TagsModalComponent } from './tags-modal/tags-modal.component';
import { MassUpdateTagsDialog } from './mass-update-tags/mass-update-tags.component';
import { EditTabTitleModalComponent } from './edit-tab-title-modal/edit-tab-title-modal.component';
import { EditSelectModalComponent } from './edit-custom-field-select/edit-custom-field-select.component';
import { GenerateMergeDocumentComponent } from './generate-merge-document/generate-merge-document.component';
import { WidgetsModule } from '../components/widgets/widgets.module';
import { AlertBannerComponent } from '../components/widgets/alert-banner/alert-banner.component';
import { PanelConditionalLogicModal } from './panel-conditional-logic-modal/panel-conditional-logic-modal.component';

@NgModule({
  declarations: [
    TagsModalComponent,
    EditTabTitleModalComponent,
    EditSelectModalComponent,
    GenerateMergeDocumentComponent,
    MassUpdateTagsDialog,
    PanelConditionalLogicModal,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    TreoCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatMenuModule,
    MatMomentDateModule,
    MatProgressBarModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatTableModule,
    MatTooltipModule,
    MatSnackBarModule,
    QuillModule.forRoot(),
    TreoAutogrowModule,
    MatAutocompleteModule,
    MatChipsModule,
    TreoFindByKeyPipeModule,
    TreoMessageModule,
    WidgetsModule,
    AlertBannerComponent,
  ],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: moment.ISO_8601,
        },
        display: {
          dateInput: 'LL',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => {
        return () => overlay.scrollStrategies.block();
      },
      deps: [Overlay],
    },
  ],
})
export class ModalsModule {}
