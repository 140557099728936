import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'app/core/services/auth.service';
import { Module, Permission } from '../enums';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard {
  constructor(private readonly authService: AuthService, private readonly router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (state.url.startsWith('/unauthorized')) {
      return true;
    } else {
      const permission = route.data?.permission as Permission;

      if (permission) {
        const hasPermission = this.authService.hasPermission(permission);

        if (!hasPermission) {
          this.router.navigate(['unauthorized']);
        }

        return hasPermission;
      }

      const module = route.data?.module as Module;

      if (module) {
        const hasAny = this.authService.hasAnyPermissionInModule(module);

        if (!hasAny) {
          this.router.navigate(['unauthorized']);
        }

        return hasAny;
      }
    }

    return true;
  }
}
