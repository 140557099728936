import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TreoAutogrowModule } from 'tsui/@treo';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import {
  MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY as MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
  MatLegacyAutocompleteModule as MatAutocompleteModule,
} from '@angular/material/legacy-autocomplete';
import { Overlay } from '@angular/cdk/overlay';
import { MentionModule } from 'angular-mentions';
import { QuillModule } from 'ngx-quill';

import { CurrencyMaskModule } from 'ng2-currency-mask';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

import { TaskStatusComponent } from './task-status/task-status.component';
import { CommentViewComponent } from './comment/comment-view.component';
import { ContactSharingComponent } from './contact-sharing/contact-sharing.component';
import { MailboxMailListComponent } from './mailbox-mail-list/mailbox-mail-list.component';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { AddContactComponent } from './add-contact/add-contact.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SelectFieldComponent } from './select-field/select-field.component';
import { ListFilterComponent } from './list-filter/list-filter.component';
import { SortableDropdownEditorComponent } from './sortable-dropdown-editor/sortable-dropdown-editor.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MailboxMailViewComponent } from './mailbox-mail-list/details/details.component';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { SharedActivityComponent } from './activity/activity.component';
import { ListFilterItemsComponent } from './list-filter/list-filter-items/list-filter-items.component';
import { FieldHistoryComponent } from './field-history/field-history.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ConfirmDialogComponent } from './dialog/confirm/confirm.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { WarningComponent } from './dialog/warning/warning.component';
import { AddFieldComponent } from './add-field/add-field.component';
import { DirectivesModule } from 'app/shared/directives/directives.module';
import { TsButtonModule } from 'tsui';

@NgModule({
  declarations: [
    TaskStatusComponent,
    CommentViewComponent,
    ContactSharingComponent,
    MailboxMailListComponent,
    AddContactComponent,
    BreadcrumbComponent,
    SelectFieldComponent,
    ListFilterComponent,
    SortableDropdownEditorComponent,
    MailboxMailViewComponent,
    SharedActivityComponent,
    ListFilterItemsComponent,
    FieldHistoryComponent,
    ConfirmDialogComponent,
    DateTimePickerComponent,
    WarningComponent,
    AddFieldComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    RouterModule,
    QuillModule.forRoot(),
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatMomentDateModule,
    MatProgressBarModule,
    MatRadioModule,
    MatRippleModule,
    MentionModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    TreoAutogrowModule,
    MatTooltipModule,
    MatToolbarModule,
    DragDropModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableModule,
    PipesModule,
    CurrencyMaskModule,
    DigitOnlyModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    DirectivesModule,
    NgxMaskDirective,
    NgxMaskPipe,
    TsButtonModule,
  ],
  exports: [
    TaskStatusComponent,
    CommentViewComponent,
    ContactSharingComponent,
    MailboxMailListComponent,
    AddContactComponent,
    BreadcrumbComponent,
    SelectFieldComponent,
    ListFilterComponent,
    SortableDropdownEditorComponent,
    MailboxMailViewComponent,
    SharedActivityComponent,
    ListFilterItemsComponent,
    FieldHistoryComponent,
    ConfirmDialogComponent,
    DateTimePickerComponent,
    AddFieldComponent,
  ],
  providers: [
    provideNgxMask(),
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => {
        return () => overlay.scrollStrategies.block();
      },
      deps: [Overlay],
    },
  ],
})
export class WidgetsModule {}
