import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { filter } from 'rxjs/operators';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AccountService } from 'app/core';
import { Layout, TreoConfig } from 'app/layout/layout.types';
import { Account, Activity } from 'app/core/models/account.types';
import { CustomDrawerContract, LayoutService } from './layout.service';
import { TreoConfigService, TreoDrawerComponent } from 'tsui/@treo';
import { Observable, of } from 'rxjs';
import { ReleaseNotesService } from '@core/services/release-notes.service';
import { AccountState } from '@state/account/state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  config: TreoConfig;
  layout: Layout;
  account: Account;
  activities: Activity[];

  @ViewChild('customDrawer', { static: true }) customDrawer: TreoDrawerComponent;
  @ViewChild('childLoader', { static: true, read: ViewContainerRef }) dynamicChild: ViewContainerRef;

  constructor(
    @Inject(DOCUMENT) private _document: any,
    private _activatedRoute: ActivatedRoute,
    private _treoConfigService: TreoConfigService,
    private _accountService: AccountService,
    private _router: Router,
    private store: Store,
    private readonly layoutService: LayoutService,
    private readonly releasenotesService: ReleaseNotesService,
  ) {
    super();
    this.account = this.store.selectSnapshot(AccountState.getAccount);
    this.releasenotesService.loadReleaseNotesScript();
  }

  ngOnInit(): void {
    // Subscribe to config changes
    this._treoConfigService.config$.pipe(untilComponentDestroyed(this)).subscribe((config: TreoConfig) => {
      // Store the config
      this.config = config;

      // Update the selected theme class name on body
      const themeName = 'treo-theme-' + config.theme;
      this._document.body.classList.forEach((className) => {
        if (className.startsWith('treo-theme-') && className !== themeName) {
          this._document.body.classList.remove(className);
          this._document.body.classList.add(themeName);
          return;
        }
      });

      // Update the layout
      this._updateLayout();
    });

    this.layoutService.toggle$
      .pipe(untilComponentDestroyed(this))
      .subscribe(({ componentClass, options: { inputs, outputs } }: CustomDrawerContract) => {
        const component = this.dynamicChild.createComponent(componentClass);

        for (const inputKey in inputs) {
          component.instance[inputKey] = inputs[inputKey];
        }

        for (const outputKey in outputs) {
          component.instance[outputKey].pipe(untilComponentDestroyed(this)).subscribe(outputs[outputKey]);
        }

        this.customDrawer.toggle();
      });

    this.layoutService.close$.pipe(untilComponentDestroyed(this)).subscribe(() => this.customDrawer.close());
  }

  get showActivitiesPanel$(): Observable<boolean> {
    return this.layoutService.getCommon('showActivitiesPanel') ?? of(true);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onSettingsDrawerOpenChanged(event) {
    if (event) {
      this._getActivities();
    }
  }

  private _getActivities(): void {
    if (Object.keys(this.account).length) {
      this._accountService
        .getActivities({ account_id: this.account.id, per_page: 25 })
        .pipe(untilComponentDestroyed(this))
        .subscribe((activities) => {
          this.activities = activities;
        });
    }
  }

  private _updateLayout(): void {
    // Get the current activated route
    let route = this._activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    // 1. Set the layout from the config
    this.layout = this.config.layout;

    // 2. Get the query parameter from the current route and
    // set the layout and save the layout to the config
    const layoutFromQueryParam = route.snapshot.queryParamMap.get('layout') as Layout;
    if (layoutFromQueryParam) {
      this.config.layout = this.layout = layoutFromQueryParam;
    }

    // 3. Iterate through the paths and change the layout as we find
    // a config for it.
    //
    // The reason we do this is that there might be empty grouping
    // paths or componentless routes along the path. Because of that,
    // we cannot just assume that the layout configuration will be
    // in the last path's config or in the first path's config.
    //
    // So, we get all the paths that matched starting from root all
    // the way to the current activated route, walk through them one
    // by one and change the layout as we find the layout config. This
    // way, layout configuration can live anywhere within the path and
    // we won't miss it.
    //
    // Also, this will allow overriding the layout in any time so we
    // can have different layouts for different routes.
    const paths = route.pathFromRoot;
    paths.forEach((path) => {
      // Check if there is a 'layout' data
      if (path.routeConfig && path.routeConfig.data && path.routeConfig.data.layout) {
        // Set the layout
        this.layout = path.routeConfig.data.layout;
      }
    });
  }

  setLayout(layout: Layout): void {
    // Clear the 'layout' query param to allow layout changes
    this._router
      .navigate([], {
        queryParams: {
          layout: null,
        },
        queryParamsHandling: 'merge',
      })
      .then(() => {
        // Set the config
        this._treoConfigService.config = { ...this._treoConfigService.config, layout };
      });
  }

  setTheme(change: MatSlideToggleChange): void {
    this._treoConfigService.config = { ...this._treoConfigService.config, theme: change.checked ? 'dark' : 'light' };
  }

  customDrawerOpenedChanged(event: boolean): void {
    if (event === false) {
      this.dynamicChild.clear();
      this.layoutService.closeComponentDrawer();
    }
  }
}
