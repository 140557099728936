import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormFieldComponent } from '../form-field.types';
import moment from 'moment';
import { Activity } from 'app/core/models';
import { Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'datetime-form-field',
  templateUrl: './datetime-form-field.component.html',
  styleUrls: ['./datetime-form-field.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'L',
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'L',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ],
})
export class DateTimeFormFieldComponent extends FormFieldComponent implements OnInit {
  @ViewChild('inputControl') inputControl: ElementRef;

  public dateDisplayFormat = 'MM/DD/YYYY';

  private _originalValue;
  public editing: boolean = false;
  public showHistory: boolean = false;
  public activities: Activity[];
  public date = new UntypedFormControl(null);

  get modelFieldValue(): string {
    return this.model[this.fieldDef.name];
  }

  get modelFieldMomentValue(): Moment {
    return moment(this.modelFieldValue || '').utc(true);
  }

  get modelFieldFormattedValue(): string {
    return this.modelFieldMomentValue.format(this.dateDisplayFormat);
  }

  get dateFieldFormattedValue(): string {
    return this.date.value?.format(this.dateDisplayFormat);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this._originalValue = this.modelFieldFormattedValue;

    if (this.fieldDef.meta?.readonly) {
      this.readonly = this.fieldDef.meta.readonly;
    }

    this.date.setValue(this.modelFieldMomentValue);
    this.model[this.fieldDef.name] = this.date.value;
  }

  onDateChange(): void {
    if (this._originalValue !== this.dateFieldFormattedValue) {
      const dbFormattedDate = this.date.value?.format('YYYY-MM-DD');
      if (dbFormattedDate) this.save(dbFormattedDate);
      else this.date.setErrors({ incorrect: true });
    }
  }

  dateBlur(event: FocusEvent & { target: HTMLInputElement }): void {
    if (event.target.value === '') {
      this.date.setValue(null);
      if (this._originalValue) this.save(this.date.value);
    }
  }

  save(date) {
    this.model[this.fieldDef.name] = date;
    this._originalValue = this.dateFieldFormattedValue;
    this.update.emit({ [this.fieldDef.name]: date });
  }

  updateChangedModel?(model: any) {
    this.model = model;
    this.date.setValue(this.modelFieldMomentValue);
  }
}
