import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AccountService } from '../services/account.service';
import { UserService } from 'app/layout/common/user/user.service';
import { User, UserAssociation } from 'app/core/models/user.types';
import { InformativeSignoutCodes } from 'app/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  private _userAssociations: UserAssociation[];

  constructor(
    private router: Router,
    private authService: AuthService,
    private _accountService: AccountService,
    private _userService: UserService,
  ) {
    this._accountService.userAssociations$.subscribe((userAssociations: UserAssociation[]) => {
      if (userAssociations === undefined) {
        this.router.navigate(['/sign-out'], {
          queryParams: { messageCode: InformativeSignoutCodes.NotAssociated },
        });
      } else if (userAssociations) {
        this._userAssociations = userAssociations;
        this._setRoleOnUser();
      }
    });
  }

  _setRoleOnUser(): void {
    this._userService.user$.subscribe((user: User) => {
      if (user) {
        if (user?.email?.includes('termsheet.com')) {
          this._userService.role = { name: 'admin' };
        } else {
          let foundUserAssociations = this._userAssociations.filter((ua) => {
            return ua.user.id == user.id;
          });
          if (foundUserAssociations && foundUserAssociations.length > 0) {
            let ua = foundUserAssociations[0];
            this._userService.role = ua.role || { name: 'admin' };
          }
        }
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return of(true);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return of(true);
  }
}
