import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'highlighter' })
export class HighlighterPipe implements PipeTransform {
  transform(value: string, query: string, cssClass: string, type: 'full' | 'partial' = 'full'): string {
    if (!query) {
      return value;
    }

    query = this.escapeRegExp(query);

    cssClass = cssClass ? `class="${cssClass}"` : '';

    if (type === 'full') {
      const re = new RegExp('\\b(' + query + '\\b)', 'igm');
      value = value.replace(re, `<mark ${cssClass}>$1</mark>`);
    } else {
      const re = new RegExp(query, 'igm');
      value = value.replace(re, `<mark ${cssClass}>$&</mark>`);
    }

    return value;
  }

  private escapeRegExp(string: string): string {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
  }
}
