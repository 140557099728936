<button
  mat-icon-button
  [matMenuTriggerFor]="supportMenu"
  [matTooltip]="'Click to expand'"
  data-intercom-target="Support Hub"
>
  <mat-icon [svgIcon]="'help_outline'"></mat-icon>
</button>

<mat-menu #supportMenu="matMenu" class="support-menu">
  <section class="support-menu__wrapper">
    <!-- System Health -->
    <article
      class="support-menu__item"
      data-intercom-target="Support Hub Menu - System Health"
      (click)="navigateToAddress(pageAddresses.systemStatus)"
    >
      <mat-icon class="material-icons-outlined icon-size-40">health_and_safety</mat-icon>
      <span class="text-center">System health</span>
    </article>

    <!-- Chat with us -->
    <article class="support-menu__item" (click)="openChatBot()" data-intercom-target="Support Hub Menu - Chat with us">
      <mat-icon class="material-icons-outlined icon-size-40">forum</mat-icon>
      <span class="text-center">Chat with us</span>
    </article>

    <!-- Support Site -->
    <article
      class="support-menu__item"
      (click)="navigateToAddress(pageAddresses.support)"
      data-intercom-target="Support Hub Menu - Support Site"
    >
      <mat-icon class="material-icons-outlined icon-size-40">import_contacts</mat-icon>
      <span class="text-center">Support Site</span>
    </article>

    <!-- Email us -->
    <a class="support-menu__item" [href]="emailUs()" data-intercom-target="Support Hub Menu - Email us">
      <mat-icon class="material-icons-outlined icon-size-40">mail</mat-icon>
      <span class="text-center">Email us</span>
    </a>

    <!-- Releases -->
    <article
      class="support-menu__item"
      (click)="navigateToAddress(pageAddresses.releases)"
      data-intercom-target="Support Hub Menu - Releases"
    >
      <mat-icon class="material-icons-outlined icon-size-40">campaign</mat-icon>
      <span class="text-center">Releases</span>
    </article>

    <!-- Training -->
    <article
      class="support-menu__item"
      (click)="navigateToAddress(pageAddresses.training)"
      data-intercom-target="Support Hub Menu - Training"
    >
      <mat-icon class="material-icons-outlined icon-size-40">videocam</mat-icon>
      <span class="text-center">Training</span>
    </article>
  </section>
</mat-menu>
