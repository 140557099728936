import { FieldDef } from 'app/core/models';

export namespace Field {
  export class Get {
    static readonly type = '[FieldDefs] Get FieldDefs';

    constructor() {}
  }

  export class Set {
    static readonly type = '[FieldDefs] Set FieldDefs';

    constructor(public fieldDefs: FieldDef[]) {}
  }

  export class SetDropdownOptions {
    static readonly type = '[FieldDefs] Set Dropdown Options';

    constructor(public options: { [option_name: string]: any[] }) {}
  }
}
