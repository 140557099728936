import { Pipe, PipeTransform } from '@angular/core';
import { Groupable } from '../../models';

@Pipe({ name: 'tsGroupBy' })
export class TsGroupByPipe implements PipeTransform {
  transform<T>(value: any[], flat = false): Groupable<any>[] {
    const data = value.reduce((p, c) => {
      const g = p.find((x) => x.key === c.meta.group.key);
      if (!g) {
        p.push({
          key: c.meta.group.key,
          label: c.meta.group.label,
          isGroup: true,
          items: value.filter((y) => y.meta.group.key === c.meta.group.key),
        });
      }
      return p;
    }, [] as Groupable<any>[]);

    if (flat) {
      return data.reduce((p, c) => {
        return [...p, { key: c.key, label: c.label, isGroup: true }, ...c.items];
      }, []);
    }

    return data;
  }
}
