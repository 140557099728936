import { getCurrencySymbol } from '@angular/common';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import moment from 'moment';

const isGuid = (guid: string): boolean => {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(guid);
};

const isString = (value: any): boolean => typeof value === 'string';

const EMAIL_PATTERN =
  // eslint-disable-next-line no-useless-escape, max-len
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const getSubdomain = () => {
  const domain = window.location.hostname;
  let subdomain = '';
  if (
    domain.indexOf('.') < 0 ||
    domain.split('.')[0] === 'portal' ||
    domain.split('.')[0] === 'dev' ||
    domain.split('.')[0] === 'dashboard'
  ) {
    subdomain = '';
  } else {
    subdomain = domain.split('.')[0];
  }
  return subdomain;
};

const isArraysEqual = <T>(a: T[], b: T[]): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
};

const isObjectEqual = <T>(a: T[] | T, b: T[] | T): boolean => {
  if (Array.isArray(a) && Array.isArray(b)) {
    return isArraysEqual(a, b);
  }
  return a === b;
};

const keepProps = <T>(target: T, props: Array<keyof T>): Partial<T> => {
  return Object.keys(target)
    .filter((propName) => props.includes(propName as keyof T))
    .reduce((prev: Partial<T>, cur: string) => {
      return (prev[cur] = { ...prev, [cur]: target[cur] });
    }, {});
};

const isEmptyOrWhitespace = (value: string): boolean => value?.match(/^ *$/) !== null;

const camelToSnakeCase = (value: string) => value.replace(/[A-Z]/g, (l) => `_${l.toLowerCase()}`);

const getCurrencyMask = (displayCurrencyCode: string, precision: number, align = 'left'): CurrencyMaskOptions => ({
  align,
  precision,
  prefix: getCurrencySymbol(displayCurrencyCode, 'wide') || '$',
});

export type CurrencyMaskOptions = { align: string; precision: number; prefix: string };

const between = (obj1: any, obj2: any): string[] => {
  if (!obj1 || !obj2) {
    return [];
  }

  return Object.keys(obj1).reduce((prev, cur) => (obj1[cur] != obj2[cur] ? [...prev, cur] : prev), []);
};

const capitalizeFirstLetter = (value: string) => {
  if (!value) {
    return value;
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
};

const FJSON = (obj: any[]): string => {
  return `[${obj
    .map(({ operator, value, name }) => {
      const parsedValue = JSON.stringify(value);
      return `["${name}","${operator}",${parsedValue}]`;
    })
    .join(',')}]`;
};

const relatedToTypeFromLabel = (type: string): string => {
  switch (type) {
    case 'Deal':
      return 'Project';
    case 'Lease':
      return 'PropertyTenant';
    case 'Unit':
      return 'PropertyUnit';
    default:
      return type;
  }
};

function formatExtensionValidator(acceptedFileExtensions: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const extension = value.name.split('.').pop();
    if (acceptedFileExtensions.includes(extension)) {
      return null;
    }

    return { invalidExtension: true };
  };
}

const getDateRange = (startDate: string, endDate: string, month = false): string[] => {
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const result = [moment({ ...startDateMoment }).format('YYYY-MM-DD')];
  const monthResult = new Set<string>([moment({ ...startDateMoment }).format('YYYY-MM')]);

  while (startDateMoment.format('YYYY-MM-DD') !== endDateMoment.format('YYYY-MM-DD')) {
    startDateMoment.add(1, 'day');
    result.push(moment({ ...startDateMoment }).format('YYYY-MM-DD'));
    monthResult.add(moment({ ...startDateMoment }).format('YYYY-MM'));
  }

  return month ? [...monthResult] : result;
};

const guid = () => {
  let d = new Date().getTime();

  // Use high-precision timer if available
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now();
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export {
  guid,
  isGuid,
  isString,
  getSubdomain,
  isArraysEqual,
  isObjectEqual,
  keepProps,
  isEmptyOrWhitespace,
  camelToSnakeCase,
  getCurrencyMask,
  between,
  capitalizeFirstLetter,
  FJSON,
  EMAIL_PATTERN,
  relatedToTypeFromLabel,
  formatExtensionValidator,
  getDateRange,
};
