/* eslint-disable no-case-declarations */
import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { FieldDef } from 'app/core/models';
import { Project, Property } from 'app/modules/projects/projects.types';
import { FormatNumberPipe } from './format-number.pipe';
import { TsCurrencyPipe } from './ts-currency.pipe';
import { AccountService } from 'app/core';
import { Dictionary } from '../models';
import moment from 'moment';
import get from 'lodash-es/get';

type ModelType = Project | Property | Dictionary<any>;

@Pipe({
  name: 'readableField',
  pure: true,
})
export class ReadableFieldPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string, private readonly accountService: AccountService) {}

  transform(value: any, model: ModelType, fieldDef: FieldDef, optionsDictionary: { [key: string]: any }): string {
    switch (fieldDef?.type) {
      case 'number':
        return new FormatNumberPipe().transform(value, fieldDef.meta?.scale || 0, fieldDef);
      case 'percent':
        if (value) {
          return `${new FormatNumberPipe().transform(value, fieldDef.meta?.scale || 0)}%`;
        }

        return '';
      case 'decimal':
        return new FormatNumberPipe().transform(value, fieldDef.meta?.scale || 0);
      case 'money':
        // eslint-disable-next-line no-case-declarations
        const precision = fieldDef.meta?.scale || 0;
        return new TsCurrencyPipe(this.accountService).transform(
          value,
          'USD',
          'symbol',
          '1.' + precision + '-' + precision,
        );
      case 'date':
        return value ? moment(value).format('L') : null;
      case 'datetime':
        return value ? moment(value).utc().format('L') : null;
      case 'select':
        if (fieldDef?.meta?.multi_select) {
          let fieldValue = fieldDef?.name?.split('.')?.length > 1 ? get(model, fieldDef.name) : model[fieldDef.name];
          // Use the passed value if it's not undefined, otherwise use the field value from the model
          let finalValue = value !== undefined ? value : fieldValue;

          finalValue = this.convertIfItIsValidJson(finalValue);
          return Array.isArray(finalValue) ? finalValue.join(', ') : finalValue;
        }

        // eslint-disable-next-line no-case-declarations
        let options = [];

        if (fieldDef?.meta?.options?.length > 0) {
          options = fieldDef.meta.options.map((value) => ({ id: value, name: value }));
        } else {
          options = optionsDictionary[fieldDef.meta.option_name].filter((option) => !option?.hidden);
        }

        //[sc-12306]: re-enabled and uncommented code
        options = options.filter((option) => {
          if (option.hidden) {
            return false;
          } else {
            if (option?.pipeline_ids) {
              if (model.pipeline_id) {
                return option?.pipeline_ids.includes(Number(model.pipeline_id));
              }
            }
          }
          return true;
        });

        // eslint-disable-next-line no-case-declarations
        const selectedOption = options.find(({ id }) => id == value);
        return selectedOption?.name || selectedOption?.full_name;
      case 'checkbox':
        if (String(value) === 'true') {
          return 'Yes';
        } else if (String(value) === 'false') {
          return 'No';
        } else {
          return 'Not Set';
        }
      case 'record':
        value = this.convertIfItIsValidJson(value);
        if (fieldDef.name.startsWith('cf_linked_') || value?.includes('|') || value instanceof Array) {
          return value instanceof Array
            ? value
                .map((val) => {
                  if (typeof val === 'string' && val.includes('|')) {
                    return val?.split('|')[1];
                  }

                  return val;
                })
                .join(', ')
            : value?.split('|')[1];
        }

        return value;
      case 'id':
        return value?.label;
      case 'url':
      case 'typeahead':
      case 'tags':
      case 'year':
      case 'textarea':
      case 'text':
      default:
        return value;
    }
  }

  private convertIfItIsValidJson(value: string): any {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }
}
