import {
  Component,
  Input,
  OnInit,
  forwardRef,
  ChangeDetectorRef,
  ViewContainerRef,
  ViewChild,
  TemplateRef,
  OnDestroy,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnChanges,
  ElementRef,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import moment from 'moment';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Task, TaskStatus } from 'app/shared/modules/tasks/tasks.types';

@Component({
  selector: 'task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskStatusComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TaskStatusComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  @Input() taskStatuses: TaskStatus[];
  @Input() task: Task;
  @Input() condensedView: boolean = false;
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();

  private _value: any;

  onChange = (val) => {};
  onTouched = () => {};

  private _priorityPanelOverlayRef: OverlayRef;
  private _priorityPanelTemplatePortal: TemplatePortal;

  @ViewChild('priorityPanelOrigin')
  private _priorityPanelOrigin: ElementRef;

  @ViewChild('priorityPanel')
  private _priorityPanel: TemplateRef<any>;

  /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _overlay: Overlay,
    private _changeDetectorRef: ChangeDetectorRef,
    private _viewContainerRef: ViewContainerRef,
  ) {}

  /**
   * Get calendar by id
   *
   * @param id
   */
  getTaskStatus(status): TaskStatus {
    if (!status) {
      return;
    }

    return this.taskStatuses.find((taskStatus) => taskStatus.key === status);
  }

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  // Allows Angular to update the model (rating).
  // Update the model and changes needed for the view here.
  writeValue(value: any): void {
    this._value = value;
    this.onChange(value);
  }

  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  /**
   * Check if the task is overdue or not
   */
  isOverdue(): boolean {
    return moment(this.value, moment.ISO_8601).isBefore(moment(), 'days');
  }

  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {
    if (this._priorityPanelOverlayRef) {
      this._priorityPanelOverlayRef.dispose();
    }
  }

  userInitials(name): string {
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  onSelectUser(user): void {
    this.value = user.id;
    this.onUpdate.emit('');
    this.closePriorityPanel();
  }

  updateDueDate($event: moment.Moment): void {
    this.value = $event;
    this.onUpdate.emit($event);
    this.closePriorityPanel();
  }

  removeDueDate(): void {
    this.value = null;
    this.onUpdate.emit(null);
    this.closePriorityPanel();
  }

  onChangeStatus(): void {
    this.onUpdate.emit('');
    this.closePriorityPanel();
  }

  /**
   * Open due date panel
   */
  openPriorityPanel(event): void {
    const top = window.innerHeight - event.clientY < 230 ? 'bottom' : 'top';
    // Create the overlay
    this._priorityPanelOverlayRef = this._overlay.create({
      backdropClass: '',
      hasBackdrop: true,
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._priorityPanelOrigin.nativeElement)
        .withFlexibleDimensions()
        .withViewportMargin(100)
        .withLockedPosition()
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: top,
          },
        ]),
    });

    // Create a portal from the template
    this._priorityPanelTemplatePortal = new TemplatePortal(this._priorityPanel, this._viewContainerRef);

    // Attach the portal to the overlay
    this._priorityPanelOverlayRef.attach(this._priorityPanelTemplatePortal);

    // Subscribe to the backdrop click
    this._priorityPanelOverlayRef.backdropClick().subscribe(() => {
      // Close the panel
      this.closePriorityPanel();
    });

    event.stopPropagation();
  }

  /**
   * Close the due date panel
   */
  closePriorityPanel(): void {
    // If overlay exists and attached...
    if (this._priorityPanelOverlayRef && this._priorityPanelOverlayRef.hasAttached()) {
      // Detach it
      this._priorityPanelOverlayRef.detach();
    }

    // If template portal exists and attached...
    if (this._priorityPanelTemplatePortal && this._priorityPanelTemplatePortal.isAttached) {
      // Detach it
      this._priorityPanelTemplatePortal.detach();
    }

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }
}
