import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import { AccountService } from 'app/core';
import { GetNoteByIdResponse, GetNotesResponse, Note } from './notes.types';
import { Account } from 'app/core/models';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  private account: Account;
  constructor(private _httpClient: HttpClient, private readonly accountService: AccountService) {
    this.accountService.currentAccount.subscribe((accountData) => {
      this.account = accountData;
    });
  }

  getFilteredNotes(
    noteableType: string,
    noteableId: number,
    perPage: number,
    page: number,
  ): Observable<GetNotesResponse> {
    return this._httpClient.get<GetNotesResponse>(
      `notes?noteable_id=${noteableId}&noteable_type=${noteableType}&account_id=${
        this.account.id
      }&per_page=${perPage}&page=${page + 1}`,
    );
  }

  getContactNotes(contactId: string): Observable<Note[]> {
    return this._httpClient
      .get<{ notes: Note[] }>(`contacts/${contactId}/notes?account_id=${this.account.id}`)
      .pipe(map(({ notes }) => notes));
  }

  getCompanyNotes(companyId: string): Observable<Note[]> {
    return this._httpClient
      .get<{ notes: Note[] }>(`companies/${companyId}/notes?account_id=${this.account.id}`)
      .pipe(map(({ notes }) => notes));
  }

  getNoteById(id: number): Observable<GetNoteByIdResponse> {
    return this._httpClient.get<any>(`notes/${id}?account_id=${this.account.id}`);
  }

  createNote(note: Note): Observable<Note> {
    return this._httpClient
      .post<{ note: Note }>(`notes?account_id=${this.account.id}`, { note })
      .pipe(map(({ note }) => note));
  }

  updateNote(note: Note): Observable<Note> {
    return this._httpClient
      .put<{ note: Note }>(`notes/${note.id}?account_id=${this.account.id}`, { note })
      .pipe(map(({ note }) => note));
  }

  deleteNote(id: number): Observable<void> {
    return this._httpClient.delete<void>(`notes/${id}?account_id=${this.account.id}`);
  }
}
