export interface AlertBannerConfig {
  bgColor: string;
  iconName: string;
}

export enum BgColorEnum {
  INFO = '#76A9FA',
  SUCCESS = '#31C48D',
  WARNING = '#FACA15',
  ERROR = '#F05252',
}

export enum IconNameEnum {
  INFO = 'info',
  SUCCESS = 'check_circle',
  WARNING = 'warning',
  ERROR = 'error',
}

export type AlertTypes = 'info' | 'success' | 'warning' | 'error';

export const TYPE_CONFIG = {
  info: { bgColor: BgColorEnum.INFO, iconName: IconNameEnum.INFO },
  success: { bgColor: BgColorEnum.SUCCESS, iconName: IconNameEnum.SUCCESS },
  warning: { bgColor: BgColorEnum.WARNING, iconName: IconNameEnum.WARNING },
  error: { bgColor: BgColorEnum.ERROR, iconName: IconNameEnum.ERROR },
};
