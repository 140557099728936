import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatTaskTitle' })
export class FormatTaskTitlePipe implements PipeTransform {
  transform(value: string): any {
    if (value) {
      if (typeof value === 'string' && value.includes(':')) {
        let splitVals = value.split(':');
        if (splitVals.length > 1) {
          return `<strong>${splitVals[0]}:</strong> ${splitVals[1]}`;
        }
      }

      return value;
    }
  }
}
