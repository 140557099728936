<div
  class="container"
  [ngClass]="{
    'w-full': uiOptions?.size === 'full',
    'no-rounded-borders': uiOptions?.roundedBorders === false,
    'item-selected': !!selectedItem
  }"
>
  <div class="container__splash" [class]="ui.splashId">
    <div class="container__splash__selection-box">
      <ng-container *ngIf="selectedItem; else noSelection">
        <div
          class="container__splash__selection-box__item"
          [ngClass]="{ 'container__splash__selection-box__item--full': uiOptions?.size === 'full' }"
        >
          <ng-container *ngIf="getSelectedItemInfo() as itemInfo">
            <!-- <div class="container__splash__selection-box__item__group-label">{{ itemInfo.groupLabel }}</div> -->
            {{ itemInfo.label }}
          </ng-container>
        </div>
      </ng-container>
      <ng-template #noSelection>
        <div class="container__splash__selection-box__item no-selection">{{ uiOptions?.placeholder || 'select' }}</div>
      </ng-template>
    </div>
    <mat-icon>arrow_drop_down</mat-icon>
  </div>
  <div
    *ngIf="isPanelOpen | async"
    class="container__options-panel"
    [ngClass]="{ 'rounded-none': uiOptions?.roundedBorders === false }"
    [class]="ui.optionsPanelId"
  >
    <div class="container__options-panel__search-box">
      <mat-icon>search</mat-icon>
      <input
        #searchInput
        type="text"
        [placeholder]="uiOptions?.searchPlaceholder || 'Search...'"
        [formControl]="searchFormControl"
      />
    </div>
    <cdk-virtual-scroll-viewport
      appendOnly
      itemSize="36"
      class="container__options-panel__list"
      [ngClass]="{
        medium: uiOptions?.size === 'medium',
        small: uiOptions?.size === 'small',
        'one-item': items?.length === 1,
        'two-items': items?.length === 2,
        'three-items': items?.length === 3,
        'four-items': items?.length === 4,
        'has-group': hasGroups
      }"
    >
      <ng-container *ngIf="hasGroups; else simpleOptions">
        <div
          class="container__options-panel__list__item"
          [class.group]="item.isGroup"
          [class.selected]="item[itemKey] === selectedItem"
          *cdkVirtualFor="
            let item of items | tsGroupBy : true | tsFilterBy : [] : (searchValue$ | async) : filterFn;
            templateCacheSize: 20
          "
          (click)="onOptionSelect($event, item)"
        >
          <mat-icon *ngIf="item[itemKey] === selectedItem">check_circle_outline</mat-icon>
          {{ item.isGroup ? item.label : item[itemLabel] }}
        </div>
      </ng-container>
      <ng-template #simpleOptions>
        <div
          class="container__options-panel__list__item"
          [class.group]="item.isGroup"
          [class.selected]="item[itemKey] === selectedItem"
          *cdkVirtualFor="
            let item of items | tsFilterBy : [] : (searchValue$ | async) : filterFn;
            templateCacheSize: 20
          "
          (click)="onOptionSelect($event, item)"
        >
          <mat-icon *ngIf="item[itemKey] === selectedItem">check_circle_outline</mat-icon>
          {{ item[itemLabel] }}
        </div>
      </ng-template>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
