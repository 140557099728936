import { ExportOptions } from 'app/shared/models/export-options';
import { GraphqlParams } from 'app/shared/models/graphql';

export namespace Properties {
  export namespace Graphql {
    export interface Params extends GraphqlParams<any> {
      exportOptions?: ExportOptions;
    }

    export class Action {
      static readonly type = '[Properties] Graphql';

      constructor(public graphqlParams: Params) {}
    }
  }
}
