import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsFilterListItemComponent } from './ts-filter-list-item.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TsPipesModule } from '../cross-cutting/pipes/ts-pipes.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TsButtonModule } from '../ts-button';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';
import { TsSelectModule } from '../ts-select/ts.select.module';
import { TsOverlayModule } from '../ts-overlay';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@NgModule({
  declarations: [TsFilterListItemComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    TsPipesModule,
    TsButtonModule,
    CurrencyMaskModule,
    MatIconModule,
    TsSelectModule,
    TsOverlayModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [TsFilterListItemComponent],
  providers: [provideNgxMask()],
})
export class TsFilterListItemModule {}
