<div class="priority-and-due-date">
  <div [class.priority]="!condensedView" [class.mr-1]="condensedView" [class.expand-on-hover]="condensedView">
    <span class="whitespace-nowrap">
      <button
        [matTooltipDisabled]="!condensedView"
        [matTooltipPosition]="'left'"
        [matTooltip]="getTaskStatus(task.workflow_state)?.label"
        [class.w-4]="condensedView"
        (click)="openPriorityPanel($event)"
        #priorityPanelOrigin
      >
        <span
          class="inline-flex items-center font-bold text-xs rounded-full tracking-wide uppercase"
          [ngClass]="{
            'teal-200': task.workflow_state === 'competitive',
            'yellow-300': task.workflow_state === 'quoted',
            'yellow-50': task.workflow_state === 'in_approval',
            'gray-200': task.workflow_state === 'n_a' || task.workflow_state === 'sent',
            'gray-300': task.workflow_state === 'not_started',
            'blue-200': task.workflow_state === 'in_progress' || task.workflow_state === 'received',
            'red-200': task.workflow_state === 'needs_review' || task.workflow_state === 'rejected',
            'green-200': task.workflow_state === 'complete' || task.workflow_state === 'accepted',
            'full-width px-2 py-2px': !condensedView
          }"
        >
          <span
            class="w-2 h-2 rounded-full"
            [class.mr-2]="!condensedView"
            [ngClass]="{
              'bg-yellow': task.workflow_state === 'quoted',
              'bg-gray-500': task.workflow_state === 'n_a' || task.workflow_state === 'sent',
              'bg-gray-700': task.workflow_state === 'not_started',
              blue: task.workflow_state === 'in_progress' || task.workflow_state === 'received',
              'yellow-300': task.workflow_state === 'in_approval',
              red: task.workflow_state === 'needs_review' || task.workflow_state === 'rejected',
              green:
                task.workflow_state === 'complete' ||
                task.workflow_state === 'accepted' ||
                task.workflow_state === 'competitive'
            }"
          ></span>
          <span class="pr-2px leading-relaxed whitespace-nowrap" *ngIf="!condensedView">{{
            getTaskStatus(task.workflow_state)?.label
          }}</span>
        </span>
      </button>
    </span>
  </div>

  <!-- Priority panel -->
  <ng-template #priorityPanel>
    <div class="tasks-details-priority-panel">
      <mat-radio-group [color]="'primary'" [(ngModel)]="task.workflow_state" (change)="onChangeStatus()">
        <ng-container *ngFor="let taskStatus of taskStatuses">
          <mat-radio-button [value]="taskStatus.key">
            <span>{{ taskStatus.label }}</span>
            <span class="calendar-color" [ngClass]="taskStatus.color"></span>
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>
    </div>
  </ng-template>
</div>
