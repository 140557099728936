import { Component, EventEmitter, Input, Output, ViewContainerRef } from '@angular/core';
import { TsOverlayComponent } from '../ts-overlay';
import { MatLegacyMenuPanel as MatMenuPanel } from '@angular/material/legacy-menu';

@Component({
  selector: 'ts-button',
  templateUrl: './ts-button.component.html',
  styleUrls: ['./ts-button.component.scss'],
})
/**
   @deprecated we are transitioning to use the `variant` property to assign styles to the button
   */
export class TsButtonComponent {
  @Input() type: 'flat' | 'raised' | 'stroked' | 'icon' = 'flat';
  /**
   * @deprecated we are transitioning to use the `variant` property to assign styles to the button
   */
  @Input() color: 'primary' | 'basic' | 'accent' | 'warn' | 'success' = 'primary';
  @Input() variant: 'primary' | 'secondary' | 'danger';
  @Input() icon: string = '';
  @Input() iconPosition: 'prefix' | 'suffix' = 'prefix';
  @Input() loading = false;
  @Input() disabled = false;
  @Input() debounceTime: number = 0;
  @Input() matMenuTriggerFor: MatMenuPanel;
  @Input() iconTooltip: string;

  /**
   * @description
   * Overlay component to be displayed
   */
  @Input() tsOverlayTriggerFor: TsOverlayComponent;
  @Input() iconColor;
  @Input() alwaysEmitClick = false;

  /**
   * @description
   * Emit the click event. When tsOverlayTriggerFor is set, it will open the overlay
   * */
  @Output() click: EventEmitter<PointerEvent> = new EventEmitter();

  constructor(readonly viewContainerRef: ViewContainerRef) {}

  /**
   * @description
   * Emit the click event if the button is not disabled
   * @example
   * <ts-button (click)="onClick($event)"></button>
   **/
  onClick(event: PointerEvent): void {
    if (this.disabled) return;

    if (this.tsOverlayTriggerFor) {
      this.openOverlayHelper(event);

      if (this.alwaysEmitClick) {
        this.click.emit(event);
      }
    } else {
      this.click.emit(event);
    }
  }

  openOverlayHelper(event: any): void {
    this.tsOverlayTriggerFor.displayOverlay(event.target?.nativeElement || event.target);
  }
}
