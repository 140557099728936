import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Folders } from './actions';
import { StateBase } from 'app/state/state-base';
import { FoldersTabService } from '../folders.service';
import { FileResource, FolderResource, FoldersSearchResult } from '../folders.types';

export interface FolderResourceStateModel {
  folder: FolderResource;
  moduleBaseUrl: string;
  moduleId: number;
  searchResult: FoldersSearchResult;
  sharedResult: any;
  selectedFile: FileResource | null;
  base64Document: any;
}

const FOLDER_STATE_TOKEN = new StateToken<FolderResourceStateModel>('folderState');

@State({
  name: FOLDER_STATE_TOKEN,
  defaults: {
    folder: null,
    moduleBaseUrl: null,
    moduleId: null,
    searchResult: null,
    sharedResult: null,
    selectedFile: null,
    base64Document: null,
  },
})
@Injectable()
export class FolderResourceState extends StateBase {
  constructor(protected readonly store: Store, private foldersTabService: FoldersTabService) {
    super(store);
  }

  @Selector()
  static getFolder(state: FolderResourceStateModel): FolderResource {
    return state.folder;
  }

  @Selector()
  static getSelectedFile(state: FolderResourceStateModel): FileResource {
    return state.selectedFile;
  }

  @Selector()
  static getSearchResult(state: FolderResourceStateModel): FoldersSearchResult {
    return state.searchResult;
  }

  @Action(Folders.GetFolder)
  getFolder(context: StateContext<FolderResourceStateModel>, { id }): void {
    this.foldersTabService.getFolder(id).subscribe((folder) => {
      const newState = { ...context.getState(), folder };
      context.setState(newState);
    });
  }

  @Action(Folders.UpdateFolderModule)
  updateFolderModule(context: StateContext<FolderResourceStateModel>, { moduleBaseUrl, moduleId }): void {
    this.foldersTabService.moduleId = moduleId;
    this.foldersTabService.moduleBaseUrl = moduleBaseUrl;

    const newState = { ...context.getState(), moduleBaseUrl, moduleId };

    context.setState(newState);
  }

  @Action(Folders.ResetFolderState)
  resetFolderState(context: StateContext<FolderResourceStateModel>): void {
    const emptyFolderState = {
      folder: null,
      moduleBaseUrl: null,
      moduleId: null,
      searchResult: null,
      sharedResult: null,
      selectedFile: null,
      base64Document: null,
    };

    context.setState(emptyFolderState);
  }

  @Action(Folders.SearchFolder)
  searchFolder(context: StateContext<FolderResourceStateModel>, { query }): Observable<any> {
    return this.foldersTabService.searchFolders(query).pipe(
      tap((response) => {
        context.setState({
          ...context.getState(),
          searchResult: response,
        });
      }),
    );
  }

  @Action(Folders.AddFolder)
  addFolder(context: StateContext<FolderResourceStateModel>, { folder, opts }): Observable<any> {
    return this.foldersTabService.createFolder(folder).pipe(
      tap((folder) => {
        let state = context.getState();
        if (opts?.updateCurrentFolder) {
          state = {
            ...state,
            searchResult: null,
            folder,
          };
        } else {
          // add as children
          const currentFolder = context.getState().folder;
          currentFolder.children.push(folder);

          state = {
            ...state,
            searchResult: null,
            folder: { ...currentFolder },
          };
        }

        context.setState(state);
      }),
    );
  }

  @Action(Folders.UpdateFolder)
  updateFolder(context: StateContext<FolderResourceStateModel>, { folder }): Observable<any> {
    return this.foldersTabService.updateFolder(folder);
  }

  @Action(Folders.DeleteFolder)
  deleteFolder(context: StateContext<FolderResourceStateModel>, { id }): Observable<null> {
    const currentFolder = context.getState().folder;
    return this.foldersTabService
      .deleteFolder(id)
      .pipe(tap(() => context.dispatch(new Folders.GetFolder(currentFolder.id))));
  }

  @Action(Folders.ShareFolder)
  shareFolder(context: StateContext<FolderResourceStateModel>, { folderId }): Observable<any> {
    return this.foldersTabService.shareFolder(folderId).pipe(
      tap((result) => {
        context.setState({
          ...context.getState(),
          sharedResult: result.shared_resource,
        });
      }),
    );
  }

  @Action(Folders.GetFile)
  getFile(context: StateContext<FolderResourceStateModel>, { folderId, fileId }): any {
    return this.foldersTabService.getFile(folderId, fileId).pipe(
      tap((response) => {
        context.patchState({ selectedFile: response });
      }),
    );
  }

  @Action(Folders.CreateFile)
  createFile(context: StateContext<FolderResourceStateModel>, { folderId, file, opts }): any {
    const fileResource: FileResource = {
      folder_resource_id: folderId,
      name: file.name,
    };

    if (opts.relatedToType && opts.relatedToId) {
      fileResource.related_to_type = opts.relatedToType;
      fileResource.related_to_id = opts.relatedToId;
    }

    return this.foldersTabService.createFile(folderId, fileResource).pipe(
      tap((newFileResource) => {
        context.dispatch(
          new Folders.CreateFileDocument(newFileResource.folder_resource_id, newFileResource.id, file, opts),
        );
      }),
    );
  }

  @Action(Folders.CreateFileDocument)
  createFileDocument(
    context: StateContext<FolderResourceStateModel>,
    { folderId, fileId, file, opts },
  ): Observable<FileResource> {
    return this.foldersTabService.uploadDocument(folderId, fileId, file).pipe(
      tap((newFileResource) => {
        const currentFolder = context.getState().folder;
        if (!currentFolder) return;

        const currentFileResources = currentFolder.file_resources;

        if (opts.updateState) {
          const fileIndex = currentFolder.file_resources.findIndex(({ id }) => newFileResource.id === id);
          if (fileIndex === -1) {
            currentFileResources.push(newFileResource);
          } else {
            currentFileResources[fileIndex] = newFileResource;
          }

          const newFolder = {
            ...currentFolder,
            file_resources: currentFileResources,
          };

          context.setState({
            ...context.getState(),
            folder: newFolder,
          });
        }
      }),
    );
  }

  @Action(Folders.DeleteDocument)
  deleteDocument(
    context: StateContext<FolderResourceStateModel>,
    { folderId, fileId, documentId, opts },
  ): Observable<FileResource> {
    return this.foldersTabService.deleteDocument(folderId, fileId, documentId).pipe(
      tap(() => {
        const folder = { ...context.getState().folder };
        const fileIndex = folder.file_resources.findIndex(({ id }) => fileId === id);
        folder.file_resources[fileIndex].documents.splice(
          folder.file_resources[fileIndex].documents.findIndex((item) => item.id === documentId),
          1,
        );

        context.patchState({ folder });
      }),
    );
  }

  @Action(Folders.GetBase64Document)
  getBase64Document(
    context: StateContext<FolderResourceStateModel>,
    { folderId, fileId, documentId },
  ): Observable<FileResource> {
    return this.foldersTabService.getBase64Document(folderId, fileId, documentId).pipe(
      tap((document) => {
        context.patchState({
          base64Document: document,
        });
      }),
    );
  }

  @Action(Folders.UploadExtractedData, { cancelUncompleted: true })
  uploadExtractData(
    context: StateContext<FolderResourceStateModel>,
    { folderId, fileId, documentId, data },
  ): Observable<FileResource> {
    return this.foldersTabService.uploadedExtractedDataToDocument(folderId, fileId, documentId, data).pipe(
      tap((document) => {
        context.patchState({
          base64Document: document,
        });
      }),
    );
  }

  @Action(Folders.DeleteFile)
  deleteFile(context: StateContext<FolderResourceStateModel>, { folderId, fileId }): any {
    return this.foldersTabService.deleteFile(folderId, fileId).pipe(
      tap(() => {
        const currentFolder = context.getState().folder;
        const newFolder = {
          ...currentFolder,
          file_resources: [...currentFolder.file_resources.filter(({ id }) => id !== fileId)],
        };

        context.setState({
          ...context.getState(),
          folder: newFolder,
        });
      }),
    );
  }

  @Action(Folders.UpdateFile)
  updateFile(context: StateContext<FolderResourceStateModel>, { folderId, fileResource }): any {
    return this.foldersTabService.updateFile(folderId, fileResource).pipe(
      tap((updatedFile) => {
        const folder = { ...context.getState().folder };

        folder.file_resources = folder.file_resources.map((file) => {
          if (file.id === updatedFile.id) {
            file = updatedFile;
          }
          return file;
        });

        context.setState({
          ...context.getState(),
          folder,
        });
      }),
    );
  }

  @Action(Folders.UpdateRank)
  updateRank(context: StateContext<FolderResourceStateModel>, { files }): any {
    return this.foldersTabService.updateRanks(files, 'FileResource').pipe(
      tap((newFile) => {
        const folder = { ...context.getState().folder };
        if (newFile) {
          folder.file_resources.splice(
            folder.file_resources.findIndex((item) => item.id === newFile.id),
            1,
          );

          folder.file_resources = [newFile, ...folder.file_resources];

          context.setState({
            ...context.getState(),
            folder,
          });
        }
      }),
    );
  }

  @Action(Folders.SendToDocusign)
  sendToDocsign(context: StateContext<FolderResourceStateModel>, { folderId, fileId }): any {
    return this.foldersTabService.sendToDocusign(folderId, fileId).pipe(
      tap((response) => {
        window.open(response.url, '_blank');
      }),
    );
  }

  @Action(Folders.ShareFile)
  shareFile(context: StateContext<FolderResourceStateModel>, { folderId, fileId }): Observable<any> {
    return this.foldersTabService.shareFile(folderId, fileId).pipe(
      tap((result) => {
        context.setState({
          ...context.getState(),
          sharedResult: result.shared_resource,
        });
      }),
    );
  }
}
