<mat-autocomplete>
  <ng-container *ngIf="_options.length > 0; else noResults">
    <ng-container *ngIf="isGroupOption; else singleOption">
      <ng-container *ngFor="let optionGroup of _options; let c = count">
        <mat-optgroup [label]="optionGroup.group">
          <mat-option *ngFor="let option of optionGroup.options" [value]="option">{{ option }}</mat-option>
        </mat-optgroup>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #singleOption>
    <ng-container *ngFor="let option of _options; let c = count">
      <mat-option [value]="option">{{ option }}</mat-option>
    </ng-container>
  </ng-template>

  <ng-template #noResults>
    <mat-option disabled class="text-gray-default italic text-center text-md">No Results</mat-option>
  </ng-template>
</mat-autocomplete>
