import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { RelatedToType } from 'app/shared/enums';
import { Dictionary } from 'app/shared/models';

export type SearchResult = Dictionary<Array<Dictionary<string> & { search_highlights: Dictionary<string> }>>;
export type SearchFilter = { accountId: number; query: string; types: RelatedToType[] };

@Injectable({ providedIn: 'root' })
export class GlobalSearchService extends BaseService {
  constructor(protected readonly httpClient: HttpClient) {
    super(httpClient);
  }

  search({ accountId, query, types }: SearchFilter): Observable<SearchResult> {
    const params = new URLSearchParams({
      account_id: accountId.toString(),
      q: query,
      types: types.join(','),
    });

    return this.httpClient
      .get<{ results: SearchResult }>(`search/global?${params.toString()}`)
      .pipe(map(({ results }) => results));
  }
}
