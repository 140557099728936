import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-enterprise';
import { BehaviorSubject, debounceTime, noop, switchMap, tap } from 'rxjs';
import { TsPipesModule } from '../../cross-cutting/pipes/ts-pipes.module';
import { LOOKUP_SERVICE_TOKEN } from '../../cross-cutting/util';
import { LookupContract, LookupResult } from '../../cross-cutting/models';
import { TSIconModule } from '../../ts-icon/ts-icon.module';

export type RecordCellEditorParams = ICellEditorParams & {
  multiSelect: boolean;
  recordType: string;
  options?: LookupResult[];
};

@Component({
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    AsyncPipe,
    MatProgressSpinnerModule,
    TsPipesModule,
    FormsModule,
    ReactiveFormsModule,
    TSIconModule,
  ],
  template: `
    <div class="container" [style]="{ width: width }">
      @if(!params.options?.length){
      <input placeholder="Search..." autocomplete="off" [formControl]="recordSearch" autofocus />
      }
      <ul>
        @if(params.multiSelect){ @for(selected of selectedLookupResults;track selected) {
        <li class="selected">
          {{ selected.label }}
          <ts-icon (click)="remove(selected)" icon="ts_trash-alt"></ts-icon>
        </li>
        } } @if(lookupLoading | async){
        <mat-progress-spinner class="spinner" diameter="24" mode="indeterminate"> </mat-progress-spinner>
        } @for(result of lookupResults|async | tsFilterBy: []:selectedLookupResults:hideSelected;track result) {
        <li (click)="select(result)">{{ result.label }}</li>
        }
      </ul>
    </div>
  `,
  styles: [
    `
      .container {
        background: white;
        padding: 12px 0px 0px 0px;
        height: 220px;

        input {
          padding: 4px 16px 8px 16px;
          border-bottom: 1px solid #e2e8f0;
        }

        ul {
          overflow: auto;

          li {
            padding: 8px 16px;
            font-size: 10pt;

            &.selected {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: 500;
            }

            &:hover {
              background: #f5f5f5;
              cursor: pointer;
            }
          }
        }
      }
    `,
  ],
})
export class TsAgMultiSelectEditor implements ICellEditorAngularComp {
  recordSearch = new FormControl();
  lookupLoading = new BehaviorSubject<boolean>(false);
  lookupResults = new BehaviorSubject<LookupResult[]>([]);
  selectedLookupResults: LookupResult[] = [];
  params: RecordCellEditorParams = null;
  width = 'auto';

  constructor(@Inject(LOOKUP_SERVICE_TOKEN) private lookupService: LookupContract) {}

  agInit(params: RecordCellEditorParams): void {
    this.params = params;
    this.width = params.column.getActualWidth() + 'px';

    if (Array.isArray(params.value)) {
      this.selectedLookupResults = params.value.map((item) => this.recordStringToLink(item));
    }

    if (typeof params.value === 'string') {
      this.selectedLookupResults = [this.recordStringToLink(params.value)];
    }

    if (params.options?.length) {
      this.lookupResults.next(params.options);
    } else {
      this.recordSearch.valueChanges
        .pipe(
          debounceTime(300),
          tap(() => this.lookupLoading.next(true)),
          switchMap((value: string) => this.lookupService.lookFor({ query: value, relatedTo: params.recordType })),
          tap((result: LookupResult[]) => {
            this.lookupResults.next([...this.selectedLookupResults, ...result]);
            this.lookupLoading.next(false);
          }),
        )
        .subscribe(noop);

      this.recordSearch.setValue('');
    }
  }

  isPopup?(): boolean {
    return true;
  }

  getPopupPosition?(): 'over' | 'under' | undefined {
    return 'under';
  }

  remove(result: LookupResult): void {
    this.selectedLookupResults = this.selectedLookupResults.filter(({ id }) => id !== result.id);
  }

  select(result: LookupResult): void {
    if (!this.params.multiSelect) {
      this.selectedLookupResults = [result];
      this.params.stopEditing();
      return;
    }

    this.selectedLookupResults = [...this.selectedLookupResults, result];
  }

  getValue(): any {
    if (!this.params.multiSelect) {
      const [first] = this.selectedLookupResults.map(({ id, label }) => `${id}|${label}`);
      return first;
    }

    return this.selectedLookupResults.map(({ id, label }) => `${id}|${label}`);
  }

  hideSelected(value: LookupResult[], selectedLookupResults: LookupResult[]): LookupResult[] {
    return value.filter(({ id }) => !selectedLookupResults.map((result) => result.id).includes(id));
  }

  private recordStringToLink(recordString: string): LookupResult {
    return {
      id: Number(recordString.substring(0, recordString.indexOf('|'))),
      label: recordString.substring(recordString.indexOf('|') + 1),
    };
  }
}
