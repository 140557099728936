import Rollbar from 'rollbar';
import { Injectable, Inject, InjectionToken, ErrorHandler } from '@angular/core';
import { environment } from '@env/environment';
import { AccountService } from './account.service';
import { Account } from 'app/core/models/account.types';
import { Store } from '@ngxs/store';
import { UserState } from 'app/state/user/state';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  private canLogToConsole = false;
  private errorLogEnabledKey = 'error.log.enabled';
  private readonly failedToLoadAssetsMessage = 'Some assets failed to load.';

  private get currentUserId(): number {
    return this.store.selectSnapshot(UserState.getUser)?.id;
  }

  constructor(
    @Inject(RollbarService) private readonly rollbar: Rollbar,
    private readonly accountService: AccountService,
    private readonly store: Store,
    private readonly snackBar: MatSnackBar,
  ) {
    this.accountService.currentAccount.subscribe((account: Account) => {
      if (environment.production) {
        this.canLogToConsole =
          environment.env === 'stagingUI' || account.subdomain === 'desert2' || environment.env === 'qaUI';
      } else {
        this.canLogToConsole = environment.env === 'developmentUI';
      }
    });
  }

  handleError(err: any): void {
    if (environment.production) {
      this.rollbar.error(err.originalError || err, { userId: this.currentUserId });
    }

    if (this.canLogToConsole || localStorage.getItem(this.errorLogEnabledKey)) {
      console.error(err);
    }

    if (/Loading chunk (\d+) failed./.test(err.message)) {
      this.rollbar.warning(err, { userId: this.currentUserId });
      this.snackBar
        .open(this.failedToLoadAssetsMessage, 'Click here to reload.', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        })
        .onAction()
        .subscribe(() => location.reload());
    }
  }
}

export function rollbarFactory(): Rollbar {
  return new Rollbar(environment.rollbar);
}
