import { Pipe, PipeTransform } from '@angular/core';
import { plural } from 'pluralize';

@Pipe({
  name: 'pluralize',
})
export class PluralizePipe implements PipeTransform {
  transform(value: string): string {
    return plural(value);
  }
}
