import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsFilterComponent } from './ts-filter.component';
import { TsButtonModule } from '../ts-button';
import { MatIconModule } from '@angular/material/icon';
import { TsFilterListModule } from '../ts-filter-list';

@NgModule({
  declarations: [TsFilterComponent],
  imports: [CommonModule, TsButtonModule, TsFilterListModule, MatIconModule],
  exports: [TsFilterComponent],
})
export class TsFilterModule {}
