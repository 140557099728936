import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Project, Pipeline } from 'app/modules/projects/projects.types';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { Account, FieldDef } from 'app/core/models/account.types';
import { ProjectModalDialogComponent } from 'app/modules/projects/project-dialog/project-modal-dialog/project-modal-dialog.component';
import { AccountService } from 'app/core';
import { FoldersTabService } from 'app/shared/modules/folders/folders.service';
import { UserService } from 'app/layout/common/user/user.service';
import { getAllowedFields, removeHiddenFieldsFromTableDefinition } from 'app/shared/functions';
import { RelatedToType } from 'app/shared/enums';

@Component({
  selector: 'project-dialog',
  templateUrl: './project-dialog.component.html',
  styleUrls: ['./project-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  data: any;
  account: Account;
  project: Project;
  pipeline: Pipeline;
  displayColumns: [];
  fieldDefs: FieldDef[] = [];

  private _unsubscribeAll: Subject<void>;
  panels: any[];
  private _dashboardDefinition: any;
  private _opened: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _matDialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _foldersTabService: FoldersTabService,
    private _accountService: AccountService,
    private _projectsService: ProjectsService,
    private _userService: UserService,
    private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._accountService.currentAccount.subscribe((accountData) => {
      this.account = accountData;
    });
  }

  ngOnInit(): void {
    this._opened = false;
    this._activatedRoute.data.subscribe((data: Data) => {
      this.data = data;
      this.fieldDefs = data.fieldDefs;
      this.pipeline = data.pipeline;
      let definition = this.pipeline.table_definition;
      if (definition) {
        this.displayColumns = definition.fields;
      }
    });
    this._projectsService.project$.pipe(takeUntil(this._unsubscribeAll)).subscribe((project: Project) => {
      this.project = project;

      //load comments
      this._projectsService.getComments().subscribe();
      //load root folder
      this._foldersTabService.moduleId = this.project.id;
      this._foldersTabService.moduleBaseUrl = 'projects';
      this._foldersTabService.getFolder().subscribe();
      this._userService.getRecentProjects(this.account.id).subscribe();

      if (!this._opened) {
        this.openProjectDialog();
      }

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  openProjectDialog(): void {
    this._opened = true;
    const allowedFields = getAllowedFields(this.fieldDefs, RelatedToType.Project);
    const dialogRef = this._matDialog.open(ProjectModalDialogComponent, {
      panelClass: 'mailbox-compose-dialog',
      width: '50vw',
      data: {
        project: this.project,
        account: this.account,
        fieldDefs: allowedFields,
        options: this.data,
        pipeline: this.pipeline,
        displayColumns: removeHiddenFieldsFromTableDefinition(this.displayColumns, allowedFields),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this._router.navigate(['', { outlets: { modal: null } }]).then(() => {
        if (result?.redirect) {
          this._router.navigate(result.redirect);
        }
      });
    });
  }

  /**
   * Discard the message
   */
  discard(): void {}

  /**
   * Passed through to panels to be called when a field has been updated
   */
  updated() {}

  /**
   * Called from the individual panels when modifyign the panel definition
   */
  onEditPanelDef($event): void {
    // Let's find the panel with this id and replace it
    for (let col in this.panels) {
      for (let row in this.panels[col]) {
        let panel = this.panels[col][row];
        if (panel.id == $event.id) {
          this.panels[col][row] = $event;
        }
      }
    }
    this._dashboardDefinition.panels = this.panels;
    this.pipeline.dashboard_definition = JSON.stringify(this._dashboardDefinition);
    this._projectsService.updatePipeline(this.pipeline).subscribe();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
