import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'TsIsObject',
})
export class TsIsObjectPipe implements PipeTransform {
  transform(value: any): boolean {
    return typeof value === 'object';
  }
}
