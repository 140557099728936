export class TreoAnimationCurves {
  static STANDARD_CURVE = 'cubic-bezier(0.4, 0.0, 0.2, 1)';
  static DECELERATION_CURVE = 'cubic-bezier(0.0, 0.0, 0.2, 1)';
  static ACCELERATION_CURVE = 'cubic-bezier(0.4, 0.0, 1, 1)';
  static SHARP_CURVE = 'cubic-bezier(0.4, 0.0, 0.6, 1)';
}

export class TreoAnimationDurations {
  static COMPLEX = '375ms';
  static ENTERING = '225ms';
  static EXITING = '195ms';
}
