import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';

export type TsAutocompleteOptionsGroup = { group: string; options: string[] }[];

export function isTsAutoCompleteOptionsGroup(value: any): value is TsAutocompleteOptionsGroup {
  return Array.isArray(value) && value.every((v) => v.hasOwnProperty('group') && v.hasOwnProperty('options'));
}

@Component({
  selector: 'ts-autocomplete',
  templateUrl: './ts-autocomplete.component.html',
  styleUrls: ['./ts-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsAutocompleteComponent {
  @Input() set options(value: string[] | TsAutocompleteOptionsGroup) {
    this.isGroupOption = isTsAutoCompleteOptionsGroup(value);
    this._options = value;
  }

  @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;

  isGroupOption = false;
  _options: string[] | TsAutocompleteOptionsGroup;

  static filterGroupOptions(value: string, options: TsAutocompleteOptionsGroup): TsAutocompleteOptionsGroup {
    return options
      .map(({ group, options }) => {
        return {
          group,
          options: TsAutocompleteComponent.filterOptions(value, options),
        };
      })
      .filter(({ options }) => options.length > 0);
  }

  static filterOptions(value: string, options: string[]): string[] {
    return options.filter((option) => option.toLowerCase().includes(value.toLowerCase()));
  }
}
