import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { Project, ProjectTemplate } from 'app/modules/projects/projects.types';
import { FieldDef } from 'app/core/models/account.types';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'project-status-required-fields',
  templateUrl: './project-status-required-fields.component.html',
  styleUrls: ['./project-status-required-fields.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectStatusRequiredFieldsComponent extends OnDestroyMixin implements OnInit {
  fieldDefs: FieldDef[] = [];
  project: Project;
  displayColumns: any[];
  options: [];
  projectTemplates: ProjectTemplate<any>[];
  duplicateProjectId: number = 0;
  duplicateProjects: Project[] = [];
  uploadedFile: any;
  isLoading = true;

  get isStatus(): boolean {
    return this.data?.isStatus ?? true;
  }

  private get stageOrPhaseProperty(): string {
    return this.isStatus ? 'project_stage_id' : 'project_phase_id';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<ProjectStatusRequiredFieldsComponent>,
    private _projectsService: ProjectsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.fieldDefs = this.data.data.fieldDefs;
    this.project = this.data.project;
    if (this.project) {
      this._projectsService.bustProjectCache();
      this._projectsService
        .getProjectById(this.project.id)
        .pipe(untilComponentDestroyed(this))
        .subscribe((result) => {
          this.project = result;
          this.options = this.data.data;
          this.displayColumns = this.data.stage.required_fields
            .split(',')
            .map((field) => this.fieldDefs.find((def) => def.name === field))
            .filter((field) => field);
          this.isLoading = false;
        });
    }
  }

  discard(): void {
    this.matDialogRef.close();
  }

  lookupField(name: string): FieldDef {
    return this.fieldDefs.find((fieldDef) => fieldDef.name == name);
  }

  update(): void {
    this.isLoading = true;
    const data = this.displayColumns.reduce((p, c) => ({ ...p, [c.name]: this.project[c.name] }), {});
    data.id = this.project.id;
    data[this.stageOrPhaseProperty] = this.data.stage.id;
    data[this.stageOrPhaseProperty.replace('_id', '')] = this.data.stage;
    this._projectsService
      .saveProject(data, this.data?.columns?.join(','))
      .pipe(untilComponentDestroyed(this))
      .subscribe((updatedProject) => {
        this.isLoading = false;
        this.matDialogRef.close({ updatedProject });
      });
  }

  cancel() {
    this.discard();
  }
}
