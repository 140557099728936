<div class="container">
  <ts-filter-list-item
    *ngFor="let filter of filters; let i = index"
    [index]="i"
    [fields]="fields"
    [filter]="filter"
    [hasGroups]="hasGroups"
    [lookupContract]="lookupContract"
    (filterDefChanged)="filterDefChanged.emit($event)"
    (filterRemoved)="filterRemoved.emit($event)"
  >
    <span prefix>{{ i === 0 ? 'Where' : 'And' }}</span>
  </ts-filter-list-item>
</div>
