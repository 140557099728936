import { Automation as AutomationModel } from '../builder/builder.types';

export namespace Automation {
  export class Get {
    static readonly type = 'Get Automations';

    constructor() {}
  }

  export class GetById {
    static readonly type = 'Get Automation By Id';

    constructor(public id: number) {}
  }

  export class Create {
    static readonly type = 'Create Automation';

    constructor(public model: AutomationModel) {}
  }

  export class Update {
    static readonly type = 'Update Automation';

    constructor(public model: AutomationModel) {}
  }

  export class Delete {
    static readonly type = 'Delete Automation';

    constructor(public id: number) {}
  }

  export class GetEligibleFiles {
    static readonly type = 'Get Eligible Files';

    constructor() {}
  }
}
