import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';

import { TSIconModule } from '../ts-icon';
import { TsButtonModule } from './../ts-button';
import { SnackbarType, TsSnackbarData } from './ts-snackbar.models';
import { TsSnackbarService } from '../services';

@Component({
  selector: 'ts-snackbar',
  templateUrl: './ts-snackbar.component.html',
  styleUrls: ['./ts-snackbar.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, TsButtonModule, TSIconModule],
})
export class TsSnackbarComponent implements OnInit {
  public iconType: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: TsSnackbarData,
    private readonly tsSnackbarService: TsSnackbarService,
  ) {}

  ngOnInit() {
    this.setIconStyle();
  }

  private setIconStyle(): void {
    switch (this.data.type) {
      case SnackbarType.Info:
      case SnackbarType.Error:
      case SnackbarType.Warning:
        this.iconType = this.data.type;
        break;
      case SnackbarType.Success:
        this.iconType = 'check_circle';
        break;
      case SnackbarType.Default:
        this.iconType = null;
        break;
    }
  }

  public onResetClick(): void {
    if (this.data.onReset) {
      this.data.onReset(); // Trigger 'reset' callback
    }
    this.tsSnackbarService.dismiss();
  }

  public onSaveClick(): void {
    if (this.data.onSave) {
      this.data.onSave(); // Trigger 'save' callback
    }
    this.tsSnackbarService.dismiss();
  }

  public onDismissClick(): void {
    this.tsSnackbarService.dismiss();
  }
}
