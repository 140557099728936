import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheBuster, Cacheable } from 'ts-cacheable';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RolePermission, RolesResponse, RoleResponse } from '../roles-permissions.types';
import { Role } from 'app/core/models';

export const getByAccountIdCacheBuster$ = new Subject<void>();

@Injectable({ providedIn: 'root' })
export class RoleService {
  constructor(private readonly httpClient: HttpClient) {}

  @Cacheable({ cacheBusterObserver: getByAccountIdCacheBuster$ })
  getByAccountId(accountId: number): Observable<RolePermission[]> {
    return this.httpClient.get<RolesResponse>(`accounts/${accountId}/roles`).pipe(map(({ roles }) => roles));
  }

  getByAccountAndRoleId(accountId: number, roleId: number): Observable<RolePermission> {
    return this.httpClient.get<RoleResponse>(`accounts/${accountId}/roles/${roleId}`).pipe(map(({ role }) => role));
  }

  @CacheBuster({ cacheBusterNotifier: getByAccountIdCacheBuster$ })
  create(entity: Partial<Role>, accountId: number): Observable<RolePermission> {
    return this.httpClient.post<RolePermission>(`accounts/${accountId}/roles`, { role: entity });
  }

  @CacheBuster({ cacheBusterNotifier: getByAccountIdCacheBuster$ })
  edit(entity: Partial<Role>, accountId: number): Observable<RolePermission> {
    return this.httpClient.put<RolePermission>(`accounts/${accountId}/roles/${entity.id}`, { role: entity });
  }

  @CacheBuster({ cacheBusterNotifier: getByAccountIdCacheBuster$ })
  delete(roleId: number, accountId: number): Observable<RolePermission> {
    return this.httpClient.delete<RolePermission>(`accounts/${accountId}/roles/${roleId}`);
  }
}
