import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterListItemModel, FilterListItemValueModel, LookupContract } from '../cross-cutting/models/ts-filter.types';

@Component({
  selector: 'ts-filter',
  templateUrl: './ts-filter.component.html',
  styleUrls: ['./ts-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsFilterComponent {
  @Input() fields: FilterListItemModel[];
  @Input() filters: FilterListItemValueModel[];
  @Input() size: 'medium' | 'large' = 'medium';
  @Input() lookupContract: LookupContract;
  @Input() hasGroups = true;
  @Output() addItem = new EventEmitter<void>();
  @Output() filterDefChange = new EventEmitter<FilterListItemValueModel>();
  @Output() filterRemove = new EventEmitter<number>();
  @Output() filterClear = new EventEmitter<number>();
  @Output() filterApply = new EventEmitter<number>();

  canFilter(): boolean {
    if (!this.filters?.length) {
      return false;
    }

    return this.filters.every((filter: FilterListItemValueModel) => {
      if (!filter.fieldName) {
        return false;
      }

      if (!filter.operator) {
        return false;
      }

      if (!filter.value || (Array.isArray(filter.value) && !filter.value.length)) {
        return false;
      }

      return true;
    });
  }
}
