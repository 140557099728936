import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { ObjectGroupService } from '@services/object-group.service';
import { SavedFilter } from 'app/core/models';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { tap } from 'rxjs';

import { StateBase } from '../state-base';
import { SavedFilters } from './action';

export interface SavedFiltersStateModel {
  savedFilters: SavedFilter[];
}

@State<SavedFiltersStateModel>({
  name: new StateToken('savedFiltersState'),
  defaults: {
    savedFilters: [],
  },
})
@Injectable()
export class SavedFiltersState extends StateBase {
  protected projectsService = inject(ProjectsService);
  protected objectGroupService = inject(ObjectGroupService);

  constructor(protected readonly store: Store) {
    super(store);
  }

  @Selector()
  static getVisibleFilters(state: SavedFiltersStateModel) {
    return state.savedFilters.filter((filter) => filter.visible);
  }

  @Action(SavedFilters.Get)
  getSavedFilters(context: StateContext<SavedFiltersStateModel>) {
    return this.accountService.getSavedFilters().pipe(
      tap((savedFilters) => {
        context.setState({ ...context.getState(), savedFilters });
      }),
    );
  }

  @Action(SavedFilters.Set)
  setPipelines(context: StateContext<SavedFiltersStateModel>, { savedFilters }: { savedFilters: SavedFilter[] }) {
    context.setState({ ...context.getState(), savedFilters });
  }
}
