import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { registerLicense } from '@syncfusion/ej2-base';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Registering Syncfusion license key
registerLicense(environment.syncfusion.licenseKey);

// typing this as any since the types we installed don't include _writeKey
(analytics as any)._writeKey = environment.analytics.segmentWriteKey;
analytics.load(environment.analytics.segmentWriteKey);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
