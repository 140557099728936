import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogModel } from '../dialog.types';

import { Observable, take } from 'rxjs';
import { ConfirmDialogComponent } from './confirm.component';

@Injectable()
export class ConfirmService {
  constructor(private readonly matDialog: MatDialog) {}

  openConfirmationModal(opts?: DialogModel): Observable<boolean> {
    opts = {
      title: 'Confirmation',
      message: 'Are you sure?',
      maxWidth: '600px',
      ...opts,
    };

    return this.matDialog
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        data: {
          title: opts.title,
          message: opts.message,
          maxWidth: opts.maxWidth,
        },
      })
      .afterClosed()
      .pipe(take(1));
  }
}
