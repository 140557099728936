<div class="ethan-title">
  Ask Ethan&#8482;
  <div>
    <mat-icon class="pointer" (click)="openFullScreen()">open_in_new</mat-icon>
    <mat-icon class="pointer" (click)="closeEthan()">close</mat-icon>
  </div>
</div>

<div class="flex overflow-y-auto flex-col-reverse chat-content">
  <div class="flex flex-col flex-auto shrink p-6 bg-card dark:bg-transparent">
    <ng-container *ngFor="let ethanQuestion of ethanQuestions; let i = index; let last = last">
      <div class="my-0.5 text-sm font-medium text-secondary mr-3 flex" style="justify-content: center">
        <div>{{ ethanQuestion.updated_at | date: 'MMM dd, h:mm a' }}</div>
      </div>
      <div class="flex flex-col items-end mt-3">
        <div class="relative max-w-3/4 px-3 py-2 rounded-lg bg-blue-500 text-blue-50">
          <div class="min-w-4 leading-5">{{ ethanQuestion.question }}</div>
        </div>
      </div>
      <div *ngIf="ethanQuestion.answer; else noAnswer" class="flex flex-col items-start mt-2">
        <div class="relative max-w-3/4 px-3 py-2 rounded-lg bg-gray-300">
          <div class="absolute bottom-0 w-3 text-orange-500 -right-1 -mr-px"></div>
          <markdown class="min-w-4 leading-5" [data]="ethanQuestion.answer"></markdown>
        </div>
        <div *ngIf="!last || !isLoading" class="text-secondary text-xs">
          <a
            (click)="voteEthanAnswer(ethanQuestion, 1)"
            [matTooltip]="'If you felt the answer was correct.'"
            class="pointer"
            ><mat-icon [svgIcon]="'thumb_up'" [ngClass]="{ 'text-blue-500': ethanQuestion?.rating == 1 }"></mat-icon
          ></a>
          <a
            (click)="voteEthanAnswer(ethanQuestion, -1)"
            [matTooltip]="'If you felt the answer was wrong or could have been better.'"
            class="pointer"
            ><mat-icon [svgIcon]="'thumb_down'" [ngClass]="{ 'text-blue-500': ethanQuestion?.rating == -1 }"></mat-icon
          ></a>
        </div>
      </div>
      <ng-template #noAnswer>
        <div *ngIf="!last || !isLoading" class="flex flex-col items-start mt-2">
          <div class="relative max-w-3/4 px-3 py-2 rounded-lg bg-gray-300">
            <div class="absolute bottom-0 w-3 text-orange-500 -right-1 -mr-px"></div>
            <markdown class="min-w-4 leading-5">Sorry, unable to fetch answer</markdown>
          </div>
          <div class="text-secondary text-xs">
            <a
              (click)="voteEthanAnswer(ethanQuestion, 1)"
              [matTooltip]="'If you felt the answer was correct.'"
              class="pointer"
              ><mat-icon [svgIcon]="'thumb_up'" [ngClass]="{ 'text-blue-500': ethanQuestion?.rating == 1 }"></mat-icon
            ></a>
            <a
              (click)="voteEthanAnswer(ethanQuestion, -1)"
              [matTooltip]="'If you felt the answer was wrong or could have been better.'"
              class="pointer"
              ><mat-icon
                [svgIcon]="'thumb_down'"
                [ngClass]="{ 'text-blue-500': ethanQuestion?.rating == -1 }"
              ></mat-icon
            ></a>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <div *ngIf="isLoading" class="mt-1">
      <mat-progress-spinner [diameter]="16" [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
  </div>
</div>

<div class="flex p-1 border-t bg-gray-50 dark:bg-transparent">
  <div class="input-area">
    <form [formGroup]="ethanForm">
      <div
        class="form-control-wrapper form-control-with-filter-inline-button has-icon flex justify-content-between ethan-question"
      >
        <input
          type="text"
          autosize
          placeholder="Ask a question of your data..."
          [formControlName]="'question'"
          rows="1"
          class="form-control w-full"
          (keyup.enter)="askQuestionToEthan()"
          [disabled]="isLoading"
        />
        <button
          *ngIf="!isLoading"
          mat-icon-button
          matSuffix
          (click)="askQuestionToEthan()"
          type="button"
          title="Submit"
          [disabled]="isLoading || !ethanForm.value.question"
        >
          <mat-icon [svgIcon]="'send'"></mat-icon>
        </button>
        <button *ngIf="isLoading" mat-icon-button matSuffix (click)="stopReading()" type="button" title="Stop">
          <mat-icon [svgIcon]="'stop'"></mat-icon>
        </button>
      </div>
    </form>
  </div>
</div>
