import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ts-divider',
  template: '<div class="border-b border-black-200" [ngClass]="dividerStyle"></div>',
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  imports: [CommonModule],
  standalone: true,
})
export class TsDividerComponent {
  @Input() spacing: 'none' | 'sm' | 'md' | 'lg' = 'md';

  get dividerStyle() {
    switch (this.spacing) {
      case 'sm':
        return 'my-2';
      case 'md':
        return 'my-4';
      case 'lg':
        return 'my-6';
      default:
        return 'my-0';
    }
  }
}
