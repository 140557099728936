import { Pipe, PipeTransform } from '@angular/core';

import formatNumber from 'accounting-js/lib/formatNumber.js';
import { FieldDef } from 'app/core/models';

@Pipe({ name: 'formatNumber' })
export class FormatNumberPipe implements PipeTransform {
  /**
   * fieldDef object is being used here to remove formatting for all 'id' field values.
   * @param value field value (as string)
   * @param precision integer number from fieldDef.meta.scale (default 2)
   * @param fieldDef field definition
   */
  transform(value: string, precision = 2, fieldDef?: FieldDef): any {
    if (value) {
      if (
        (typeof value === 'string' && value.startsWith('=')) ||
        (fieldDef?.name.toLowerCase() === 'id' && (typeof value === 'number' || typeof value === 'string'))
      )
        return value;
      else {
        const newValue = value
          ?.toString()
          ?.replace(/[$,]/gi, '')
          ?.replace(/^\.|0\.$/, '0.');

        const digitsBeforeDecimal = newValue?.split('.')[0]?.length;

        if (
          !Number.isInteger(+newValue) &&
          newValue?.split('.')?.pop()?.length > 1 &&
          digitsBeforeDecimal &&
          digitsBeforeDecimal <= 4
        ) {
          return formatNumber(+newValue, {
            precision: precision,
          });
        }
        if (!isNaN(+newValue)) {
          return formatNumber(+newValue, {
            precision: precision,
          });
        }
        return ''; // return empty for any non-digit
      }
    }
    return value;
  }
}
