import { FieldDef } from 'app/core/models';
import { RelatedToType } from '../enums';
import { FieldDefGroup } from 'tsui';

interface FieldDefWithGroup extends FieldDef {
  group: string;
  nestedFields: FieldDef[];
}

/**
 * @param fields Array<FieldDef>
 * @param fieldType RelatedToType
 * @param includeNestedFields Boolean
 * @returns a filtered array from the original fieldDefs array considering only fields related to type Projects and non-hidden.
 */
export function getAllowedFields(
  fields: Array<FieldDef>,
  fieldType: RelatedToType,
  includeNestedFields = false,
  excludeFileType = false,
): Array<FieldDef> {
  let allowedFields = fields.filter((fieldDef) => fieldDef.related_to_type === fieldType && !fieldDef.hidden);

  if (includeNestedFields) {
    allowedFields = allowedFields.filter(
      (fieldDef) =>
        fieldDef.meta?.exclude_in_nested_fields === false || fieldDef.meta?.exclude_in_nested_fields === undefined,
    );

    let linkedFields = allowedFields.filter(
      (fieldDef) => fieldDef.type === 'record' || fieldDef.name.startsWith('cf_linked_'),
    );

    for (const linkedField of linkedFields) {
      const allLinkedFieldDefs = fields
        .filter((fieldDef) => {
          const relatedTo =
            linkedField.meta?.record_type?.toLowerCase() || linkedField.name.replace('cf_linked_', '').toLowerCase();
          return relatedTo === fieldDef.related_to_type.toLowerCase() && !fieldDef.hidden;
        })
        .map((fieldDef) => {
          const propertName = fieldDef.name.replace('cf_linked_', '').toLowerCase();
          return {
            ...fieldDef,
            name: `${linkedField.name}.${propertName}`,
            meta: {
              ...fieldDef.meta,
              sorting: `${linkedField.name}.${propertName}`,
            },
          };
        });
      allowedFields = allowedFields.concat(allLinkedFieldDefs);
    }
  }

  if (excludeFileType) {
    return allowedFields.filter((fieldDef) => fieldDef.meta?.record_type !== RelatedToType.FileResource);
  }

  return allowedFields;
}

/**
 * @param fields Array<FieldDef>
 * @param fieldType string
 * @returns a filtered array from the original fieldDefs array considering only fields related to type Projects and non-hidden.
 */
export function getAllowedFieldsByString(fields: Array<FieldDef>, fieldType: string): Array<FieldDef> {
  return fields.filter((fieldDef) => fieldDef.related_to_type === fieldType && !fieldDef.hidden);
}

/**
 * @param definitionFields table definition
 * @param fieldDefs Array<FieldDef>
 * @returns a filtered array from the original table definition array response, with hidden fiields filtered out by name.
 */
export function removeHiddenFieldsFromTableDefinition(
  definitionFields: Array<string>,
  fieldDefs: Array<FieldDef>,
): Array<string> {
  return definitionFields.filter((field) => fieldDefs.find((fieldDef) => fieldDef.name === field));
}

export function moveRelatedToNestedFieldsToParent(fields: FieldDef[]): FieldDef[] {
  return fields
    .map((field: any) => {
      const newField = { ...field };
      let startLinkName = '';
      if (newField.name.includes('cf_linked_')) {
        startLinkName = `${newField.name.split('.')[0]}.`;
      } else {
        startLinkName = `${field.name}.`;
      }
      newField.nestedFields = fields
        .filter((childField) => childField.name.startsWith(startLinkName))
        .sort((a, b) => {
          if (a.name.includes('created_at') || a.name.includes('updated_at')) return 1;
          if (b.name.includes('created_at') || b.name.includes('updated_at')) return -1;
          return a.name.localeCompare(b.name);
        });

      return newField;
    })
    .filter((field) => !field.name.includes('.'));
  // remove nested fields (cf_field.cf_name) and keep links
}

export function orderNestedFields(arr: FieldDef[]): FieldDef[] {
  const nestedRelatedToFields: FieldDefWithGroup[] = [];
  const nestedLinkedFields: FieldDefWithGroup[] = [];
  const objectFields: FieldDefWithGroup[] = [];

  arr.forEach((obj: FieldDefWithGroup) => {
    const name = obj.name;
    if (name.includes('_linked_')) {
      nestedLinkedFields.push(obj);
    } else if (obj.type === 'record' || (name.includes('.') && !name.includes('_linked_'))) {
      nestedRelatedToFields.push({ ...obj, group: obj.name });
    } else {
      objectFields.push(obj);
    }
  });

  nestedRelatedToFields.sort((a, b) => a.name.localeCompare(b.name));
  nestedLinkedFields.sort((a, b) => a.name.localeCompare(b.name));
  objectFields.sort((a, b) => a.name.localeCompare(b.name));
  const response = [...objectFields, ...nestedRelatedToFields, ...nestedLinkedFields];
  return response;
}

/**
 *
 * @param relatedField
 * @param rootField
 * @returns a formatted related field to nested field providing the root field name and the related field name splitted by a dot.
 * @example formatRelatedFieldToNested({name: 'field1', meta: {sorting: 'field1'}}, {name: 'rootField'}) => {name: 'rootField.field1', meta: {sorting: 'rootField.field1'}}
 */
export function formatRelatedFieldToNestedField(relatedField: FieldDef, rootField: FieldDef): FieldDef {
  return {
    ...relatedField,
    name: `${rootField.name}.${relatedField.name}`,
    meta: {
      ...relatedField.meta,
      sorting: `${rootField.name}.${relatedField.name}`,
      group: createFieldDefGroupMetadata(rootField.name, rootField.label),
    },
    _parentFieldName: rootField.name,
    _parentFieldLabel: rootField.label,
    _parentFieldMeta: rootField.meta,
  };
}

export function createFieldDefGroupMetadata(parentFieldName: string, parentFieldLabel: string): FieldDefGroup {
  return { key: parentFieldName, label: parentFieldLabel };
}
