import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TreoAutogrowModule } from 'tsui/@treo';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import {
  MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY as MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
  MatLegacyAutocompleteModule as MatAutocompleteModule,
} from '@angular/material/legacy-autocomplete';
import { Overlay } from '@angular/cdk/overlay';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FormFieldDirective } from './form-field.directive';
import { FormFieldComponent } from './form-field.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { IConfig, NgxMaskPipe, NgxMaskDirective, provideNgxMask } from 'ngx-mask';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

import { TagsFormFieldComponent } from './tags-form-field/tags-form-field.component';
import { TextFormFieldComponent } from './text-form-field/text-form-field.component';
import { TextAreaFormFieldComponent } from './textarea-form-field/textarea-form-field.component';
import { SelectFormFieldComponent } from './select-form-field/select-form-field.component';
import { MoneyFormFieldComponent } from './money-form-field/money-form-field.component';
import { NumberFormFieldComponent } from './number-form-field/number-form-field.component';
import { DateFormFieldComponent } from './date-form-field/date-form-field.component';
import { DateTimeFormFieldComponent } from './datetime-form-field/datetime-form-field.component';
import { PercentFormFieldComponent } from './percent-form-field/percent-form-field.component';
import { TypeaheadFormFieldComponent } from './typeahead-form-field/typeahead-form-field.component';
import { DecimalFormFieldComponent } from './decimal-form-field/decimal-form-field.component';
import { YearFormFieldComponent } from './year-form-field/year-form-field.component';
import { CheckboxFormFieldComponent } from './checkbox-form-field/checkbox-form-field.component';
import { UrlFormFieldComponent } from './url-form-field/url-form-field.component';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { WidgetsModule } from 'app/shared/components/widgets/widgets.module';
import { ContactFormFieldComponent } from './contact-form-field/contact-form-field.component';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from 'app/shared/directives/directives.module';

@NgModule({
  declarations: [
    FormFieldComponent,
    FormFieldDirective,
    TagsFormFieldComponent,
    TextFormFieldComponent,
    TextAreaFormFieldComponent,
    SelectFormFieldComponent,
    MoneyFormFieldComponent,
    NumberFormFieldComponent,
    DateFormFieldComponent,
    DateTimeFormFieldComponent,
    PercentFormFieldComponent,
    DecimalFormFieldComponent,
    YearFormFieldComponent,
    ContactFormFieldComponent,
    CheckboxFormFieldComponent,
    TypeaheadFormFieldComponent,
    UrlFormFieldComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatMomentDateModule,
    MatProgressBarModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    TreoAutogrowModule,
    PipesModule,
    DirectivesModule,
    WidgetsModule,
    CurrencyMaskModule,
    MatTooltipModule,
    DirectivesModule,
    NgxMaskPipe,
    NgxMaskDirective,
  ],
  exports: [FormFieldComponent],
  providers: [
    provideNgxMask(),
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => {
        return () => overlay.scrollStrategies.block();
      },
      deps: [Overlay],
    },
  ],
})
export class FormFieldsModule {}
