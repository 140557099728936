import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { TreoFindByKeyPipeModule } from 'tsui/@treo';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TreoAutogrowModule } from 'tsui/@treo';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import {
  MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY as MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
  MatLegacyAutocompleteModule as MatAutocompleteModule,
} from '@angular/material/legacy-autocomplete';
import { Overlay } from '@angular/cdk/overlay';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { InlineFieldDirective } from './inline-field.directive';
import { InlineFieldComponent } from './inline-field.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

import { TextInlineFieldComponent } from './text-inline-field/text-inline-field.component';
import { DateInlineFieldComponent } from './date-inline-field/date-inline-field.component';
import { DateTimeInlineFieldComponent } from './datetime-inline-field/datetime-inline-field.component';
import { DecimalInlineFieldComponent } from './decimal-inline-field/decimal-inline-field.component';
import { NumberInlineFieldComponent } from './number-inline-field/number-inline-field.component';
import { MoneyInlineFieldComponent } from './money-inline-field/money-inline-field.component';
import { PercentInlineFieldComponent } from './percent-inline-field/percent-inline-field.component';
import { TagsInlineFieldComponent } from './tags-inline-field/tags-inline-field.component';
import { TextAreaInlineFieldComponent } from './textarea-inline-field/textarea-inline-field.component';
import { TypeaheadInlineFieldComponent } from './typeahead-inline-field/typeahead-inline-field.component';
import { SelectInlineFieldComponent } from './select-inline-field/select-inline-field.component';
import { YearInlineFieldComponent } from './year-inline-field/year-inline-field.component';
import { CheckboxInlineFieldComponent } from './checkbox-inline-field/checkbox-inline-field.component';
import { UrlInlineFieldComponent } from './url-inline-field/url-inline-field.component';
import { RecordInlineFieldComponent } from './record-inline-field/record-inline-field.component';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { WidgetsModule } from 'app/shared/components/widgets/widgets.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    InlineFieldDirective,
    InlineFieldComponent,
    TextInlineFieldComponent,
    DateInlineFieldComponent,
    DateTimeInlineFieldComponent,
    DecimalInlineFieldComponent,
    NumberInlineFieldComponent,
    MoneyInlineFieldComponent,
    PercentInlineFieldComponent,
    TagsInlineFieldComponent,
    TextAreaInlineFieldComponent,
    TypeaheadInlineFieldComponent,
    SelectInlineFieldComponent,
    YearInlineFieldComponent,
    CheckboxInlineFieldComponent,
    UrlInlineFieldComponent,
    RecordInlineFieldComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    TreoFindByKeyPipeModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatMomentDateModule,
    MatProgressBarModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    TreoAutogrowModule,
    PipesModule,
    WidgetsModule,
    CurrencyMaskModule,
    MatTooltipModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [InlineFieldComponent],
  providers: [
    provideNgxMask(),
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => {
        return () => overlay.scrollStrategies.block();
      },
      deps: [Overlay],
    },
  ],
})
export class InlineFieldsModule {}
