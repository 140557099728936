<div class="treo-horizontal-navigation-wrapper">
  <ng-container *ngFor="let item of navigation">
    <!-- Skip the hidden items -->
    <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
      <!-- Basic -->
      <treo-horizontal-navigation-basic-item
        class="treo-horizontal-navigation-menu-item"
        *ngIf="item.type === 'basic'"
        [item]="item"
        [name]="name"
      ></treo-horizontal-navigation-basic-item>

      <!-- Branch: aside, collapsable, group -->
      <treo-horizontal-navigation-branch-item
        class="treo-horizontal-navigation-menu-item"
        *ngIf="item.type === 'aside' || item.type === 'collapsable' || item.type === 'group'"
        [item]="item"
        [name]="name"
      ></treo-horizontal-navigation-branch-item>

      <!-- Spacer -->
      <treo-horizontal-navigation-spacer-item
        class="treo-horizontal-navigation-menu-item"
        *ngIf="item.type === 'spacer'"
        [item]="item"
        [name]="name"
      ></treo-horizontal-navigation-spacer-item>
    </ng-container>
  </ng-container>
</div>
