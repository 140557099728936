<mat-form-field class="flex-auto gt-xs:pr-3 w-full" (click)="edit()" (focus)="edit()">
  <mat-label>
    <span
      class="block text-ellipsis overflow-hidden pointer-events-auto"
      [matTooltip]="fieldDef.label"
      tooltipIfEllipsis
    >
      {{ fieldDef.label }}
    </span>
    <i class="fa fa-info-circle pointer-events-auto" [matTooltip]="showTip" *ngIf="showTip"> </i
  ></mat-label>
  <div class="w-full">
    <input
      #inputControl
      matInput
      [placeholder]="fieldDef.label"
      [ngModel]="editing ? value : (value | formatNumber: precision)"
      type="text"
      (ngModelChange)="value = $event"
      (keyup.enter)="onBlur($event)"
      (blur)="onBlur($event)"
      (focus)="edit()"
      [disabled]="isDisabled"
    />
  </div>
</mat-form-field>
