import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ts-icon',
  template: ` <mat-icon
    class="ts-icon"
    [svgIcon]="svgIcon"
    [fontIcon]="fontIcon"
    [inline]="true"
    [ngClass]="{
      'ts-icon-black': color === 'black',
      'ts-icon-red': color === 'red',
      'ts-icon-gray': color === 'gray',
      'ts-icon-light-gray': color === 'light-gray',
      'ts-icon-orange': color === 'orange',
      'material-icons-outlined': !!outlined
    }"
  ></mat-icon>`,
  host: { class: 'ts-icon' },
  styleUrls: ['./ts-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TSIconComponent {
  @Input() set icon(icon: string) {
    if (icon.includes('ts_') || icon.includes('.svg')) {
      this.svgIcon = icon;
      this.fontIcon = undefined;
    } else {
      this.fontIcon = icon;
      this.svgIcon = undefined;
    }
  }

  @Input() color: 'red' | 'black' | 'gray' | 'light-gray' | 'orange';
  @Input() outlined: boolean;
  @Input() tooltip: string;

  svgIcon: string | undefined = undefined;
  fontIcon: string | undefined = undefined;
}
