import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TSIconModule } from '../ts-icon';
import { CommonModule } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

export type TsPageSidenavConfig = {
  title: string;
  actions?: TsPageSidenavAction[];
};

export type TsPageSidenavAction = {
  icon?: string;
  description?: string;
  actionFn: () => void;
};

@Component({
  selector: 'ts-page',
  templateUrl: './ts-page.component.html',
  styleUrls: [`./ts-page.component.scss`],
  standalone: true,
  imports: [CommonModule, TSIconModule, MatTooltipModule],
})
export class TsPageComponent implements OnInit {
  @Input() sidenavConfig: TsPageSidenavConfig;

  readonly sidebarMode = new BehaviorSubject<'expanded' | 'collapsed'>('expanded');

  constructor() {}

  ngOnInit(): void {}

  toggleSidebarMode() {
    this.sidebarMode.next(this.sidebarMode.value === 'expanded' ? 'collapsed' : 'expanded');
  }
}
