import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { StateBase } from 'app/state/state-base';
import { GetNoteByIdResponse, GetNotesResponse, Note, NotesPaginationRequest } from '../notes.types';
import { NotesActions } from './actions';
import { NotesService } from '../notes.service';

export interface NotesStateModel {
  notes: Note[];
  selectedNote: Note;
  notesCount: number;
  page: number;
  noteableType: string;
  noteableId: number;
}

@State<NotesStateModel>({
  name: new StateToken<NotesStateModel>('notesState'),
  defaults: {
    notes: [],
    selectedNote: {},
    notesCount: 0,
    page: 0,
    noteableType: null,
    noteableId: null,
  },
})
@Injectable()
export class NotesState extends StateBase {
  constructor(protected readonly store: Store, protected readonly notesService: NotesService) {
    super(store);
  }

  @Selector()
  static getNotesList(state: NotesStateModel): Note[] {
    return state.notes;
  }

  @Selector()
  static getSelectedNote(state: NotesStateModel): Note {
    return state.selectedNote;
  }

  @Selector()
  static getNoteableType(state: NotesStateModel): string {
    return state.noteableType;
  }

  @Selector()
  static getNoteableId(state: NotesStateModel): number {
    return state.noteableId;
  }

  @Selector()
  static getNotesPagination(state: NotesStateModel) {
    return { page: state.page, notesCount: state.notesCount };
  }

  @Action(NotesActions.GetNotes)
  getNotes(
    context: StateContext<NotesStateModel>,
    { filter, keepSelected }: { filter: NotesPaginationRequest; keepSelected: boolean },
  ): Observable<GetNotesResponse> {
    return this.notesService.getFilteredNotes(filter.noteableType, filter.noteableId, filter.perPage, filter.page).pipe(
      tap((response) => {
        const state = context.getState();
        const noteToSelect = keepSelected
          ? state.selectedNote
          : response?.notes && response?.notes.length > 0
          ? response.notes[0]
          : {};
        context.setState({
          ...state,
          notes: response?.notes || [],
          notesCount: response?.count || 0,
          page: filter.page,
          noteableId: filter.noteableId,
          noteableType: filter.noteableType,
          selectedNote: noteToSelect,
        });
      }),
    );
  }

  @Action(NotesActions.SelectNote)
  selectNoteById(context: StateContext<NotesStateModel>, { note }: { note: Note }): void {
    const state = context.getState();
    context.setState({
      ...state,
      selectedNote: note,
    });
  }

  @Action(NotesActions.GetNoteById)
  getNoteById(context: StateContext<NotesStateModel>, { noteId }: { noteId: number }): Observable<GetNoteByIdResponse> {
    return this.notesService.getNoteById(noteId).pipe(
      tap((response) => {
        const state = context.getState();
        context.setState({
          ...state,
          selectedNote: response.note,
        });
      }),
    );
  }

  @Action(NotesActions.CreateNote)
  createNote(context: StateContext<NotesStateModel>): Observable<Note> {
    const note: Note = {
      title: 'New Note',
      noteable_id: this.store.selectSnapshot(NotesState.getNoteableId),
      noteable_type: this.store.selectSnapshot(NotesState.getNoteableType),
    };
    return this.notesService.createNote(note);
  }

  @Action(NotesActions.DeleteNote)
  deleteNoteById(context: StateContext<NotesStateModel>, { noteId }: { noteId: number }): Observable<void> {
    return this.notesService.deleteNote(noteId);
  }

  @Action(NotesActions.UpdateNote)
  updateNote(context: StateContext<NotesStateModel>, { note }: { note: Note }): Observable<Note> {
    return this.notesService.updateNote(note);
  }
}
