import { GraphqlParams } from '../../../shared/models/graphql';

export namespace CompanyActions {
  export class GetCompanies {
    static readonly type = '[Company] Get Companies';

    constructor(public params: GraphqlParams) {}
  }

  export class ExportCompanies {
    static readonly type = '[Company] Export Companies';

    constructor(public params: GraphqlParams, public savedViewId: string) {}
  }
}
