import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';

@Pipe({
  name: 'isFormControl',
})
export class IsFormControlPipe implements PipeTransform {
  transform(value: any): boolean {
    return value instanceof FormControl;
  }
}
