import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { getDrillFilters } from 'app/modules/reporting/reporting.functions';
import { ReportingService } from 'app/modules/reporting/reporting.service';
import { DrillFilter, ReportLayoutDefinition } from 'app/modules/reporting/reporting.types';
import { ReportLayout, ReportType } from 'app/modules/reports/reports.types';
import { StateBase } from 'app/state/state-base';
import { forkJoin, map, of, switchMap, tap, zip } from 'rxjs';
import { DashboardService } from '../dashboard.service';
import { Dashboard } from '../dashboard.types';
import { DuplicateDashboard, GetDashboardById, UpdateDashboard } from './actions';
import { FieldDefsState } from 'app/state/fields/state';

export interface DashboardStateModel {
  selectedDashboard: Dashboard;
}

@State<DashboardStateModel>({
  name: new StateToken<DashboardStateModel>('dashboardState'),
  defaults: { selectedDashboard: null },
})
@Injectable()
export class DashboardState extends StateBase {
  constructor(
    protected readonly store: Store,
    private readonly dashboardService: DashboardService,
    private readonly reportingService: ReportingService,
  ) {
    super(store);
  }

  @Selector()
  static getDashboardById(state: DashboardStateModel): Dashboard {
    return state.selectedDashboard;
  }

  @Action(GetDashboardById)
  getDashboardById(context: StateContext<DashboardStateModel>, { dashboardId }: { dashboardId: number }) {
    let definition: { report_ids: number[][] };

    return this.dashboardService.getDashboardById(dashboardId, false).pipe(
      switchMap((dashboard: Dashboard) => {
        definition = JSON.parse(dashboard.definition);
        const reportIdsFlattened = definition.report_ids.reduce((p, c) => [...p, ...c], []);

        if (!reportIdsFlattened.length) {
          return zip(of(dashboard));
        }

        return zip(
          of(dashboard),
          forkJoin(
            reportIdsFlattened.map((reportId: number) =>
              this.reportingService.getReportById(reportId, Number(this.account.id)).pipe(
                map((reportLayout: ReportLayout<string>) => {
                  const definition = JSON.parse(reportLayout.definition) as ReportLayoutDefinition;
                  const [chart] = definition.visualizations;
                  return {
                    reportLayout,
                    chart,
                  };
                }),
              ),
            ),
          ),
        );
      }),
      switchMap(([dashboard, reportLayouts]) => {
        if (!reportLayouts) {
          return zip(of(dashboard));
        }

        return zip(
          of(dashboard),
          forkJoin(
            reportLayouts.map(({ reportLayout, chart }) =>
              zip(
                of(reportLayout),
                this.reportingService.buildChartData(reportLayout.id, [chart]).pipe(map(([[chart]]) => chart)),
              ),
            ),
          ),
        );
      }),
      tap(([dashboard, reportLayouts]) => {
        const state = context.getState();
        context.setState({
          ...state,
          selectedDashboard: {
            ...dashboard,
            charts: definition.report_ids.map((reportIds) =>
              reportIds
                .map((reportId) => {
                  const [reportLayout, chart] = reportLayouts.find(([reportLayout]) => reportLayout.id === reportId);

                  if (!chart) {
                    return null;
                  }

                  const reportLayoutDefinition = JSON.parse(reportLayout.definition) as ReportLayoutDefinition;
                  const isTimeSeries = reportLayout.report_type === ReportType.TimeSeries;
                  const hasVisualization = reportLayoutDefinition.visualizations?.length > 0;
                  const hasVisualizationGroupBySet = hasVisualization
                    ? !!reportLayoutDefinition.visualizations[0].series_value
                    : false;

                  let drillFilters = {} as DrillFilter;

                  if (!isTimeSeries) {
                    const fieldDefs = this.store
                      .selectSnapshot(FieldDefsState.getFieldDefs)
                      .filter(({ related_to_type }) => related_to_type === reportLayoutDefinition.related_to_type);
                    drillFilters = getDrillFilters(chart, fieldDefs, hasVisualizationGroupBySet);
                  }

                  return {
                    ...chart,
                    reportLayout: { ...reportLayout, definition: reportLayoutDefinition },
                    drillFilters,
                    isTimeSeries,
                  };
                })
                .filter((chart) => chart),
            ),
          },
        });
      }),
    );
  }

  @Action(UpdateDashboard)
  updateDashboard(context: StateContext<DashboardStateModel>, { dashboard }: { dashboard: Dashboard }) {
    return this.dashboardService.updateDashboard(dashboard, false).pipe(
      tap((dashboard: Dashboard) => {
        this.store.dispatch(new GetDashboardById(dashboard.id));
      }),
    );
  }

  @Action(DuplicateDashboard)
  duplicateReport(context: StateContext<DashboardStateModel>, { dashboardId }: { dashboardId: number }) {
    return this.dashboardService.getDashboardById(dashboardId).pipe(
      switchMap((originalDashboard: Dashboard) => {
        delete originalDashboard.id;
        delete originalDashboard.rank;
        originalDashboard.name = `${originalDashboard.name} - (Copy)`;
        return this.dashboardService.createDashboard(originalDashboard).pipe(
          tap((dashboard) => {
            const state = context.getState();
            const newState = {
              ...state,
              selectedDashboard: dashboard,
            };
            context.setState(newState);
          }),
        );
      }),
    );
  }
}
