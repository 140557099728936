import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, Observable, retry, throwError, timer } from 'rxjs';

import { BaseService } from './base.service';
import { ObjectGroup, ObjectGroupMember } from 'app/shared/models/object-group';

@Injectable({
  providedIn: 'root',
})
export class ObjectGroupService extends BaseService {
  constructor(protected readonly httpClient: HttpClient) {
    super(httpClient);
  }

  get(accountId: number, groupableType: string): Observable<ObjectGroup[]> {
    return this.httpClient
      .get<{ object_groups: ObjectGroup[] } | null>(
        `accounts/${accountId}/object_groups?groupable_type=${groupableType}`,
      )
      .pipe(
        retry({
          count: 3,
          delay: (error, retryCount) => {
            // For network errors (status 0), use a linear backoff starting at 1 second.
            if (error instanceof HttpErrorResponse && error.status === 0) {
              return timer(1000 * retryCount);
            }
            // For other errors, use an exponential backoff.
            return timer(Math.pow(2, retryCount) * 1000);
          },
        }),
        map((response) => {
          if (response && 'object_groups' in response) {
            return response.object_groups;
          }
          return [];
        }),
        catchError(this.handleError),
      );
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error);
  }

  create(accountId: number, objectGroup: Omit<ObjectGroup, 'id'>): Observable<ObjectGroup> {
    return this.httpClient
      .post<{ object_group: ObjectGroup }>(`accounts/${accountId}/object_groups`, { object_group: objectGroup })
      .pipe(map(({ object_group }) => object_group));
  }

  update(accountId: number, objectGroup: ObjectGroup): Observable<ObjectGroup> {
    return this.httpClient
      .put<{ object_group: ObjectGroup }>(`accounts/${accountId}/object_groups/${objectGroup.id}`, {
        object_group: objectGroup,
      })
      .pipe(map(({ object_group }) => object_group));
  }

  delete(accountId: number, objectGroupId: number): Observable<void> {
    return this.httpClient.delete<void>(`accounts/${accountId}/object_groups/${objectGroupId}`);
  }

  createMember(
    accountId: number,
    objectGroupId: number,
    objectGroupMember: ObjectGroupMember,
  ): Observable<ObjectGroupMember> {
    return this.httpClient
      .post<{ object_group_member: ObjectGroupMember }>(
        `accounts/${accountId}/object_groups/${objectGroupId}/members`,
        { object_group_member: objectGroupMember },
      )
      .pipe(map(({ object_group_member }) => object_group_member));
  }

  deleteMember(accountId: number, objectGroupId: number, objectGroupMemberId: number): Observable<void> {
    return this.httpClient.delete<void>(
      `accounts/${accountId}/object_groups/${objectGroupId}/members/${objectGroupMemberId}`,
    );
  }
}
