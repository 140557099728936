import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterListItemModel, FilterListItemValueModel, LookupContract } from '../cross-cutting/models';

@Component({
  selector: 'ts-filter-list',
  templateUrl: './ts-filter-list.component.html',
  styleUrls: ['./ts-filter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsFilterListComponent {
  @Input() fields: FilterListItemModel[];
  @Input() filters: FilterListItemValueModel[];
  @Input() size: 'medium' | 'large' = 'medium';
  @Input() lookupContract: LookupContract;
  @Input() hasGroups = true;
  @Output() addItem = new EventEmitter<void>();
  @Output() filterDefChanged = new EventEmitter<FilterListItemValueModel>();
  @Output() filterRemoved = new EventEmitter<number>();
  @Output() filterClared = new EventEmitter<number>();
  @Output() filterApplied = new EventEmitter<number>();
}
