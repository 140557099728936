import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { AccountService } from 'app/core';
import { FormatNumberPipe } from 'app/shared/pipes/format-number.pipe';
import { CurrencyMaskOptions, getCurrencyMask } from 'app/shared/Utils/common.utils';

@Component({
  selector: 'calculator-modal',
  templateUrl: './calculator-modal.component.html',
  styleUrls: ['./calculator-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalculatorModalComponent implements OnInit {
  public underwriting: any = {
    purchasePrice: 0,
    term: 20,
    noi: 0,
    capRate: 0,
    interestRate: 4.25,
    leverage: 80,
    equityRequired: 0,
    netCashFlow: 0,
    cashOnCash: 0,
    dscr: 0,
    debt: 0,
  };

  private get accountCurrency(): string {
    return this.accountService.getCurrentAccount()?.display_currency;
  }

  get currencyMaskOptions(): CurrencyMaskOptions {
    return getCurrencyMask(this.accountCurrency, 0);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly matDialogRef: MatDialogRef<CalculatorModalComponent>,
    private readonly formatNumber: FormatNumberPipe,
    private readonly accountService: AccountService,
  ) {}

  ngOnInit(): void {
    if (this.data && this.data.project) {
      if (this.data.project.bid_price && this.data.project.bid_price > 0) {
        this.underwriting.purchasePrice = this.data.project.bid_price;
      } else if (this.data.project.broker_guidance_price && this.data.project.broker_guidance_price > 0) {
        this.underwriting.purchasePrice = this.data.project.broker_guidance_price;
      }

      if (this.data.project.noi && this.data.project.noi > 0) {
        this.underwriting.noi = this.data.project.noi;
      } else if (this.data.project.going_in_noi && this.data.project.going_in_noi > 0) {
        this.underwriting.noi = this.data.project.going_in_noi;
      }
      this.doCalculate();
    }
  }

  public discard(): void {
    this.matDialogRef.close();
  }

  public onChange(): void {
    this.doCalculate();
  }

  public onChangeCapRate(): void {
    if (this.underwriting.capRate && this.underwriting.capRate > 0) {
      if (this.underwriting.noi && this.underwriting.noi > 0) {
        this.underwriting.purchasePrice = this.underwriting.noi / (this.underwriting.capRate / 100);
        this.doCalculate();
      } else if (this.underwriting.purchasePrice && this.underwriting.purchasePrice > 0) {
        this.underwriting.noi = this.underwriting.purchasePrice * (this.underwriting.capRate / 100);
        this.doCalculate();
      }
    }
  }

  private onChangePurchasePrice(): void {
    if (
      this.underwriting.capRate &&
      this.underwriting.capRate > 0 &&
      this.underwriting.noi &&
      this.underwriting.noi > 0
    ) {
      this.underwriting.purchasePrice = this.underwriting.noi / (this.underwriting.capRate / 100);
      this.doCalculate();
    }
  }

  private doCalculate(): void {
    if (this.underwriting.purchasePrice > 0) {
      this.underwriting.capRate = (this.underwriting.noi / this.underwriting.purchasePrice) * 100;
      this.underwriting.capRate = this.formatNumber.transform(this.underwriting.capRate, 2);

      this.underwriting.equityRequired =
        this.underwriting.purchasePrice - this.underwriting.purchasePrice * (this.underwriting.leverage / 100);
      this.underwriting.debt = this.underwriting.purchasePrice - this.underwriting.equityRequired;

      let payment = this.calculateLoanPayments();
      this.underwriting.netCashFlow = this.underwriting.noi - payment * 12;

      this.underwriting.cashOnCash = (this.underwriting.netCashFlow / this.underwriting.equityRequired) * 100;

      this.underwriting.dscr = this.underwriting.noi / (payment * 12);
    }
  }

  private calculateLoanPayments(): number {
    let interest = this.underwriting.interestRate / 100 / 1200;

    let monthlyInterest = this.underwriting.interestRate / 100 / 12;
    let totalTerm = this.underwriting.term * 12;

    let payment = (this.underwriting.debt * interest) / (1 - Math.pow(1 / (1 + interest), this.underwriting.term * 12));

    payment =
      (this.underwriting.debt * (monthlyInterest * Math.pow(1 + monthlyInterest, totalTerm))) /
      (Math.pow(1 + monthlyInterest, totalTerm) - 1);

    return payment;
  }
}
