import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';

import { ModernLayoutModule } from 'app/layout/layouts/horizontal/modern/modern.module';

import { SharingLayoutModule } from 'app/layout/layouts/sharing/sharing.module';
import { SharedModule } from 'app/shared/shared.module';
import { TreoDrawerModule } from 'tsui/@treo';

const modules = [
  // Empty
  EmptyLayoutModule,
  //Sharing
  SharingLayoutModule,

  // Horizontal navigation
  ModernLayoutModule,
];

@NgModule({
  declarations: [LayoutComponent],
  imports: [MatIconModule, RouterModule, TreoDrawerModule, SharedModule, ...modules],
  exports: [...modules],
})
export class LayoutModule {}
