import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CustomObjectsService } from '@shared/modules/custom-objects/services/custom-objects.service';
import { AccountState } from '@state/account/state';
import { CompaniesService } from 'app/modules/companies/companies.service';
import { ContactsService } from 'app/modules/contacts/contacts.service';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { PropertiesService } from 'app/modules/properties/properties.service';
import { ObjectsManagerState } from 'app/modules/settings/objects-manager/state/state';
import { RelatedToType } from 'app/shared/enums';
import { Dictionary } from 'app/shared/models';
import { Observable, of, map } from 'rxjs';
import { LookupContract, LookupFilter, LookupResult } from 'tsui';

@Injectable({ providedIn: 'root' })
export class LookupService implements LookupContract {
  private lookupDictionary: Dictionary<(query: string, customObjectName?: string) => Observable<any[]>>;

  constructor(
    private readonly store: Store,
    private readonly projectsService: ProjectsService,
    private readonly propertiesService: PropertiesService,
    private readonly companiesService: CompaniesService,
    private readonly contactsService: ContactsService,
    private readonly customObjectsService: CustomObjectsService,
  ) {
    this.lookupDictionary = {
      [RelatedToType.Project]: (query: string) => {
        return this.projectsService
          .getProjectsNoPipeline(['id', 'name'], {}, 0, 20, 'name', 'asc', query, false, null, true)
          .pipe(map((response) => response.projects.map((x) => ({ id: x.id, label: x.name }))));
      },
      [RelatedToType.Property]: (query: string) => {
        return this.propertiesService
          .getProperties(['id', 'name', 'address_string'], {}, 0, 20, 'name', 'asc', query)
          .pipe(map((response) => response.properties.map((x) => ({ id: x.id, label: x.name }))));
      },
      [RelatedToType.Company]: (query: string) => {
        return this.companiesService
          .search({ fjson: '', q: query }, 0, 20)
          .pipe(map((response) => response.results.map((x) => ({ id: x.id, label: x.name }))));
      },
      [RelatedToType.Contact]: (query: string) => {
        return this.contactsService
          .search({ fjson: '', q: query }, 0, 20)
          .pipe(map((response) => response.results.map((x) => ({ id: x.id, label: x.name }))));
      },
      [RelatedToType.CustomObject]: (query: string, customObjectName: string) => {
        const customObjects = this.store.selectSnapshot(ObjectsManagerState.get);
        const customField = customObjects?.find(({ name }) => name === customObjectName);

        if (!customField) {
          return of([]);
        }

        const account = this.store.selectSnapshot(AccountState.getAccount);
        const searchField = customField.fields?.find((field) => field.id === customField.search_custom_field_id);

        return this.customObjectsService
          .getValues(+account.id, customField.id, undefined, undefined, 0, 20, 'name', 'asc', query)
          .pipe(map((response) => response.values.map((obj) => ({ id: obj.id, label: obj[searchField.name] }))));
      },
    };
  }

  lookFor(model: LookupFilter): Observable<LookupResult[]> {
    const lookupFn = this.lookupDictionary[model.relatedTo];

    if (!lookupFn) {
      return this.lookupDictionary.CustomObject(model.query, model.relatedTo);
    }

    return this.lookupDictionary[model.relatedTo](model.query);
  }
}
