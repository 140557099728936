import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { TreoMessageComponent } from './message.component';

@NgModule({
  declarations: [TreoMessageComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule],
  exports: [TreoMessageComponent],
})
export class TreoMessageModule {}
