import { Permission } from 'app/core/enums/permission';
import { User } from 'app/core/models';

export class SetPermissions {
  static readonly type = '[User] Set Permissions';

  constructor(public permissions: Permission[]) {}
}

export class SetUser {
  static readonly type = '[User] Set User';

  constructor(public user: User) {}
}

export class SetUsers {
  static readonly type = '[User] Set Users';

  constructor(public users: User[]) {}
}
