import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { FieldDefsState } from 'app/state/fields/state';

@Pipe({ name: 'fieldNameToLabel' })
export class FieldNameToLabelPipe implements PipeTransform {
  constructor(private readonly store: Store) {}

  transform(fieldName: string, relatedToType: string): unknown {
    const fields = this.store.selectSnapshot(FieldDefsState.getFieldDefs);
    const field = fields.find(({ name, related_to_type }) => name === fieldName && related_to_type === relatedToType);
    return field?.label ?? fieldName;
  }
}
