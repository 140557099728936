import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsPageHeaderComponent } from './ts-page-header.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TsPageHeaderComponent],
  exports: [TsPageHeaderComponent],
})
export class TsPageHeaderModule {}
