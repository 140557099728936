<div class="add-link-to-co-value">
  <div class="add-link-to-co-value__header">
    <div class="add-link-to-co-value__header__title">Add New Link</div>
    <button class="add-link-to-co-value__header__close" mat-icon-button (click)="close()" [tabIndex]="-1">
      <mat-icon [svgIcon]="'close'"></mat-icon>
    </button>
  </div>

  <div class="add-link-to-co-value__content">
    <form [formGroup]="addLinkToCOValueForm">
      <div class="add-link-to-co-value__content__fields">
        <mat-form-field>
          <mat-label>{{ customObjectType }}</mat-label>
          <input matInput [formControlName]="'name'" [matAutocomplete]="matAutocompleteCOValue" [spellcheck]="false" />
        </mat-form-field>
        <mat-autocomplete
          #matAutocompleteCOValue="matAutocomplete"
          [disableRipple]="true"
          (optionSelected)="autocompleteSelected($event)"
        >
          <mat-option *ngIf="customObjectOptions && !customObjectOptions.length"> No results found! </mat-option>
          <mat-option *ngFor="let option of customObjectOptions" [value]="option.name" [id]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </div>

      <div class="add-link-to-co-value__content__actions">
        <button mat-button type="button" (click)="close()">
          <span>Cancel</span>
        </button>

        <button
          class="ml-2"
          mat-flat-button
          [color]="'primary'"
          type="submit"
          [disabled]="isSaveBtnDisabled"
          (click)="saveAndClose()"
        >
          <span>Save</span>
        </button>
      </div>
    </form>
  </div>
</div>
