<div class="w-full flex flex-row justify-between items-center p-4 border-t border-gray-200">
  <div class="w-fit flex flex-row justify-start items-center gap-1">
    <button class="backward-button" [disabled]="activePage === 1" mat-button (click)="changeActivePage(activePage - 1)">
      <ts-icon icon="chevron_left"></ts-icon>
    </button>

    <ng-container *ngFor="let page of rowButtons">
      <ng-container *ngIf="page === '...'; else pageButton">
        <p class="ellipsis">{{ page }}</p>
      </ng-container>
      <ng-template #pageButton>
        <button [color]="page === activePage && 'primary'" mat-flat-button (click)="changeActivePage(page)">
          {{ page }}
        </button>
      </ng-template>
    </ng-container>

    <button
      class="forward-button"
      [disabled]="activePage === totalPages || totalPages === 0"
      mat-button
      (click)="changeActivePage(activePage + 1)"
    >
      <ts-icon icon="chevron_right"></ts-icon>
    </button>
  </div>

  <div class="flex flex-row justify-start items-center gap-2 items-per-page">
    <p class="text-sm">Items per page</p>
    <mat-form-field appearance="fill">
      <mat-select [formControl]="pageSizeControl">
        <mat-option *ngFor="let itemsPerPageOption of pageSizeOptions" [value]="itemsPerPageOption"
          >{{ itemsPerPageOption }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
