import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { UserService } from 'app/layout/common/user/user.service';

import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { environment } from '@env/environment';
import { takeUntil } from 'rxjs/operators';
import { User } from 'app/core/models/user.types';
import { Subject } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import Pusher from 'pusher-js';
import { ChannelEvent, ChannelListener } from '../models/pusher';
import { Dictionary } from 'app/shared/models/dictionary';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  // this will be replaced by actual hash post-build.js
  private _unsubscribeAll: Subject<void>;
  private channelListeners: Dictionary<ChannelListener> = {};
  private pusher: Pusher = null;

  beamsClient: any;

  constructor(
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly platform: Platform,
  ) {
    const {
      production,
      pusher: {
        instanceId,
        channelApp: { cluster, key: channelKey },
      },
    } = environment;

    this._unsubscribeAll = new Subject();
    if (production && !this.platform.SAFARI) {
      try {
        this.beamsClient = new PusherPushNotifications.Client({ instanceId });
      } catch {
        console.warn('Pusher Beam is disabled in this browser version, update it or change to a newer browser');
      }
    }

    this.pusher = new Pusher(channelKey, { cluster });
  }

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */

  registerForPusher() {
    if (environment.production && !this.platform.SAFARI) {
      this.userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
        if (user) {
          /*beamsClient.start()
                      .then(() => beamsClient.addDeviceInterest('hello'))
                      .then(() => console.log('Successfully registered and subscribed!'))
                      .catch(console.error);*/

          const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
            url: `${environment.baseUrl}/${environment.apiVersion}/user/pusher_auth`,
            headers: {
              Authorization: 'Token ' + this.authService.accessToken, // Headers your auth endpoint needs
            },
          });
          this.beamsClient
            .start()
            .then(() => this.beamsClient.setUserId('termsheet-' + user.id.toString(), beamsTokenProvider))
            .catch(console.error);
        }
      });
    }
  }

  deRegisterFromPusher() {
    if (environment.production) {
      this.beamsClient.stop().catch(console.error);
    }
  }

  listenToChannel<T>(options: ChannelEvent): Subject<T> {
    const { channelName, eventName } = options;

    if (!this.channelListeners[channelName]) {
      this.channelListeners[channelName] = {
        channel: this.pusher.subscribe(channelName),
        listener: new Subject<any>(),
      };
    }

    const channelListener = this.channelListeners[channelName];

    channelListener.channel.unbind(eventName).bind(eventName, (payload: T) => channelListener.listener.next(payload));

    return channelListener.listener;
  }

  closeChannel(channelName: string): void {
    this.pusher.unsubscribe(channelName);
    delete this.channelListeners[channelName];
  }
}
