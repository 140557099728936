import { Pipe, PipeTransform } from '@angular/core';
import { RelatedToType } from 'app/shared/enums';
import { typeTitleMap } from '../models/type-title-map';
import { CustomObject } from '../modules/custom-objects/custom-objects.types';
import { Store } from '@ngxs/store';
import { ObjectsManagerState } from 'app/modules/settings/objects-manager/state/state';

const typeTitleMapKeys = Object.keys(typeTitleMap);

@Pipe({ name: 'relatedToTypeTitle' })
export class RelatedToTypeTitlePipe implements PipeTransform {
  constructor(private readonly store: Store) {}

  transform(value: RelatedToType | string, customObjects: Partial<CustomObject>[]): string {
    const normalizedValue = value.toLowerCase();

    const customObject = (
      customObjects?.length ? customObjects : this.store.selectSnapshot(ObjectsManagerState.get)
    ).find(({ name }) => name === normalizedValue);

    if (customObject) {
      return customObject.label;
    }

    for (const key of typeTitleMapKeys) {
      if (key.toLowerCase() === normalizedValue) {
        return typeTitleMap[key];
      }
    }

    return value;
  }
}
