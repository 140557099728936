import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsOperatorDisplayPipe } from './ts-operator-display/ts-operator-display.pipe';
import { TsFieldFromNamePipe } from './ts-field-from-name/ts-field-from-name.pipe';
import { TsGroupByPipe } from './ts-group-by/ts-group-by.pipe';
import { TsFilterByPipe } from './ts-filter-by/ts-filter-by.pipe';
import { TsMutateToAnyPipe } from './ts-mutate-to-any/ts-mutate-to-any.pipe';
import { TsIsObjectPipe } from './ts-is-object';

@NgModule({
  declarations: [
    TsOperatorDisplayPipe,
    TsFieldFromNamePipe,
    TsGroupByPipe,
    TsFilterByPipe,
    TsMutateToAnyPipe,
    TsIsObjectPipe,
  ],
  imports: [CommonModule],
  exports: [
    TsOperatorDisplayPipe,
    TsFieldFromNamePipe,
    TsGroupByPipe,
    TsFilterByPipe,
    TsMutateToAnyPipe,
    TsIsObjectPipe,
  ],
})
export class TsPipesModule {}
