import { GraphqlFilter, GraphqlParams, GraphqlResultPageInfo } from '@shared/models/graphql';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { StateBase } from '@state/state-base';
import { CompanyActions } from './actions';
import GraphqlService from '@services/graphql.service';
import { tap } from 'rxjs';
import { ExportOptions } from '@shared/models/export-options';
import { FileFormat } from '@shared/enums';
import { Company, CompanyStandardFieldsMap } from '../companies.types';
import { generateDownloadableFile } from '@shared/functions';
import { TsSnackbarService } from 'tsui';
import { ObjectsManagerState } from '../../settings/objects-manager/state/state';

export interface CompanyStateModel {
  companies: Company[];
  pageInfo: GraphqlResultPageInfo;
}

const initialPageInfoState: GraphqlResultPageInfo = {
  hasNextPage: false,
  hasPreviousPage: false,
  startCursor: '',
  endCursor: '',
  pageSize: 20,
  currentPage: 1,
  totalCount: 0,
  filteredCount: 0,
};

@State<CompanyStateModel>({
  name: new StateToken<CompanyStateModel>('companyState'),
  defaults: { companies: [], pageInfo: initialPageInfoState },
})
@Injectable()
export class CompanyState extends StateBase {
  constructor(
    private readonly graphqlService: GraphqlService,
    private readonly snackBar: TsSnackbarService,
    protected readonly store: Store,
  ) {
    super(store);
  }

  @Selector()
  static selectState(state: CompanyStateModel): CompanyStateModel {
    return state;
  }

  @Selector()
  static selectPagination(state: CompanyStateModel): GraphqlResultPageInfo {
    return state.pageInfo;
  }

  @Selector()
  static selectCompanies(state: CompanyStateModel): Company[] {
    return state.companies;
  }

  @Selector([CompanyState.selectCompanies])
  static selectCompaniesCount(_: CompanyStateModel, companies: Company[]): number {
    return companies.length;
  }

  @Action(CompanyActions.GetCompanies)
  getCompanies(context: StateContext<CompanyStateModel>, { params }: { params: GraphqlParams }) {
    const formattedParams = this.getCompanyGraphQlQueryParams(params);

    return this.graphqlService.query(Number(this.accountId), formattedParams).pipe(
      tap(({ edges, pageInfo }) => {
        const rows = edges.map((edge) => edge.node) as Company[];

        context.patchState({
          companies: rows,
          pageInfo,
        });
      }),
    );
  }

  @Action(CompanyActions.ExportCompanies)
  exportCompanies(
    context: StateContext<CompanyStateModel>,
    {
      params,
      savedViewId,
    }: {
      params: GraphqlParams;
      savedViewId: string;
    },
  ) {
    const formattedParams = this.getCompanyGraphQlQueryParams(params);
    const exportOptions: ExportOptions = {
      format: FileFormat.CSV,
      fileName: 'Companies',
      savedViewId: savedViewId,
    };

    return this.graphqlService.query(Number(this.accountId), formattedParams, exportOptions).pipe(
      tap((res: any) => {
        if (res?.message) {
          this.snackBar.open(res?.message, 'info');
        } else {
          generateDownloadableFile(res, FileFormat.CSV, `Companies`);
        }
      }),
    );
  }

  private getCompanyGraphQlQueryParams(params: GraphqlParams<GraphqlFilter>): GraphqlParams {
    const companyFieldDefs = this.store.selectSnapshot(
      ObjectsManagerState.getFieldsByObjectName('Company', {
        allowedFieldsOnly: true,
        addNestedFields: true,
      }),
    );
    return {
      ...params,
      fields: [CompanyStandardFieldsMap.id, ...params.fields],
      fieldDefs: companyFieldDefs,
    };
  }
}
