import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { User } from 'app/core/models';
import { SetPermissions, SetUser, SetUsers } from './actions';
import { Permission } from 'app/core/enums';

export interface UserStateModel {
  user: User;
  users: User[];
  permissions: Permission[];
}

@State<UserStateModel>({
  name: new StateToken<UserStateModel>('userState'),
  defaults: { user: null, users: null, permissions: null },
})
@Injectable()
export class UserState {
  @Selector()
  static getUser(state: UserStateModel): User {
    return state.user;
  }

  @Selector()
  static getUsers(state: UserStateModel): User[] {
    return state.users;
  }

  @Selector()
  static getPermissions(state: UserStateModel): Permission[] {
    return state.permissions;
  }

  @Action(SetPermissions)
  setPermissions(context: StateContext<UserStateModel>, { permissions }: { permissions: Permission[] }) {
    const state = context.getState();
    context.setState({ ...state, permissions });
  }

  @Action(SetUser)
  setUser(context: StateContext<UserStateModel>, { user }: { user: User }) {
    const state = context.getState();
    context.setState({ ...state, user });
  }

  @Action(SetUsers)
  setUsers(context: StateContext<UserStateModel>, { users }: { users: User[] }) {
    const state = context.getState();
    context.setState({ ...state, users });
  }
}
