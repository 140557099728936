import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { FieldDef } from 'app/core/models';
import { tap } from 'rxjs';
import { StateBase } from '../state-base';
import { Field } from './actions';

export type DropdownOptionNames =
  | 'project_stages'
  | 'property_type'
  | 'project_type'
  | 'user'
  | 'investment_type'
  | 'team'
  | 'vehicle'
  | 'savedFilter'
  | 'pipeline'
  | 'market_statuses'
  | 'project_templates'
  | 'loan_purposes'
  | 'categories'
  | 'project_phases'
  | 'call_outcomes'
  | 'meeting_outcomes'
  | 'engagement_activity_types'
  | 'social_media_sources'
  | 'workflow_states'
  | 'task_list_templates';

export interface FieldDefsStateModel {
  fieldDefs: FieldDef[];
  dropdownOptions: { [option_name in DropdownOptionNames]?: any[] };
}

@State<FieldDefsStateModel>({
  name: new StateToken('fieldDefsState'),
  defaults: {
    fieldDefs: null,
    dropdownOptions: {},
  },
})
@Injectable()
export class FieldDefsState extends StateBase {
  constructor(protected readonly store: Store) {
    super(store);
  }

  @Selector()
  static getFieldDefs(state: FieldDefsStateModel) {
    return state.fieldDefs;
  }

  @Selector()
  static getDropdownOptions(state: FieldDefsStateModel) {
    return state.dropdownOptions;
  }

  static selectDropdownOptionsBy(optionName: DropdownOptionNames, displayHidden = false) {
    return createSelector([FieldDefsState], (state: FieldDefsStateModel) =>
      state.dropdownOptions[optionName]?.filter((option) => !option?.hidden || displayHidden),
    );
  }

  @Action(Field.Get)
  getFieldDefs(context: StateContext<FieldDefsStateModel>) {
    return this.accountService.getFieldDefsByAccountId(this.account.id).pipe(
      tap((fieldDefs) => {
        context.setState({ ...context.getState(), fieldDefs });
      }),
    );
  }

  @Action(Field.Set)
  setFieldDefs(context: StateContext<FieldDefsStateModel>, { fieldDefs }: { fieldDefs: FieldDef[] }) {
    context.setState({ ...context.getState(), fieldDefs });
  }

  @Action(Field.SetDropdownOptions)
  setDropdownOptions(
    context: StateContext<FieldDefsStateModel>,
    {
      options,
    }: {
      options: { [option_name: string]: any[] };
    },
  ) {
    const currentOptions = context.getState().dropdownOptions;

    context.patchState({ dropdownOptions: { ...currentOptions, ...options } });
  }
}
