import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';

@Directive({
  selector: '[matTooltip][tooltipIfEllipsis]',
})
export class TooltipIfEllipsisDirective {
  @Input() isLineClamp = false;

  constructor(private matToolTip: MatTooltip, private elementRef: ElementRef<HTMLElement>) {}

  @HostListener('mouseenter', ['$event'])
  setTooltipState(): void {
    const element = this.elementRef.nativeElement;
    if (this.isLineClamp) {
      this.matToolTip.disabled = !(element.offsetHeight < element.scrollHeight);
    } else {
      this.matToolTip.disabled = element.scrollWidth <= element.clientWidth;
    }
  }
}
