import { SavedFilter } from 'app/core/models';

export namespace SavedFilters {
  export class Get {
    static readonly type = 'Get Saved Filters';

    constructor() {}
  }

  export class Set {
    static readonly type = ' Saved Filters';

    constructor(public filters: SavedFilter[]) {}
  }
}
