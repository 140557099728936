<div class="header">
  <div class="title">Deal {{ isStatus ? 'Status' : 'Phase' }} Change</div>
  <button class="close-button" mat-icon-button (click)="discard()" [tabIndex]="-1">
    <mat-icon [svgIcon]="'close'"></mat-icon>
  </button>
</div>
<div class="flex flex-wrap p-6 optional-view items-center">
  <div class="flex w-full px-3 mb-3 justify-center">
    The following fields are required in order to change the {{ isStatus ? 'status' : 'phase' }}.
  </div>
  <div class="flex flex-row w-1/2 px-6 pb-0" *ngFor="let column of displayColumns">
    <ng-container *ngIf="lookupField(column.name) as fieldDef">
      <app-form-field [fieldDef]="fieldDef" [model]="project" [data]="options" class="w-full"></app-form-field>
    </ng-container>
  </div>
  <div class="flex justify-end actions w-full px-3">
    <div class="buttons">
      <button data-cy="required-fields-modal-cancel" class="discard-button mr-1" mat-button (click)="cancel()">
        <span>Cancel</span>
      </button>

      <button data-cy="required-fields-modal-save" class="send-button" mat-flat-button [color]="'primary'" [disabled]="isLoading" (click)="update()">
        Save
      </button>
    </div>
  </div>
</div>
