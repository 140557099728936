import { QuillModules } from 'ngx-quill';
import { Delta } from 'quill/core';

const MSWORD_MATCHERS: (string | ((node: any, delta: any) => any))[][] = [
  ['p.MsoListParagraphCxSpFirst', matchMsWordList],
  ['p.MsoListParagraphCxSpMiddle', matchMsWordList],
  ['p.MsoListParagraphCxSpLast', matchMsWordList],
  ['p.MsoListParagraph', matchMsWordList],
  ['p.msolistparagraph', matchMsWordList],
  ['p.MsoNormal', maybeMatchMsWordList],
  ['p', maybeMatchMsWordList],
];

const QUILL_MODULES: QuillModules = {
  toolbar: [
    [{ font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline'],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
    ['blockquote', 'code-block', 'strike'],
    ['clean'],
  ],
};

function matchMsWordList(node, delta) {
  // Clone the operations
  let ops = delta.ops.map((op) => Object.assign({}, op));

  // Trim the front of the first op to remove the bullet/number
  let bulletOp = ops.find((op) => op.insert && op.insert.trim().length);
  if (!bulletOp) {
    return delta;
  }

  bulletOp.insert = bulletOp.insert.trimLeft();
  let listPrefix = bulletOp.insert.match(/^(\s*[·.-o§])/) || bulletOp.insert[0];

  bulletOp.insert = bulletOp.insert.substring(listPrefix[0].length, bulletOp.insert.length).trimLeft();

  // Trim the newline off the last op
  let last = ops[ops.length - 1];
  last.insert = last.insert.substring(0, last.insert.length - 1);

  // Determine the list type
  let listType = listPrefix[0].length === 1 ? 'bullet' : 'ordered';

  // Determine the list indent
  let style = node.getAttribute('style').replace(/\n+/g, '');
  let levelMatchMargin = style.match(/^margin: [^;]* (\d+)pt/); // exists only for mac MS Word versions.
  let levelMatchLevel = style.match(/level(\d+)/); // exists only for MS Word Windows version.

  // Determine the indent based on the matching pattern
  let indent = 0;

  if (levelMatchLevel) {
    // If "level" pattern is matched, use `level - 1` logic
    indent = parseInt(levelMatchLevel[1]) - 1;
  } else if (levelMatchMargin) {
    // If "margin" pattern is matched, use `margin/36` logic
    indent = parseFloat(levelMatchMargin[1]) / 36;
  }

  ops.push({ insert: '\n', attributes: { list: listType, indent } });

  return new Delta(ops);
}

function maybeMatchMsWordList(node, delta) {
  if (['·', '•', 'o', '-', '§'].includes(delta.ops[0].insert.trimLeft()[0])) {
    return matchMsWordList(node, delta);
  }

  return delta;
}

export { MSWORD_MATCHERS, QUILL_MODULES };
