import { Injectable } from '@angular/core';

declare let rnw: any;

@Injectable({
  providedIn: 'root',
})
export class ReleaseNotesService {
  constructor() {}

  public loadReleaseNotesScript(): void {
    // Add the script initialization code here
    (function (w, d, s, o, f, js, fjs) {
      w['ReleaseNotesWidget'] = o;
      w[o] =
        w[o] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          (w[o].q = w[o].q || []).push(arguments);
        };
      js = d.createElement(s);
      fjs = d.getElementsByTagName(s)[0];
      js.id = o;
      js.src = f;
      js.async = 1;
      fjs.parentNode.insertBefore(js, fjs);
    })(window, document, 'script', 'rnw', 'https://s3.amazonaws.com/cdn.releasenotes.io/v1/bootstrap.js');

    rnw('init', {
      account: 'releases.termsheet.com',
      selector: '.rnw-badge',
      auto_show_unseen: true,
    });
  }
}
