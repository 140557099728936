import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TsButtonModule } from '../../ts-button/ts-button.module';
import { TSIconModule } from '../../ts-icon/ts-icon.module';

@Component({
  standalone: true,
  imports: [MatIconModule, TsButtonModule, TSIconModule, MatMenuModule],
  selector: 'button-renderer',
  template: `
    <ts-button type="icon" [matMenuTriggerFor]="menu" color="accent" icon="more_vert"></ts-button>
    <mat-menu #menu="matMenu">
      @for (action of actions; track trackByFn) {
      <button mat-menu-item (click)="onActionClick(action)">
        <div class="btn-content">
          @if (action.icon) {
          <ts-icon outlined="true" [icon]="action.icon"></ts-icon>
          }
          <span>{{ action.label }}</span>
        </div>
      </button>
      }
    </mat-menu>
  `,
  styles: [
    `
      .btn-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    `,
  ],
})
export class TableActionsRendererComponent implements ICellRendererAngularComp {
  actions: any[] = [];
  params;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.actions = this.params.actions || [];
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.actions = this.params.actions || [];
    return true;
  }

  onActionClick(action): void {
    if (action && typeof action.action === 'function') {
      action.action(this.params.data);
    }
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
