import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Automation } from './actions';
import { StateBase } from 'app/state/state-base';
import { AutomationsService } from '../automations.service';
import { of, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Automation as AutomationModel } from '../builder/builder.types';
import { AccountFoldersService } from 'app/modules/folders/folders.service';
import { FileResource } from 'app/shared/modules/folders/folders.types';

export interface AutomationStateModel {
  automations: AutomationModel[];
  selectedAutomation: AutomationModel;
  files: FileResource[];
}

@State<AutomationStateModel>({
  name: new StateToken<AutomationStateModel>('automationState'),
  defaults: {
    automations: [],
    selectedAutomation: null,
    files: [],
  },
})
@Injectable()
export class AutomationState extends StateBase {
  constructor(
    protected readonly store: Store,
    private readonly automationsService: AutomationsService,
    private readonly accountFoldersService: AccountFoldersService,
  ) {
    super(store);
    this.accountFoldersService.accountId = String(this.accountId);
  }

  @Selector()
  static getAutomations(state: AutomationStateModel) {
    return state.automations;
  }

  @Selector()
  static getEligibleFiles(state: AutomationStateModel) {
    return state.files;
  }

  @Selector()
  static getSelectedAutomation(state: AutomationStateModel) {
    return state.selectedAutomation;
  }

  @Action(Automation.Delete)
  delete(context: StateContext<AutomationStateModel>, { id }: { id: number }) {
    return this.automationsService.remove(this.accountId, id).pipe(
      tap((response) => {
        context.patchState({
          automations: context.getState().automations.filter((automation) => automation.id !== id),
        });
      }),
    );
  }

  @Action(Automation.Create)
  create(context: StateContext<AutomationStateModel>, { model }: { model: AutomationModel }) {
    const payload: AutomationModel = {
      ...model,
      id: undefined,
      actions: model.actions.map((action) => ({ ...action, id: undefined })),
      triggers: model.triggers.map((trigger) => ({ ...trigger, id: undefined })),
    };

    return this.automationsService.create(this.accountId, payload).pipe(
      tap((response) => {
        context.patchState({
          automations: [...context.getState().automations, response],
          selectedAutomation: response,
        });
      }),
    );
  }

  @Action(Automation.Update)
  update(context: StateContext<AutomationStateModel>, { model }: { model: AutomationModel }) {
    return this.automationsService.update(this.accountId, model as any).pipe(
      tap((response) => {
        context.patchState({
          automations: [...context.getState().automations, response.automation as unknown as AutomationModel],
          selectedAutomation: response.automation as unknown as AutomationModel,
        });
      }),
    );
  }

  @Action(Automation.GetById)
  getById(context: StateContext<AutomationStateModel>, { id }: { id: number }) {
    if (!context.getState().automations?.length) {
      return this.automationsService.get(this.accountId).pipe(
        tap((response) => {
          context.patchState({
            automations: response.automations as unknown as AutomationModel[],
            selectedAutomation: response.automations.find(
              (automation) => automation.id === id,
            ) as unknown as AutomationModel,
          });
        }),
      );
    }

    return of(context.getState().automations).pipe(
      tap((automations) => {
        context.patchState({ selectedAutomation: automations.find((automation) => automation.id === id) });
      }),
    );
  }

  @Action(Automation.GetEligibleFiles)
  getEligibleFiles(context: StateContext<AutomationStateModel>) {
    return this.accountFoldersService.getAllFiles().pipe(
      tap((fileResources) => {
        context.patchState({
          files: fileResources.filter((file) => {
            const fileName = file.name?.toLowerCase();
            return fileName.endsWith('.xlsxb') || fileName.endsWith('.xlsx') || fileName.endsWith('.xls');
          }),
        });
      }),
    );
  }

  @Action(Automation.Get)
  get(context: StateContext<AutomationStateModel>) {
    return this.automationsService
      .get(this.accountId)
      .pipe(
        tap((response) => context.patchState({ automations: response.automations as unknown as AutomationModel[] })),
      );
  }
}
