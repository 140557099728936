import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

type SummaryFormula = {
  id: number;
  name: string;
  label: string;
  account_id: number;
};

@Injectable({ providedIn: 'root' })
class SummaryFormulaService extends BaseService {
  constructor(protected readonly httpClient: HttpClient) {
    super(httpClient);
  }

  getByAccountId(accountId: number): Observable<SummaryFormula[]> {
    return this.httpClient
      .get<any>(`summary_formulas?account_id=${accountId}`)
      .pipe(map(({ summary_formulas }) => summary_formulas));
  }
}

export { SummaryFormulaService, SummaryFormula };
