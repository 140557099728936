import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TAB } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { debounceTime, switchMap } from 'rxjs/operators';

import isArray from 'lodash-es/isArray';
import { AccountService } from 'app/core';
import { Account } from 'app/core/models/account.types';
import { Dictionary } from 'app/shared/models';
import { RelatedToType } from 'app/shared/enums';
import { FormFieldComponent } from '../form-field.types';

@Component({
  templateUrl: './tags-form-field.component.html',
  styleUrls: ['./tags-form-field.component.scss'],
})
export class TagsFormFieldComponent extends FormFieldComponent implements OnInit {
  public editing: boolean = false;
  public value: string = '';
  public tags: string[] = [];
  public foundTags: Dictionary<number>;
  account: Account;

  tagCtrl: UntypedFormControl;
  separatorKeysCodes: number[] = [TAB];
  visible = true;
  selectable = true;
  removable = true;

  private _readonly;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  constructor(private _accountService: AccountService) {
    super();
    this.account = this._accountService.getCurrentAccount();
  }

  ngOnInit() {
    this.tagCtrl = new UntypedFormControl();

    this.value = this.model[this.fieldDef.name];

    if (this.value) this.tags = isArray(this.value) ? this.value : this.value?.split(',');
    if (this.fieldDef.meta && this.fieldDef.meta.readonly) this._readonly = this.fieldDef.meta.readonly;
    if (this.readonly) this._readonly = this.readonly;

    this.tagCtrl.valueChanges
      .pipe(
        debounceTime(400),
        switchMap((value: string) =>
          this._accountService.getAccountTags(this.account.id, RelatedToType.Project, value),
        ),
      )
      .subscribe((tags) => {
        this.foundTags = tags;
      });
  }

  edit() {
    if (!this.editing && !this.isDisabled) this.editing = true;
  }

  onBlur() {
    this.onSave();
    this.editing = false;
  }

  onSave() {
    this.updateModel();
    this.update.emit({ [this.fieldDef.name]: this.value ? this.value : [] });
  }

  updateModel() {
    this.model[this.fieldDef.name] = this.value;
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;

    if ((input.value || '').trim()) this.tags.push(input.value.trim());

    if (input) input.value = '';

    this.tagCtrl.setValue(null);
  }

  removeTag(tag: string): void {
    this.tags.splice(
      this.tags.findIndex((item) => item === tag),
      1,
    );
  }

  selectedTag(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  updateChangedModel?(model: any): void {
    /* noop */
  }
}
