import { Pipe, PipeTransform } from '@angular/core';
import { FieldDef } from 'app/core/models';

export type Groupable<T> = { groupName: string; groupLabel: string; items: T[] };

@Pipe({ name: 'groupBy' })
export class GroupByPipe implements PipeTransform {
  transform(value: FieldDef[], groupProp: string, labelProp: string, order: string[] = []): Groupable<any>[] {
    const data = value
      .reduce((p, c) => {
        const group = p.find((x) => x.groupName === c[groupProp]);

        if (!group) {
          p.push({
            groupLabel: c[labelProp],
            groupName: c[groupProp],
            items: [],
          });
        }

        return p;
      }, [] as Groupable<any>[])
      .map((x) => ({
        ...x,
        items: value.filter((y) => y[groupProp] === x.groupName),
      }));

    if (order.length > 0) {
      const stageOrder = order.map((groupName, rank) => ({ groupName, rank }));

      const result = data
        .map(({ groupName }) => ({
          groupName,
          rank: stageOrder.find((x) => x.groupName === groupName)?.rank ?? 99,
          ...data.find((y) => y.groupName === groupName),
        }))
        .sort((a, b) => {
          if (a.rank < b.rank) return -1;
          if (a.rank > b.rank) return 1;
          return 0;
        });

      return result;
    }

    return data;
  }
}
