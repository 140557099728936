import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml',
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    const tmp = document.createElement('DIV');
    tmp.innerHTML = value;
    return tmp.textContent || tmp.innerText || '';
  }
}

export class StripHtmlPipeUtility {
  static transform(value: string): string {
    return StripHtmlPipe.prototype.transform(value);
  }
}
