<button class="search-toggle-open" mat-icon-button *ngIf="appearance === 'bar' && !opened" (click)="open()">
  <mat-icon svgIcon="search"></mat-icon>
</button>
<div
  class="search-container"
  *ngIf="appearance === 'basic' || (appearance === 'bar' && opened)"
  [@.disabled]="appearance === 'basic'"
  @slideInTop
  @slideOutTop
>
  <mat-form-field class="treo-mat-no-subscript search-input" #searchInput>
    <mat-icon matPrefix svgIcon="search"></mat-icon>
    <input
      matInput
      [formControl]="searchControl"
      [placeholder]="'Search...'"
      [matAutocomplete]="matAutocomplete"
      (keydown)="onKeydown($event)"
    />
  </mat-form-field>
  <mat-autocomplete
    [class]="'search-results search-results-appearance-' + appearance"
    #matAutocomplete="matAutocomplete"
    [disableRipple]="true"
  >
    <mat-option *ngIf="ui.isLoading; else loaded">
      <mat-progress-spinner class="spinner" diameter="24" mode="indeterminate"> </mat-progress-spinner>
    </mat-option>
    <ng-template #loaded>
      <mat-option class="no-results" *ngIf="results && !results.length"> No results found! </mat-option>
      <mat-option class="search-result-option" *ngFor="let result of results" (onSelectionChange)="selectOption()">
        <app-result-item [item]="result" [query]="searchControl.value"></app-result-item>
      </mat-option>
    </ng-template>
  </mat-autocomplete>
  <button class="search-toggle-close" mat-icon-button *ngIf="appearance === 'bar'" (click)="close()">
    <mat-icon [svgIcon]="'close'"></mat-icon>
  </button>
</div>
