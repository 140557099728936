import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { CalculatorComponent } from 'app/layout/common/calculator/calculator.component';
import { SharedModule } from 'app/shared/shared.module';
import { CalculatorModalComponent } from 'app/layout/common/calculator/calculator-modal/calculator-modal.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [CalculatorComponent, CalculatorModalComponent],
  imports: [
    MatButtonModule,
    MatIconModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CurrencyMaskModule,
    MatDialogModule,
  ],
  exports: [CalculatorComponent],
})
export class CalculatorModule {}
