import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { WINDOW } from 'app/providers/window.provider';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    HubSpotConversations?: {
      widget: {
        close: () => void;
        load: () => void;
        open: () => void;
        refresh: () => void;
        remove: () => void;
      };
    };
    hsConversationsSettings: {
      loadImmediately: boolean;
      disableAttachment: boolean;
    };
    hsConversationsOnReady: (() => void)[];
  }
}

@Injectable({
  providedIn: 'root',
})
export class HubspotChatbotService {
  constructor(@Inject(WINDOW) private window: Window, @Inject(DOCUMENT) private document: Document) {}

  public loadChatbotScript(): void {
    const script = Object.assign(this.document.createElement('script'), {
      type: 'text/javascript',
      id: 'hs-script-loader',
      async: true,
      defer: true,
      src: `//js-na1.hs-scripts.com/${environment.hubspot.chatbotScriptId}.js`,
      onerror: (error: any) => console.error('HubSpot chatbot script failed to load:', error),
    });

    this.document.body.appendChild(script);

    this.setupChatBot();
  }

  private setupChatBot(): void {
    this.window.hsConversationsSettings = {
      loadImmediately: false,
      disableAttachment: false,
    };

    if (!this.window.HubSpotConversations) {
      this.window.hsConversationsOnReady = [() => {}]; // Execute every function in array in sequence when the chatbot object is ready
    }
  }

  private getConversationsWidget(): any {
    return this.window.HubSpotConversations && this.window.HubSpotConversations.widget
      ? this.window.HubSpotConversations.widget
      : null;
  }

  // Auxiliary methods
  public closeWidget(): void {
    const widget = this.getConversationsWidget();
    if (widget) widget.close();
  }

  public loadWidget(): void {
    const widget = this.getConversationsWidget();
    if (widget) widget.load();
  }

  public openWidget(): void {
    const widget = this.getConversationsWidget();
    if (widget) widget.open();
  }

  public refreshWidget(): void {
    const widget = this.getConversationsWidget();
    if (widget) widget.refresh();
  }

  public removeWidget(): void {
    const widget = this.getConversationsWidget();
    if (widget) widget.remove();
  }
}
