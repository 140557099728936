import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TreoAutogrowModule } from 'tsui/@treo';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import {
  MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY as MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
  MatLegacyAutocompleteModule as MatAutocompleteModule,
} from '@angular/material/legacy-autocomplete';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { DirectivesModule } from '../../directives/directives.module';

import { CurrencyMaskModule } from 'ng2-currency-mask';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

import { ParcelMapComponent } from './parcel-map.component';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { FloodMapLegendComponent } from 'app/modules/research/@components/flood-map-legend/flood-map-legend.component';

@NgModule({
  declarations: [ParcelMapComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgApexchartsModule,
    MatMenuModule,
    MatMomentDateModule,
    MatProgressBarModule,
    MatRadioModule,
    MatRippleModule,
    NgxMapboxGLModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    TreoAutogrowModule,
    PipesModule,
    DirectivesModule,
    CurrencyMaskModule,
    FloodMapLegendComponent,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [ParcelMapComponent],
  providers: [provideNgxMask()],
})
export class ParcelMapModule {}
