import { Layer } from '../research.types';

export const defaultMapLayers: Layer[] = [
  {
    id: '2',
    name: 'School Ratings',
    label: 'School Ratings',
    attribute: 'median_gross_rent_5yr_cagr',
    type: 'heatmap',
    category: 'heatmap',
    active: false,
  },
  {
    id: '3',
    name: 'Household Income',
    label: 'Household Income',
    attribute: 'median_income_5yr_cagr',
    type: 'heatmap',
    category: 'heatmap',
    active: true,
  },
  {
    id: '4',
    name: 'Population Growth',
    label: 'Population Growth',
    attribute: 'population_5yr_cagr',
    type: 'heatmap',
    category: 'heatmap',
    active: true,
  },
  {
    id: '5',
    name: 'Housing Value',
    label: 'Housing Value',
    attribute: 'median_housing_value_5yr_cagr',
    type: 'heatmap',
    category: 'heatmap',
    active: true,
  },
  {
    id: '6',
    name: 'Rental Rates',
    label: 'Rental Rates',
    attribute: 'median_gross_rent_5yr_cagr',
    type: 'heatmap',
    category: 'heatmap',
    active: true,
  },
  {
    id: '7',
    name: 'Divorce Rate',
    label: 'Divorce Rate',
    attribute: 'median_gross_rent_5yr_cagr',
    type: 'heatmap',
    category: 'heatmap',
    active: false,
  },
  {
    id: '12',
    name: 'Electric Substations',
    label: 'Electric Substations',
    type: 'circle',
    category: 'symbol',
    url: 'https://termsheet-data-lake.s3.amazonaws.com/pvw/Electric_Substations.geojson',
    active: true,
  },
  {
    id: '13',
    name: 'Walmarts',
    label: 'Walmarts',
    type: 'circle',
    category: 'symbol',
    url: 'https://termsheet-data-lake.s3.amazonaws.com/pvw/walmarts.geojson',
    active: true,
  },
];
