import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TSIconComponent } from './ts-icon.component';
import { CommonModule } from '@angular/common';
import { randomId } from '../cross-cutting/util';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [TSIconComponent],
  imports: [MatIconModule, CommonModule, MatTooltipModule],
  exports: [TSIconComponent, MatIconModule],
})
export class TSIconModule {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    this.iconRegistry.addSvgIconSet(
      this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/ts_set_icons.svg?r=${randomId()}`),
    );
  }
}
