import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TAB } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { Observable, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AccountService } from 'app/core';
import { RelatedToType } from 'app/shared/enums';
import { Account } from 'app/core/models/account.types';
import { AddressPart, Dictionary } from 'app/shared/models';
import { Company } from 'app/modules/companies/companies.types';
import { CompaniesService } from 'app/modules/companies/companies.service';

declare interface DialogDataInterface {
  byPass: boolean;
  contactId: string;
  object: unknown;
  related_to_type: RelatedToType;
  multi_select?: boolean;
}
@Component({
  selector: 'project-add-company',
  templateUrl: './project-add-company.component.html',
  styleUrls: ['./project-add-company.component.scss'],
})
export class ProjectAddCompanyComponent extends OnDestroyMixin implements OnInit {
  private account: Account;

  public companyForm: UntypedFormGroup;
  public companyResults: Company[];
  public selectedCompany: Company;
  public separatorKeysCodes: number[] = [TAB];
  public tagsSearchResult$: Observable<Dictionary<number>>;
  public isLoading: boolean;
  public isSearching = false;
  public createNew = false;

  @ViewChild('tagsInput') tagsInput: ElementRef<HTMLInputElement>;

  get companyNameControl(): AbstractControl {
    return this.companyForm.get('name');
  }

  get tagsControl(): AbstractControl {
    return this.companyForm.get('tag_list');
  }

  get tags(): string[] {
    return (this.tagsControl.value || []) as string[];
  }

  get addTagInputControl(): AbstractControl {
    return this.companyForm.get('tagsInput');
  }

  get hasSelectedCompany(): boolean {
    return !!this.selectedCompany;
  }

  get isSaveBtnDisabled(): boolean {
    return this.isLoading || this.companyForm.pristine || this.companyForm.invalid;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: DialogDataInterface,
    private readonly matDialogRef: MatDialogRef<ProjectAddCompanyComponent>,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly companiesService: CompaniesService,
    private readonly accountService: AccountService,
  ) {
    super();
    this.account = this.accountService.getCurrentAccount();
    this.companiesService.accountId = this.account.id;
  }

  ngOnInit(): void {
    this.buildForm();
    this.searchCompanies();
    this.searchTags();
  }

  private buildForm(): void {
    this.companyForm = this.formBuilder.group({
      id: [this.hasSelectedCompany ? this.selectedCompany.id : null],
      name: ['', [Validators.required]],
      website: [this.hasSelectedCompany ? this.selectedCompany.website : ''],
      address: this.formBuilder.group({
        address_string: '',
        street: '',
        city: '',
        state: '',
        postal_code: '',
        latitude: '',
        longitude: '',
      }),
      address_string: [this.hasSelectedCompany ? this.selectedCompany.address_string : ''],
      tag_list: [this.hasSelectedCompany ? this.selectedCompany.tag_list : []],
      tagsInput: [''],
    });
  }

  private searchCompanies(): void {
    this.companyForm
      .get('name')
      .valueChanges.pipe(
        untilComponentDestroyed(this),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((value) => {
          const query = value;

          this.createNew = false;

          if (!query || !query.id) {
            this.isSearching = true;
            return this.companiesService.getCompanies(1, 20, 'name', 'asc', query).pipe(
              untilComponentDestroyed(this),
              map(({ companies }) => companies),
            );
          }

          return of([]);
        }),
      )
      .subscribe((companies) => {
        this.companyResults = companies;
        this.isSearching = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  private searchTags(): void {
    this.tagsSearchResult$ = this.addTagInputControl.valueChanges.pipe(
      untilComponentDestroyed(this),
      debounceTime(400),
      filter((value: string) => !!(value || '').trim()),
      switchMap((query: string) =>
        this.accountService
          .getAccountTags(this.account.id, RelatedToType.Company, query)
          .pipe(untilComponentDestroyed(this)),
      ),
    );
  }

  private clearTagsInput(): void {
    this.tagsInput.nativeElement.value = '';
    this.addTagInputControl.setValue('');
  }

  public saveCompanyAssociation(): Subscription {
    this.companyForm.disable();
    const companyFormValue = this.companyForm.value;
    const service = this.createNew
      ? this.companiesService.createCompany(companyFormValue)
      : this.companiesService.updateCompany(companyFormValue);

    return service.pipe(untilComponentDestroyed(this)).subscribe((company: Company) => {
      this.matDialogRef.close({ contact: company, contact_id: company.id, multi_select: this.data.multi_select });
    });
  }

  public autocompleteCompanySelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedCompany = this.companyResults.find((company) => {
      return company.id === event.option.value;
    });
    if (this.selectedCompany) this.companyForm.patchValue(this.selectedCompany, { emitEvent: false });
  }

  public setAddress(addressString: string): void {
    this.companyForm.get('address').get('address_string').setValue(addressString);
  }

  public onSelectAddressParts({
    locality,
    administrative_area_level_1,
    street_number,
    route,
    postal_code,
    lat,
    lng,
  }: AddressPart): void {
    this.companyForm.get('address').get('city').setValue(locality);
    this.companyForm.get('address').get('state').setValue(administrative_area_level_1);
    this.companyForm.get('address').get('postal_code').setValue(postal_code);
    this.companyForm.get('address').get('latitude').setValue(lat);
    this.companyForm.get('address').get('longitude').setValue(lng);

    if (route) {
      const streetValue = street_number ? `${street_number} ${route}` : route;
      this.companyForm.get('address').get('street').setValue(streetValue);
    } else {
      this.companyForm.get('address').get('street').setValue('');
    }
  }

  public removeTag(tag: string): void {
    this.tagsControl.setValue([...this.tags.filter((item) => tag !== item)]);
  }

  public addTag(event: MatChipInputEvent): void {
    const { value: rawValue } = event;
    const value = (rawValue || '').trim();

    if (value) {
      this.tagsControl.setValue([...this.tags, value]);
      this.clearTagsInput();
    }
  }

  public selectedTag({ option }: MatAutocompleteSelectedEvent): void {
    this.tagsControl.setValue([...this.tags, option.value]);
    this.clearTagsInput();
  }

  public discard(): void {
    this.matDialogRef.close();
  }

  public resetForm(): void {
    this.companyForm.reset();
    this.selectedCompany = null;
  }

  // Function attached to debounce-click directive to flag the button's disable state.
  public isDebounceLoading(isLoading: boolean): boolean {
    return (this.isLoading = isLoading);
  }
}
