import { BehaviorSubject } from 'rxjs';
import { TSTreeNode } from 'tsui';

export const reportBuilderListMenuItems = new BehaviorSubject<TSTreeNode[]>([
  {
    id: 'all-reports',
    name: 'All Reports',
    expandable: false,
    icon: 'view_list',
    link: 'reporting/list',
    badge: {
      value: '0',
      type: 'transparent',
    },
  },
  {
    id: 'created_by_me',
    name: 'Created By Me',
    expandable: false,
    icon: 'view_list',
    link: 'reporting/list?byme=true',
    badge: {
      value: '',
      type: 'transparent',
    },
  },
  {
    id: 'favorites',
    name: 'Favorites',
    expandable: false,
    icon: 'star_border',
    link: 'reporting/list?favorites=true',
    badge: {
      value: '',
      type: 'transparent',
    },
  },
]);
