import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject, Subscription, noop } from 'rxjs';
import { debounceTime, finalize, tap } from 'rxjs/operators';

/**
 * How to use it:
 * <button
 *  TSDebounceClick
 *  (debounceClick)="doSomething()" (Function from parent component to be called)
 *  [debounceTime]="700"
 * >
 *  Save Something
 * </button>
 */

@Directive({
  selector: '[TSDebounceClick]',
})
export class TSDebounceClickDirective implements OnInit, OnDestroy {
  @Input() debounceTime: number = 300;
  @Output() debounceClick: EventEmitter<unknown> = new EventEmitter();

  private clicks$: Subject<unknown> = new Subject();
  private subscription$: Subscription;

  ngOnInit() {
    this.subscription$ = this.clicks$
      .pipe(
        debounceTime(this.debounceTime),
        tap((event) => {
          this.debounceClick.emit(event);
        }),
      )
      .subscribe(noop);
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event: MouseEvent) {
    this.clicks$.next(event);
    event.preventDefault();
    event.stopPropagation();
  }
}
