import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormatNumberPipe } from 'app/shared/pipes/format-number.pipe';
import { FormFieldComponent } from '../form-field.types';

@Component({
  templateUrl: './percent-form-field.component.html',
  styleUrls: ['./percent-form-field.component.scss'],
})
export class PercentFormFieldComponent extends FormFieldComponent implements OnInit {
  @ViewChild('inputControl') inputControl: ElementRef;

  public editing: boolean = false;
  private valueMemento: string;
  private _readonly;
  precision: number = 2;

  valueControl = new UntypedFormControl();

  ngOnInit(): void {
    super.ngOnInit();

    // we want to track the original value, so that we don't hit save to many times
    if (this.fieldDef.meta && this.fieldDef.meta.readonly) {
      this._readonly = this.fieldDef.meta.readonly;
    }
    if (this.readonly) {
      this._readonly = this.readonly;
    }
    this.readonly = this._readonly;

    if (this.fieldDef.meta && this.fieldDef.meta.scale) {
      this.precision = this.fieldDef.meta.scale;
    }

    if (this.isDisabled) {
      this.valueControl.disable();
    }

    this.setValues();
  }

  /**
   * When the model is updated after save, re-run our logic
   * @param model
   */
  updateChangedModel(model: any): void {
    this.model = model;
    this.setValues();
  }

  edit() {
    if (!this.editing && !this._readonly) {
      this.editing = true;
      setTimeout(() => {
        this.inputControl.nativeElement.focus();
      }, 300);
    }
  }

  onBlur(event: Event & { target: { value: any } }): void {
    this.editing = false;

    const value = event?.target?.value ? String(Number(event.target.value)) : null;

    if (this.valueMemento === value) {
      return;
    }

    this.model[this.fieldDef.name] = value;
    this.update.emit({ [this.fieldDef.name]: value });
  }

  private setValues(): void {
    const value = new FormatNumberPipe().transform(this.model[this.fieldDef.name], this.precision);
    this.valueControl.setValue(value);
    this.valueMemento = value;
  }
}
