<div class="header">
  <div class="title">Associate Company</div>
  <button class="close-button" mat-icon-button (click)="discard()" [tabIndex]="-1">
    <mat-icon [svgIcon]="'close'"></mat-icon>
  </button>
</div>

<form [formGroup]="companyForm">
  <!-- Company Name Control -->
  <div class="flex flex-col gt-xs:flex-row">
    <mat-form-field class="flex-auto">
      <mat-label>Company Name</mat-label>
      <input
        matInput
        [formControlName]="'name'"
        [placeholder]="'Search by Company Name'"
        [matAutocomplete]="matAutocompleteCompany"
        [readonly]="hasSelectedCompany"
        [spellcheck]="false"
      />
      <ng-container *ngIf="isSearching">
        <div [ngTemplateOutlet]="LoadingSpinner"></div>
      </ng-container>
    </mat-form-field>
    <mat-autocomplete
      #matAutocompleteCompany="matAutocomplete"
      [disableRipple]="true"
      (optionSelected)="autocompleteCompanySelected($event)"
    >
      <mat-option *ngIf="companyResults && !companyResults.length" disabled class="no-results">
        No companies found!
      </mat-option>

      <mat-option *ngFor="let companyResult of companyResults" [value]="companyResult.id">
        <span
          [innerHTML]="companyResult.name | highlighter: companyForm.get('name').value:'highlight-result':'partial'"
        ></span>
      </mat-option>

      <mat-option
        *ngIf="companyResults && companyNameControl.value && !createNew && !isSearching"
        disabled
        (click)="createNew = true"
      >
        <span class="create-new">Create a New Company</span>
      </mat-option>
    </mat-autocomplete>
  </div>

  <ng-container *ngIf="companyNameControl.value && (hasSelectedCompany || createNew)">
    <!-- Company Website Control -->
    <div class="flex flex-col gt-xs:flex-row">
      <mat-form-field class="flex-auto">
        <mat-label>Website</mat-label>
        <input
          matInput
          [ngClass]="{ 'cursor-not-allowed': hasSelectedCompany }"
          [formControlName]="'website'"
          [placeholder]="'Website'"
          [readonly]="hasSelectedCompany"
        />
      </mat-form-field>
    </div>

    <!-- Company Address Control -->
    <div class="flex flex-col gt-xs:flex-row">
      <ng-container *ngIf="hasSelectedCompany && selectedCompany?.address_string?.length">
        <mat-form-field class="flex-auto">
          <mat-label>Address</mat-label>
          <input
            matInput
            class="cursor-not-allowed"
            [formControlName]="'address_string'"
            [placeholder]="'Address'"
            [readonly]="hasSelectedCompany"
          />
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="createNew">
        <fieldset class="w-full" [formGroupName]="'address'">
          <mat-form-field class="flex-auto w-full">
            <mat-label>Address</mat-label>
            <input
              matInput
              google-place
              [placeholder]="'Address'"
              [formControlName]="'address_string'"
              (onSelect)="setAddress($event)"
              (onSelectParts)="onSelectAddressParts($event)"
              autocomplete="none"
            />
          </mat-form-field>
        </fieldset>
      </ng-container>
    </div>

    <!-- Company Tags Control -->
    <div class="flex flex-col gt-xs:flex-row">
      <ng-container *ngIf="hasSelectedCompany && selectedCompany?.tag_list.length">
        <mat-form-field class="flex-auto">
          <mat-label>Tags</mat-label>
          <mat-chip-list #chipList aria-label="Tags display">
            <mat-chip *ngFor="let tag of tags" class="cursor-not-allowed" [selectable]="false" [removable]="false">{{
              tag
            }}</mat-chip>
          </mat-chip-list>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="createNew">
        <mat-form-field class="flex-auto">
          <mat-label>Tags</mat-label>
          <mat-chip-list #chipList aria-label="Tag selection">
            <mat-chip
              *ngFor="let tag of tags | keyvalue"
              [selectable]="true"
              [removable]="true"
              (removed)="removeTag(tag.value)"
            >
              {{ tag.value }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="New tag..."
              #tagsInput
              [matAutocomplete]="auto"
              [formControlName]="'tagsInput'"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addTag($event)"
              (focus)="tagsInput.focused = true"
              (blur)="tagsInput.focused = false"
              data-cy="tags-input"
            />
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTag($event)">
            <ng-container *ngIf="tagsSearchResult$ | async as tagsSearchResult">
              <mat-option *ngFor="let tag of tagsSearchResult | keyvalue" [value]="tag.key">
                {{ tag.key }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
          <mat-hint *ngIf="tagsInput.focused" class="mt-2">
            Press <strong>TAB</strong> key after finishing typing to add a new tag
          </mat-hint>
        </mat-form-field>
      </ng-container>
    </div>
  </ng-container>

  <div class="buttons mt-6 w-full justify-end">
    <ng-container *ngIf="hasSelectedCompany">
      <button
        class="reset-button mr-auto"
        mat-stroked-button
        type="button"
        (click)="resetForm()"
        [matTooltip]="'Click to clear all fields and search for a new company'"
      >
        <span>Clear</span>
      </button>
    </ng-container>
    <button class="discard-button" mat-button type="button" (click)="discard()">
      <span>Cancel</span>
    </button>
    <button
      class="send-button"
      mat-flat-button
      [color]="'primary'"
      [disabled]="isSaveBtnDisabled"
      DebounceClick
      (debounceLoading)="isDebounceLoading($event)"
      (debounceClick)="saveCompanyAssociation()"
    >
      <span *ngIf="!isLoading; else LoadingSpinner">
        {{ createNew ? 'Create and Save' : 'Save' }}
      </span>
    </button>
  </div>
</form>

<ng-template #LoadingSpinner>
  <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
</ng-template>
