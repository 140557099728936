<mat-form-field class="flex-auto gt-xs:pr-3 w-full" (click)="edit()" (focus)="edit()">
  <mat-label>
    <span
      class="block text-ellipsis overflow-hidden pointer-events-auto"
      [matTooltip]="fieldDef.label"
      tooltipIfEllipsis
    >
      {{ fieldDef.label }}
    </span>
  </mat-label>
  <div class="w-full">
    <mat-select
      (selectionChange)="onSelectionChange($event)"
      [(ngModel)]="value"
      (blur)="onBlur($event)"
      [disabled]="isDisabled"
    >
      <mat-option *ngFor="let option of options" [value]="option.id">{{ option.value }}</mat-option>
    </mat-select>
  </div>
</mat-form-field>
