<div class="header">
  <div class="title">
    {{ selectedFile.name }}
  </div>

  <button class="close-button" mat-icon-button (click)="discard()" [tabIndex]="-1">
    <mat-icon [svgIcon]="'close'"></mat-icon>
  </button>
</div>
<div class="flex flex-wrap h-full">
  <div
    [class]="
      expandRightSidebar ? 'flex flex-wrap w-3/4 lt-xl:w-full border-right' : 'flex flex-wrap lt-xl:w-full border-right'
    "
    [style]="expandRightSidebar ? '' : 'width: calc(100% - 60px)'"
  >
    <!-- WOPI File Editor -->
    <ng-container *ngIf="selectedFile">
      <!-- If there are errors do not display the form. -->
      <ng-container *ngIf="selectedFile.wopi && !selectedFile.wopi.errors">
        <ng-container *ngIf="editWopiFile">
          <form
            [action]="selectedFile?.wopi?.edit_url || selectedFile?.wopi?.view_url"
            class="p-0 mb-6"
            id="officeForm"
            method="post"
            name="officeForm"
            target="officeFrame"
          >
            <input [value]="selectedFile?.wopi?.access_token" name="access_token" type="hidden" />
            <input [value]="selectedFile?.wopi?.access_token_ttl" name="access_token_ttl" type="hidden" />
            <input [value]="selectedFile?.wopi?.owner_id" name="owner_id" type="hidden" />
            <input [value]="selectedFile?.wopi?.user_id" name="user_id" type="hidden" />
          </form>

          <iframe
            [style]="editWopiFile ? 'height: 100%' : 'height: 0;'"
            allowfullscreen="true"
            class="bg-white"
            id="officeFrame"
            name="officeFrame"
            sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox"
            title="Office Frame"
          ></iframe>
        </ng-container>
      </ng-container>

      <!-- If there are errors and we are in edit mode then display the errors. -->
      <ng-container *ngIf="editWopiFile && selectedFile.wopi && selectedFile.wopi.errors">
        <div class="mt-16 pl-8 pt-4">
          <p *ngFor="let error of selectedFile.wopi.errors">
            <strong>{{ error }}</strong>
          </p>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="selectedDocument && !editWopiFile">
      <ng-container *ngIf="!selectedDocument.pdf_url && selectedDocument.integrated_storage === 'one_drive'">
        <div class="flex flex-wrap w-full items-center justify-center">
          <button
            class="send-button"
            id="add-project-finish-button"
            mat-flat-button
            [color]="'primary'"
            (click)="openOnline(selectedDocument)"
          >
            Open In Office Online
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedDocument.pdf_url">
        <iframe [src]="selectedDocument.pdf_url | safe" style="display: block; width: 100%" id="pdf-viewer"></iframe>
      </ng-container>
    </ng-container>
  </div>

  <div
    [class]="expandRightSidebar ? 'flex flex-auto flex-col w-1/4 side-panel' : 'flex flex-auto flex-col side-panel'"
    [style]="expandRightSidebar ? '' : 'width: 60px'"
  >
    <div class="flex flex-col flex-auto">
      <div class="flex items-center px-3 pt-2 pb-1">
        <div class="flex flex-col">
          <div class="font-bold text-md text-secondary uppercase tracking-wider">
            {{ expandRightSidebar ? selectedFile.name : '' }}
          </div>
        </div>
        <!-- Display if the right sidebar is expanded. -->
        <div *ngIf="expandRightSidebar" class="ml-auto">
          <button mat-icon-button [matMenuTriggerFor]="moreMenu">
            <mat-icon [svgIcon]="'more_vert'"></mat-icon>
          </button>
          <mat-menu #moreMenu="matMenu">
            <button mat-menu-item (click)="deleteFile()">
              <mat-icon [svgIcon]="'delete'"></mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
          <button mat-icon-button class="expand-right-sidebar" (click)="expandRightSidebar = !expandRightSidebar">
            <mat-icon class="icon" svgIcon="chevron_right"></mat-icon>
          </button>
        </div>
        <!-- Display if the right sidebar is collapsed. -->
        <div *ngIf="!expandRightSidebar" class="ml-auto">
          <button mat-icon-button class="collapse-right-sidebar" (click)="expandRightSidebar = !expandRightSidebar">
            <mat-icon class="icon" svgIcon="chevron_left"></mat-icon>
          </button>
        </div>
      </div>

      <div [class]="expandRightSidebar ? 'flex flex-col overflow-auto pl-3 pr-5' : 'hidden'">
        <table class="w-full bg-transparent" mat-table [dataSource]="documentVersionsDataSource" [trackBy]="trackByFn">
          <!-- Transaction ID -->
          <ng-container matColumnDef="version">
            <th
              class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t"
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
            >
              <span class="whitespace-nowrap"> Version </span>
            </th>
            <td mat-cell *matCellDef="let document; let i = index">
              <div class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                <a (click)="setSelectedDocument(document)">
                  <div class="flex flex-row">
                    <div class="mt-1" style="text-decoration: underline">
                      Version {{ documentVersionsDataSource.data.length - i }}
                    </div>
                  </div>
                </a>
              </div>
            </td>
          </ng-container>

          <!-- User -->
          <ng-container matColumnDef="created_by">
            <th
              class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t"
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
            >
              <span class="whitespace-nowrap"> Created By </span>
            </th>
            <td mat-cell *matCellDef="let document">
              <span class="font-medium text-sm text-secondary whitespace-nowrap">
                {{ document.created_by ? document.created_by.full_name : '' }}
              </span>
            </td>
          </ng-container>

          <!-- Date -->
          <ng-container matColumnDef="created_at">
            <th
              class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t"
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
            >
              <span class="whitespace-nowrap"> Date </span>
            </th>
            <td mat-cell *matCellDef="let document">
              <span class="font-medium text-sm text-secondary whitespace-nowrap">
                {{ document.created_at | date: 'LLL dd, y h:mm a' }}
              </span>
            </td>
          </ng-container>

          <!-- Name -->
          <ng-container matColumnDef="actions">
            <th
              class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t"
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
            >
              <span class="whitespace-nowrap"> Actions </span>
            </th>
            <td mat-cell *matCellDef="let document">
              <span class="font-medium text-sm text-secondary whitespace-nowrap">
                <button mat-icon-button class="delete-file" (click)="deleteDocument(document)">
                  <mat-icon [svgIcon]="'delete'"></mat-icon>
                </button>
                <button mat-icon-button (click)="downloadDocument(document)">
                  <mat-icon class="icon" [svgIcon]="'get_app'"></mat-icon>
                </button>
                <button mat-icon-button [class]="editWopiFile ? 'edit-file' : 'view-file'" (click)="toggleWopiFile()">
                  <mat-icon class="icon" [svgIcon]="editWopiFile ? 'picture_as_pdf' : 'edit'"></mat-icon>
                </button>
              </span>
            </td>
          </ng-container>

          <!-- Footer -->
          <ng-container matColumnDef="documentVersionsTableFooter">
            <td class="px-3 border-none" mat-footer-cell *matFooterCellDef colspan="6">
              <input style="display: none" type="file" (change)="onFileChanged($event)" #fileInput />
              <button mat-button mat-stroked-button [color]="'primary'" (click)="fileInput.click()">
                Upload New File Version
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="documentVersionsColumns"></tr>
          <tr class="order-row h-16" mat-row *matRowDef="let row; columns: documentVersionsColumns"></tr>
          <tr class="py-3" mat-footer-row *matFooterRowDef="['documentVersionsTableFooter']"></tr>
        </table>
      </div>

      <div class="w-full pl-3 pr-5" [class]="expandRightSidebar ? 'mt-3' : 'hidden'">
        <comment-view [relatedToId]="selectedFile.id" relatedToType="FileResource" [account]="account"></comment-view>
      </div>
    </div>
  </div>
</div>
