import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef, Input } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FoldersTabService } from 'app/shared/modules/folders/folders.service';
import { FolderResource, FileResource } from 'app/shared/modules/folders/folders.types';
import { ConfirmService } from 'app/shared/components/widgets/dialog/confirm/confirm.service';

@Component({
  selector: 'project-dialog-files-panel',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectDialogFilesPanelComponent implements OnInit, OnDestroy {
  @Input() project: any;
  @Input() account: any;

  private _unsubscribeAll: Subject<void>;

  files: FileResource[];
  displayColumns: string[];
  folderId: number;

  constructor(
    private _foldersTabService: FoldersTabService,
    private _changeDetectorRef: ChangeDetectorRef,
    private confirmService: ConfirmService,
  ) {
    this._unsubscribeAll = new Subject();
    this.displayColumns = ['name', 'created'];
  }

  ngOnInit() {
    if (this.project.root_folder_resource) {
      this.folderId = this.project.root_folder_resource.id;
    }

    this._foldersTabService.folder$.pipe(takeUntil(this._unsubscribeAll)).subscribe((folder: FolderResource) => {
      if (folder) {
        this.files = folder.file_resources;
        this._changeDetectorRef.markForCheck();
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {}

  downloadDocument(file): void {
    if (file.documents.length > 0) {
      let document = file.documents[0];
      window.open(document.original_url, '_blank');
    }
  }

  deleteFile(file): void {
    this.confirmService
      .openConfirmationModal()
      .pipe(filter((res: boolean) => res))
      .subscribe(() => {
        this._foldersTabService.deleteFile(this.folderId, file.id).subscribe();
      });
  }

  onFileChanged(event) {
    let file = event.target.files[0];
    let fileResource = {
      folder_resource_id: this.folderId,
      name: file.name,
    };

    this._foldersTabService.createFile(this.folderId, fileResource).subscribe((fileResource) => {
      this._foldersTabService.uploadDocument(this.folderId, fileResource.id, file).subscribe((document) => {});
    });
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
