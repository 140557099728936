import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsInputComponent } from './ts-input.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { TsAutocompleteModule } from '../ts-autocomplete';
import { TSIconModule } from '../ts-icon';

export const tsInputImports = [
  CommonModule,
  MatFormFieldModule,
  MatInputModule,
  ReactiveFormsModule,
  FormsModule,
  TSIconModule,
  MatAutocompleteModule,
  TsAutocompleteModule,
];

@NgModule({
  imports: [...tsInputImports],
  declarations: [TsInputComponent],
  exports: [TsInputComponent],
})
export class TsInputModule {}
