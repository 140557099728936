import { Component, Inject, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TAB } from '@angular/cdk/keycodes';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { Observable, of, throwError } from 'rxjs';
import { debounceTime, take, distinctUntilChanged, filter, switchMap, delay, catchError, map } from 'rxjs/operators';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AccountService } from 'app/core';
import { RelatedToType } from 'app/shared/enums';
import { Account } from 'app/core/models/account.types';
import { EMAIL_PATTERN } from 'app/shared/Utils/common.utils';
import { UserService } from 'app/layout/common/user/user.service';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { ContactsService } from 'app/modules/contacts/contacts.service';
import { Contact, Category } from 'app/modules/contacts/contacts.types';
import { Project } from '../../projects.types';
import { Dictionary } from 'app/shared/models';
import { Company } from 'app/modules/companies/companies.types';

declare interface DialogDataInterface {
  project_id?: number;
  contactId?: string;
  related_to_type?: RelatedToType;
  byPass?: boolean;
  object?: any;
  fromProject?: boolean;
  project?: Project;
  paginator?: MatPaginator;
  pageSize?: number;
  sort?: MatSort;
  queryFilters?: any;
}
@Component({
  selector: 'project-add-contact',
  templateUrl: './project-add-contact.component.html',
  styleUrls: ['./project-add-contact.component.scss'],
})
export class ProjectAddContactComponent extends OnDestroyMixin implements OnInit {
  private account: Account;

  public contactForm: UntypedFormGroup;
  public contactResults: Contact[];
  public associatedCompanyResults: Company[] | null;
  public selectedContact: Contact;
  public isLoading: boolean;
  public isSearching = false;
  public isAssociatedCompanySearching = false;
  public categories: Category[];
  public contactId: string | null;
  public relatedToType: RelatedToType;
  public error = null;
  public createNew = false;
  separatorKeysCodes: number[] = [TAB];
  tagsSearchResult$: Observable<Dictionary<number>>;

  @ViewChild('tagsInput') tagsInput: ElementRef<HTMLInputElement>;

  get idControl(): AbstractControl {
    return this.contactForm.get('id');
  }

  get contactNameControl(): AbstractControl {
    return this.contactForm.get('name');
  }

  get tagsControl(): AbstractControl {
    return this.contactForm.get('tag_list');
  }

  get tags(): string[] {
    return (this.tagsControl.value || []) as string[];
  }

  get addTagInputControl(): AbstractControl {
    return this.contactForm.get('tagsInput');
  }

  get hasSelectedContact(): boolean {
    return !!this.selectedContact;
  }

  get isSaveBtnDisabled(): boolean {
    return this.isLoading || this.contactForm.pristine || this.contactForm.invalid;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: DialogDataInterface,
    public readonly matDialogRef: MatDialogRef<ProjectAddContactComponent>,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly projectsService: ProjectsService,
    private readonly contactsService: ContactsService,
    private readonly userService: UserService,
    private readonly accountService: AccountService,
  ) {
    super();
    this.account = this.accountService.getCurrentAccount();
    this.contactsService.accountId = this.account.id;
  }

  ngOnInit(): void {
    this.buildForm();
    this.setupInitial();
    this.getCategories();
    this.searchContacts();
    this.searchTags();
  }

  private buildForm(): void {
    this.contactForm = this.formBuilder.group({
      id: [this.hasSelectedContact ? this.selectedContact.id : null],
      name: ['', [Validators.required]],
      tagsInput: [''],
    });
  }

  private addDynamicControls(): void {
    if (this.hasSelectedContact) {
      this.contactForm.addControl('category_id', this.formBuilder.control(this.selectedContact.category_id));
      this.contactForm.addControl('company_name', this.formBuilder.control(this.selectedContact.company_name));
      this.contactForm.addControl('category_name', this.formBuilder.control(this.selectedContact.category_name));
      this.contactForm.addControl('email', this.formBuilder.control(this.selectedContact.email));
      this.contactForm.addControl('phone', this.formBuilder.control(this.selectedContact.phone));
      this.contactForm.addControl('tag_list', this.formBuilder.control(this.selectedContact.tag_list));
    }

    if (this.createNew) {
      this.contactForm.addControl('category_id', this.formBuilder.control('', Validators.required));
      this.contactForm.addControl('company_name', this.formBuilder.control(''));
      this.contactForm.addControl('category_name', this.formBuilder.control(''));
      this.contactForm.addControl(
        'email',
        this.formBuilder.control('', [Validators.email, Validators.pattern(EMAIL_PATTERN), Validators.required]),
      );
      this.contactForm.addControl('phone', this.formBuilder.control(''));
      this.contactForm.addControl('tag_list', this.formBuilder.control([]));
    }

    this.searchCompanyAssociation();
  }

  private setupInitial(): void {
    this.contactId = this.data?.contactId;
    this.relatedToType = this.data?.related_to_type;

    if (
      this.data.byPass &&
      this.relatedToType === RelatedToType.Project &&
      this.data?.object &&
      this.data?.object?.id
    ) {
      this.projectsService.getContactAssociations(this.data?.object).pipe(untilComponentDestroyed(this)).subscribe();
    } else if (this.contactId) {
      this.contactsService.getContactById(this.contactId).pipe(untilComponentDestroyed(this)).subscribe();
    }
  }

  private getCategories(): void {
    this.accountService
      .getCategories()
      .pipe(untilComponentDestroyed(this))
      .subscribe((categories: Category[]) => {
        this.categories = categories?.filter((option) => !option.hidden);
        this.changeDetectorRef.detectChanges();
      });
  }

  private searchContacts(): void {
    this.contactForm
      .get('name')
      .valueChanges.pipe(
        untilComponentDestroyed(this),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((value) => {
          this.createNew = false;
          this.isSearching = !!value;

          return value
            ? this.contactsService.getContacts(1, 20, 'first_name', 'asc', value).pipe(
                untilComponentDestroyed(this),
                map(({ contacts }) => contacts),
              )
            : of([]);
        }),
      )
      .subscribe((contacts) => {
        this.contactResults = contacts;
        this.isSearching = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  private searchCompanyAssociation(): void {
    this.contactForm
      .get('company_name')
      .valueChanges.pipe(
        untilComponentDestroyed(this),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((value) => {
          this.isAssociatedCompanySearching = !!value;
          return value
            ? this.accountService.typeaheadSearch(value, RelatedToType.Company).pipe(
                untilComponentDestroyed(this),
                map(({ companies }) => companies),
              )
            : of([]);
        }),
      )
      .subscribe((companiesOnlyNames) => {
        this.associatedCompanyResults = companiesOnlyNames;
        this.isAssociatedCompanySearching = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  private searchTags(): void {
    this.tagsSearchResult$ = this.addTagInputControl.valueChanges.pipe(
      untilComponentDestroyed(this),
      debounceTime(400),
      filter((value: string) => !!(value || '').trim()),
      switchMap((query: string) =>
        this.accountService
          .getAccountTags(this.account.id, RelatedToType.Contact, query)
          .pipe(untilComponentDestroyed(this)),
      ),
    );
  }

  private clearTagsInput(): void {
    this.tagsInput.nativeElement.value = '';
    this.addTagInputControl.setValue('');
  }

  public isNewContact(): void {
    this.createNew = true;
    this.addDynamicControls();
  }

  public saveContactAssociation(): void {
    this.contactForm.disable();
    this.error = null;
    const contactFormValue = this.contactForm.getRawValue();
    if (contactFormValue) {
      this.projectsService.project$.pipe(take(1), untilComponentDestroyed(this)).subscribe((project) => {
        // If its an existing contact...
        if (contactFormValue.id) {
          this.contactForm.get('id').patchValue(contactFormValue.id);
          //ch3256: only update contact info if was an edit
          if (this.contactId) {
            this.projectsService
              .updateContactInfo(contactFormValue)
              .pipe(untilComponentDestroyed(this))
              .subscribe((newContact) => {
                if (this.data.byPass) {
                  this.matDialogRef.close({ contact: newContact, contact_id: newContact.id });
                  return;
                }
                this.userService.getRecentContacts(this.account.id).pipe(untilComponentDestroyed(this)).subscribe();
                this.contactForm.enable();
                this.matDialogRef.close({ contact_id: this.contactId });
              });
          } else {
            this.projectsService
              .updateContactInfo(contactFormValue)
              .pipe(
                switchMap((newContact) => {
                  if (this.data.byPass) {
                    this.matDialogRef.close({ contact: newContact, contact_id: newContact.id });
                    return of(null);
                  }
                  return this.projectsService.createContactAssociation(contactFormValue, project.id);
                }),
                catchError(({ error }) => {
                  this.isLoading = false;
                  this.idControl.setValue(contactFormValue.id);
                  this.contactForm.enable();
                  this.error = {
                    error: {
                      message: error.contact_association?.errors?.[0],
                    },
                  };
                  this.changeDetectorRef.detectChanges();
                  return throwError(() => new Error(error));
                }),
              )
              .subscribe(() => {
                if (this.data.fromProject) {
                  this.contactsService
                    .getDealAssociatedContacts(
                      this.data.paginator.pageIndex,
                      this.data.paginator.pageSize ? this.data.paginator.pageSize : this.data.pageSize,
                      'first_name',
                      this.data.sort.direction,
                      '',
                      this.data.queryFilters,
                      this.data.project.id,
                    )
                    .pipe(untilComponentDestroyed(this), delay(1000))
                    .subscribe(() => {
                      this.contactForm.enable();
                      this.matDialogRef.close({ contact_id: contactFormValue.id });
                      this.changeDetectorRef.markForCheck();
                    });
                } else {
                  this.userService
                    .getRecentContacts(this.account.id)
                    .pipe(untilComponentDestroyed(this), delay(1000))
                    .subscribe(() => {
                      this.contactForm.enable();
                      this.matDialogRef.close({ contact_id: contactFormValue.id });
                    });
                }
              });
          }
        }
        // Else create a new one...
        else {
          (this.contactsService.getContactsByEmail(contactFormValue.email) || of([]))
            .pipe(
              untilComponentDestroyed(this),
              switchMap((contacts) => {
                const dupeEmail = contacts?.filter((x) => x.id !== contactFormValue.id).length > 0;
                if (dupeEmail) {
                  this.isLoading = false;
                  this.contactForm.enable();
                  this.error = {
                    error: {
                      message: 'Contact with email already exists',
                    },
                  };
                  return of(false);
                }
                return of(true);
              }),
              filter((result) => result),
              switchMap(() => this.projectsService.createContact(contactFormValue)),
              untilComponentDestroyed(this),
            )
            .subscribe({
              next: (newContact) => {
                if (this.data.byPass) {
                  this.matDialogRef.close({ contact: newContact, contact_id: newContact.id });
                  return;
                }
                this.projectsService
                  .createContactAssociation(newContact, project.id)
                  .pipe(
                    untilComponentDestroyed(this),
                    switchMap(() => this.userService.getRecentContacts(this.account.id)),
                    untilComponentDestroyed(this),
                  )
                  .subscribe(() => {
                    this.contactForm.enable();
                    this.matDialogRef.close({ contact_id: newContact.id });
                  });
              },
              error: (error) => {
                this.error = error;
                this.contactForm.enable();
                this.isLoading = false;
              },
            });
        }
      });
    }
  }

  public autocompleteAssociatedCompanySelected(event: MatAutocompleteSelectedEvent): void {
    this.contactForm.patchValue({ company_name: event.option.value }, { emitEvent: false });
  }

  public autocompleteContactSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedContact = this.contactResults.find((contact) => contact.id === event.option.value.id);
    if (this.selectedContact) {
      this.addDynamicControls();
      this.contactForm.patchValue(this.selectedContact, { emitEvent: true });
      this.contactForm.updateValueAndValidity();
    }
  }

  public discard(): void {
    this.matDialogRef.close();
  }

  public addTag(event: MatChipInputEvent): void {
    const { value: rawValue } = event;

    const value = (rawValue || '').trim();

    if (value) {
      this.tagsControl.setValue([...this.tags, value]);
      this.clearTagsInput();
    }
  }

  public removeTag(tag: string): void {
    this.tagsControl.setValue([...this.tags.filter((item) => tag !== item)]);
    this.tagsControl.markAsDirty();
  }

  public selectedTag({ option }: MatAutocompleteSelectedEvent): void {
    this.tagsControl.setValue([...this.tags, option.value]);
    this.clearTagsInput();
  }

  public resultLabel(result: Contact): string {
    return `${result.name} ${result.category_name ? `[${result.category_name}]` : ''} ${
      result.company_name ? `at ${result.company_name}` : ''
    }`;
  }

  public resetForm(): void {
    this.contactForm.reset();
    this.selectedContact = null;
  }

  // Function attached to debounce-click directive to flag the button's disable state.
  public isDebounceLoading(isLoading: boolean): boolean {
    return (this.isLoading = isLoading);
  }
}
