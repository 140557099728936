import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import type { TreoHorizontalNavigationComponent } from '../../horizontal.component';
import { TreoNavigationService } from '../../../navigation.service';
import { TreoNavigationItem } from '../../../navigation.types';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HORIZONTAL_PARENT } from '../../horizontal-parent.token';

@Component({
  selector: 'treo-horizontal-navigation-branch-item',
  templateUrl: './branch.component.html',
  styles: [
    `
      .expand {
        display: flex;
        align-items: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreoHorizontalNavigationBranchItemComponent implements OnInit, OnDestroy {
  // Child
  @Input()
  child: boolean;

  // Item
  @Input()
  item: TreoNavigationItem;

  // Mat menu
  @ViewChild('matMenu', { static: true })
  matMenu: MatMenu;

  // Name
  @Input()
  name: string;

  // Private
  // private _treoHorizontalNavigationComponent: TreoHorizontalNavigationComponent;
  private _unsubscribeAll: Subject<void>;
  private currentUrl = '';

  /**
   * Constructor
   *
   * @param {TreoNavigationService} _treoNavigationService
   * @param {ChangeDetectorRef} _changeDetectorRef
   */
  constructor(
    private _treoNavigationService: TreoNavigationService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    @Inject(HORIZONTAL_PARENT) private _treoHorizontalNavigationComponent: TreoHorizontalNavigationComponent,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Set the defaults
    this.child = false;

    this._router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        this.triggerChangeDetection();
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get the parent navigation component
    this._treoHorizontalNavigationComponent = this._treoNavigationService.getComponent(this.name);

    // Subscribe to onRefreshed on the navigation component
    this._treoHorizontalNavigationComponent.onRefreshed.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Trigger the change detection
   */
  triggerChangeDetection(): void {
    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  isActive(item) {
    if (this.currentUrl.startsWith('/' + item.id)) return true;

    return false;
  }
}
