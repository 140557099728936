import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Select, Store } from '@ngxs/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { debounceTime, distinctUntilChanged, noop, Observable, of, switchMap, tap } from 'rxjs';

import { ObjectsManagerState } from 'app/modules/settings/objects-manager/state/state';
import { CustomObject, CustomObjectValue } from '../../custom-objects.types';
import { PropertiesService } from 'app/modules/properties/properties.service';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { CustomObjectsService } from '../../services/custom-objects.service';
import { ObjectsManagerService } from 'app/modules/settings/objects-manager/services/objects-manager.service';
import { RelatedToType } from 'app/shared/enums';
import { Project, Property } from 'app/modules/projects/projects.types';
import { AccountService } from 'app/core';
import { ObjectsManagerActions } from 'app/modules/settings/objects-manager/state/actions';

@Component({
  selector: 'add-link-to-custom-object-value',
  templateUrl: './add-link-to-custom-object-value.component.html',
  styleUrls: ['./add-link-to-custom-object-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddLinkToCustomObjectValueComponent extends OnDestroyMixin implements OnInit {
  addLinkToCOValueForm: FormGroup;
  customObjects: CustomObject[];
  customObjectOptions: any[];
  customObjectType: string;
  customObjectId: number;
  customObject: CustomObject;

  public get isSaveBtnDisabled(): boolean {
    return this.addLinkToCOValueForm.pristine || this.addLinkToCOValueForm.invalid;
  }

  public get accountId(): number {
    return Number(this.accountService.getCurrentAccount()?.id);
  }

  @Select(ObjectsManagerState.get) get$: Observable<CustomObject[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<AddLinkToCustomObjectValueComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store,
    private readonly accountService: AccountService,
    private readonly propertiesService: PropertiesService,
    private readonly projectsService: ProjectsService,
    private readonly customObjectsService: CustomObjectsService,
    private readonly objectsManagerService: ObjectsManagerService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.customObjectType = this.data?.customObjectType;
    this.customObjectId = Number(this.data?.customObjectId);
    this.store.dispatch(new ObjectsManagerActions.Get());
    this.buildForm();

    if (this.customObjectId) {
      this.objectsManagerService
        .getById(this.accountId, this.customObjectId)
        .pipe(untilComponentDestroyed(this))
        .subscribe((customObject) => {
          this.customObject = customObject;
        });
    }

    this.get$
      .pipe(
        untilComponentDestroyed(this),
        tap((objects) => {
          this.customObjects = objects.filter((objectItem) => objectItem?.id);
        }),
      )
      .subscribe(noop);

    this.addLinkToCOValueForm
      .get('name')
      .valueChanges.pipe(
        untilComponentDestroyed(this),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((value) => {
          let observableValue$: any;

          if (this.customObjectType === RelatedToType.Property) {
            observableValue$ = this.propertiesService.getProperties(['id', 'name'], {}, 0, 20, 'name', 'asc', value);
          } else if (this.customObjectType === RelatedToType.Project) {
            observableValue$ = this.projectsService.getProjectsNoPipeline(
              ['id', 'name'],
              {},
              0,
              20,
              'project_stage_rank',
              'asc',
              value,
            );
          } else if (this.isCustomObject(this.customObjectType)) {
            observableValue$ = this.customObjectsService.getValues(
              this.accountId,
              this.customObjectId,
              undefined,
              undefined,
              0,
              20,
              'name',
              'asc',
              value,
            );
          } else {
            observableValue$ = of([]);
          }

          return observableValue$;
        }),
      )
      .subscribe((value) => {
        if (this.customObjectType === RelatedToType.Property) {
          this.customObjectOptions = (value as { properties: Property[] })?.properties;
        } else if (this.customObjectType === RelatedToType.Project) {
          this.customObjectOptions = (value as { projects: Project[] })?.projects;
        } else if (this.isCustomObject(this.customObjectType)) {
          const searchFieldName = this.customObject?.custom_fields?.find(
            (field) => field.id === this.customObject?.search_custom_field_id,
          )?.name;
          this.customObjectOptions = (value as { values: CustomObjectValue[] })?.values?.map((coValue) => ({
            id: coValue.id,
            name: coValue[searchFieldName],
          }));
        } else {
          this.customObjectOptions = null;
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  private buildForm(): void {
    this.addLinkToCOValueForm = this.formBuilder.group({
      id: [null],
      name: ['', [Validators.required]],
    });
  }

  autocompleteSelected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value !== 'No results found!') {
      this.addLinkToCOValueForm.patchValue({
        id: Number(event.option.id),
        name: event.option.value,
      });
    }
  }

  private isCustomObject(name: string): boolean {
    return this.customObjects?.some((co) => co.name === name);
  }

  saveAndClose(): void {
    const coValue = this.addLinkToCOValueForm.value;
    this.addLinkToCOValueForm.disable();
    this.matDialogRef.close({ contact: coValue });
  }

  close(): void {
    this.matDialogRef.close();
  }
}
