<mat-form-field class="flex-auto gt-xs:pr-3 no-edit w-full" *ngIf="!editing" (click)="edit()" (focus)="edit()">
  <mat-label>
    <span
      class="block text-ellipsis overflow-hidden pointer-events-auto"
      [matTooltip]="fieldDef.label"
      tooltipIfEllipsis
    >
      {{ fieldDef.label }}
    </span>
  </mat-label>
  <mat-chip-list #chipList aria-label="Tag selection" class="my-2">
    <mat-chip *ngFor="let tag of tags | keyvalue" [selectable]="false" [removable]="false">
      {{ tag.value }}
    </mat-chip>
  </mat-chip-list>
</mat-form-field>
<mat-form-field class="flex-auto gt-xs:pr-3 yes-edit w-full" *ngIf="editing">
  <mat-label>{{ fieldDef.label }}</mat-label>
  <mat-chip-list #chipList aria-label="Tag selection" class="my-2">
    <mat-chip
      *ngFor="let tag of tags | keyvalue"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="removeTag(tag.value)"
    >
      {{ tag.value }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      class="my-2"
      placeholder="New tag..."
      #tagInput
      [formControl]="tagCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addTag($event)"
      (focus)="tagInput.focused = true"
      (blur)="tagInput.focused = false"
    />
    <button mat-icon-button matSuffix (click)="onBlur()" class="treo-message-dismiss-button">
      <mat-icon matSuffix [svgIcon]="'save'"></mat-icon>
    </button>
  </mat-chip-list>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTag($event)">
    <mat-option *ngFor="let tag of foundTags | keyvalue" [value]="tag.key">
      {{ tag.key }}
    </mat-option>
  </mat-autocomplete>
  <mat-hint *ngIf="tagInput.focused" class="mt-2">
    Press <strong>TAB</strong> key after finishing typing to add a new tag
  </mat-hint>
</mat-form-field>
