<mat-form-field class="flex-auto gt-xs:pr-3 w-full" (click)="edit()" (focus)="edit()">
  <mat-label>
    <span
      class="block text-ellipsis overflow-hidden pointer-events-auto"
      [matTooltip]="fieldDef.label"
      tooltipIfEllipsis
    >
      {{ fieldDef.label }}
    </span>
  </mat-label>
  <div class="w-full">
    <textarea
      matInput
      #inputControl
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="500"
      [disabled]="isDisabled"
      [(ngModel)]="value"
      (blur)="onBlur($event)"
      [spellcheck]="false"
    ></textarea>
  </div>
</mat-form-field>
