import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsBadgeComponent } from './ts-badge.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TsBadgeComponent],
  exports: [TsBadgeComponent],
})
export class TsBadgeModule {}
