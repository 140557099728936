import { SavedView } from 'app/core/models';

export namespace SavedViews {
  export class Get {
    static readonly type = 'Get Saved views';

    constructor() {}
  }

  export class AddView {
    static readonly type = 'Create Saved views';

    constructor(public view: SavedView) {}
  }

  export class Set {
    static readonly type = ' Saved views';

    constructor(public views: SavedView[]) {}
  }
}
