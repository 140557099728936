import { Pipe, PipeTransform } from '@angular/core';

type CustomFnType = (value: any[], filter: any) => any[];

@Pipe({ name: 'tsFilterBy' })
export class TsFilterByPipe implements PipeTransform {
  transform(value: any[], properties: string[] = [''], filter: any = '', customFn: CustomFnType = null): any[] {
    if (customFn) {
      return customFn(value, filter);
    }

    if (properties.some((property) => !property) || !filter) return value;

    return value.filter((item) =>
      properties.some((property) => String(item[property])?.toLowerCase().includes(String(filter).toLowerCase())),
    );
  }
}
