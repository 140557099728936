import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { AccountService } from 'app/core';
import { Activity, FieldDef } from 'app/core/models';
import { Project, Property } from 'app/modules/projects/projects.types';
import { RelatedToType } from 'app/shared/enums';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-field-history',
  templateUrl: './field-history.component.html',
  styleUrls: ['./field-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldHistoryComponent implements OnInit {
  @Input() model: Project | Property | any;
  @Input() fieldDef: FieldDef;
  @Input() data: any;

  get options(): any {
    return this.data?.options;
  }

  private get relatedToType(): RelatedToType {
    return this.fieldDef.related_to_type;
  }

  activities$: Observable<Activity[]>;

  constructor(private readonly accountService: AccountService) {}

  ngOnInit(): void {
    if (this.fieldDef.related_to_type === RelatedToType.Task) {
      this.activities$ = this.accountService.getActivities({
        related_to_id: this.model.id,
        field: this.fieldDef.name,
        related_to_type: this.fieldDef.related_to_type,
      });
    } else if (this.fieldDef.custom_object_id) {
      this.activities$ = this.accountService.getActivities({
        related_to_id: this.model.related_to_id,
        field: this.fieldDef.name,
        related_to_type: RelatedToType.CustomObjectValue,
      });
    } else {
      this.activities$ = this.accountService.getActivities({
        related_to_type: this.fieldDef.related_to_type,
        related_to_id: this.model.id,
        field: this.fieldDef.name,
      });
    }
  }

  displayActivitySource(activity): any {
    if (activity?.source) {
      if (activity?.source == 'excel') {
        return 'Excel';
      } else if (activity?.source == 'v3') {
        return 'API';
      } else {
        return 'Web';
      }
    }
  }
}
