import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map, switchMap, take, tap } from 'rxjs/operators';
import { TimeSeriesChart } from './time-series-charts.types';
import { FieldDef } from 'app/core/models/account.types';

declare type ModuleBaseUrlType = 'projects' | 'properties';
@Injectable({
  providedIn: 'root',
})
export class TimeSeriesChartService {
  private _timeSeriesCharts: BehaviorSubject<any | null>;
  private _timeSeriesChart: BehaviorSubject<any | null>;
  private _timeSeriesFields: BehaviorSubject<any | null>;
  private _moduleId: string;
  private _moduleBaseUrl: ModuleBaseUrlType;
  private _relatedToId: string;
  private _relatedToType: string;
  private _accountId: string;

  constructor(private _httpClient: HttpClient) {
    this._timeSeriesCharts = new BehaviorSubject(null);
    this._timeSeriesChart = new BehaviorSubject(null);
    this._timeSeriesFields = new BehaviorSubject(null);
  }

  setTimeSeriesCharts(timeSeriesCharts) {
    this._timeSeriesCharts.next(timeSeriesCharts);
  }

  get timeSeriesCharts$(): Observable<any> {
    return this._timeSeriesCharts.asObservable();
  }

  get timeSeriesChart$(): Observable<any> {
    return this._timeSeriesChart.asObservable();
  }

  get timeSeriesFields$(): Observable<any> {
    return this._timeSeriesFields.asObservable();
  }

  set accountId(val: string) {
    this._accountId = val;
  }

  set moduleId(val: string) {
    this._moduleId = val;
  }

  get moduleId() {
    return this._moduleId;
  }

  set moduleBaseUrl(val: ModuleBaseUrlType) {
    this._moduleBaseUrl = val;
  }

  get moduleBaseUrl() {
    return this._moduleBaseUrl;
  }

  set relatedToId(val: string) {
    this._relatedToId = val;
  }

  get relatedToId() {
    return this._relatedToId;
  }

  set relatedToType(val: string) {
    this._relatedToType = val;
  }

  get relatedToType() {
    return this._relatedToType;
  }

  getTimeSeriesCharts(
    params: { accountId: number; relatedToType: string; relatedToId: number } = null,
  ): Observable<any> {
    const accountId = params?.accountId ?? this._accountId;
    const relatedToType = params?.relatedToType ?? this.relatedToType;
    const relatedToId = params?.relatedToId ?? this.relatedToId;

    return this._httpClient
      .get<any>(
        `time_series_charts?account_id=${accountId}&related_to_type=${relatedToType}&related_to_id=${relatedToId}`,
      )
      .pipe(
        map((response) => {
          this.setTimeSeriesCharts(response.time_series_charts);
          return response;
        }),
      );
  }

  getTimeSeriesChart(id: any): Observable<any> {
    return this._httpClient
      .get<any>(
        `time_series_charts/${id}?account_id=${this._accountId}&related_to_type=${this.relatedToType}&related_to_id=${this.relatedToId}`,
      )
      .pipe(
        map((response: any) => {
          this._timeSeriesChart.next(response.time_series_chart);
          return response.time_series_chart;
        }),
      );
  }

  getTimeSeriesChartData(id: any, startDate: string, endDate: string): Observable<any> {
    return this._httpClient
      .get<any>(
        `time_series_charts/${id}/chart_data?account_id=${this._accountId}&related_to_type=${this.relatedToType}&related_to_id=${this.relatedToId}&start_date=${startDate}&end_date=${endDate}`,
      )
      .pipe(
        map((response: any) => {
          return response.chart_data;
        }),
      );
  }

  getTimeSeriesFields(updateSubject = true): Observable<FieldDef[]> {
    return this._httpClient
      .get<any>(`time_series_charts/fields_for?account_id=${this._accountId}&related_to_type=${this.relatedToType}`)
      .pipe(
        map((response: any) => {
          if (updateSubject) {
            this._timeSeriesFields.next(response.time_series_custom_fields);
          }
          return response.time_series_custom_fields;
        }),
      );
  }

  saveTimeSeriesChart(timeSeriesChart: TimeSeriesChart, skipNext = false): Observable<any> {
    if (timeSeriesChart.id) {
      return this.timeSeriesCharts$.pipe(
        take(1),
        switchMap((timeSeriesCharts) =>
          this._httpClient
            .put<any>(
              `time_series_charts/${timeSeriesChart.id}?account_id=${this._accountId}&related_to_type=${this.relatedToType}&related_to_id=${this.relatedToId}`,
              {
                time_series_chart: timeSeriesChart,
              },
            )
            .pipe(
              map((response) => {
                let timeSeriesChart = response.time_series_chart;

                if (!skipNext) {
                  const index = timeSeriesCharts.findIndex((item) => item.id == timeSeriesChart.id);

                  // Delete the label
                  timeSeriesCharts[index] = timeSeriesChart;

                  // Update the labels
                  this._timeSeriesCharts.next(timeSeriesCharts);
                }

                // Return the deleted status
                return timeSeriesChart;
              }),
            ),
        ),
      );
    } else {
      return this.timeSeriesCharts$.pipe(
        take(1),
        switchMap((timeSeriesCharts) =>
          this._httpClient
            .post<any>(
              `time_series_charts?account_id=${this._accountId}&related_to_type=${this.relatedToType}&related_to_id=${this.relatedToId}`,
              {
                time_series_chart: timeSeriesChart,
              },
            )
            .pipe(
              map((response) => {
                if (timeSeriesCharts && !skipNext) {
                  // Update the contacts with the new contact
                  this._timeSeriesCharts.next([response.time_series_chart, ...timeSeriesCharts]);
                }

                // Return the new contact
                return response.time_series_chart;
              }),
            ),
        ),
      );
    }
  }

  deleteTimeSeriesChart(id: number): Observable<any> {
    return this.timeSeriesCharts$.pipe(
      take(1),
      switchMap((timeSeriesCharts) =>
        this._httpClient
          .delete<any>(
            `time_series_charts/${id}?account_id=${this._accountId}&related_to_type=${this._moduleBaseUrl}&related_to_id=${this.moduleId}`,
          )
          .pipe(
            map((response) => {
              // Find the index of the deleted label within the labels
              const index = timeSeriesCharts.findIndex((item) => item.id == id);

              // Delete the label
              timeSeriesCharts.splice(index, 1);

              // Update the labels
              this._timeSeriesCharts.next(timeSeriesCharts);

              // Return the deleted status
              return response;
            }),
          ),
      ),
    );
  }

  setFieldValue(timeSeriesChartId: any, value: any, date: any, field_name: any, field_id: any = null): Observable<any> {
    return this._httpClient
      .post<any>(
        `time_series_charts/${timeSeriesChartId}/set_field_value?account_id=${this._accountId}&related_to_id=${this.relatedToId}`,
        {
          time_series_value: {
            val: value,
            field_name: field_name,
            date: date,
            field_id: field_id,
          },
        },
      )
      .pipe(
        map((reponse) => {
          return reponse;
        }),
      );
  }

  deleteFieldValue(timeSeriesChartId: any, date: any, field_name: any, field_id: any = null): Observable<any> {
    return this._httpClient
      .post<any>(
        `time_series_charts/${timeSeriesChartId}/delete_field_value?account_id=${this._accountId}&related_to_id=${this.relatedToId}`,
        {
          time_series_value: {
            field_name: field_name,
            date: date,
            field_id: field_id,
          },
        },
      )
      .pipe(
        map((reponse) => {
          return reponse;
        }),
      );
  }

  exportTimeSeriesChart(id: number): Observable<any> {
    return this._httpClient
      .get<any>(`time_series_charts/fields_for?account_id=${this._accountId}&related_to_type=${this.relatedToType}`)
      .pipe(
        map((response: any) => {
          this._timeSeriesFields.next(response.time_series_custom_fields);
          return response.time_series_custom_fields;
        }),
      );
  }
}
