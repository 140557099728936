import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { AlertBannerConfig, AlertTypes, BgColorEnum, IconNameEnum, TYPE_CONFIG } from './alert-banner.types';

@Component({
  selector: 'app-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule],
})
export class AlertBannerComponent implements OnInit {
  @Input() public type: AlertTypes = 'warning';
  @Input() public closable: boolean = false;

  public isOpened: boolean = true;

  public alertConfig: AlertBannerConfig = {
    bgColor: BgColorEnum.WARNING,
    iconName: IconNameEnum.WARNING,
  };

  ngOnInit(): void {
    this.setType(this.type);
  }

  public close(): boolean {
    return (this.isOpened = false);
  }

  private setType(type: string): AlertBannerConfig {
    return (this.alertConfig = TYPE_CONFIG[type] || { bgColor: '', iconName: '' }) as AlertBannerConfig;
  }
}
